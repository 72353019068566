import React from "react";
import _ from 'lodash';

import {
    IconButton,
    Tooltip
} from '@material-ui/core/';

import HisotoryIcon from '@material-ui/icons/History';

import User from "@apricityhealth/web-common-lib/components/User";
import ChangesView from '../views/ChangesView';

import {
    getReportSeries
} from '@apricityhealth/web-common-lib/utils/Services';

// CSS
import "../styles/content.css";
import "diff2html/dist/diff2html.min.css";
import preventClickthrough from 'react-prevent-clickthrough';

let changeQueue = [];

class ChangedByButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changeKeys: props.changeKeys,
            primaryKey: props.primaryKey || [],
            collection: props.collection || [],
            userId: '--',
            dialog: null
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if ( oldProps.changeKeys !== this.props.changeKeys 
            || oldProps.primaryKey !== this.props.primaryKey 
            || oldProps.collection !== this.props.collection ) {
            this.setState({
                changeKeys: this.props.changeKeys,
                primaryKey: this.props.primaryKey || [],
                collection: this.props.collection || []
            }, this.loadContent.bind(this) )
        }
    }

    loadContent() {
        let self = this;
        const { appContext } = this.props;
        const { planId } = appContext.state.plan ? appContext.state.plan : '';
        let { changeKeys, primaryKey, collection } = this.state;
        if (! Array.isArray(collection)) collection = [ collection ];
        if (! Array.isArray(primaryKey)) primaryKey = [ primaryKey ];

        if ( changeKeys ) {
            collection = [];
            primaryKey = [];
            for(let k in changeKeys) {
                if ( collection.indexOf(k) < 0)
                    collection.push(k);
                let keys = changeKeys[k];
                for(let j in keys) {
                    if ( primaryKey.indexOf(keys[j]) < 0)
                        primaryKey.push( keys[j] );
                }
            }
        }

        if ( collection.length > 0 ) {
            let args = { planId, dataId: primaryKey, collectionName: collection, limit: 1 };
            let startQueue = changeQueue.length === 0;
            changeQueue.push( () => {
                getReportSeries(appContext, 'Change', args).then((response) => {
                    let change = _.get(response, "data.results.0");
                    if ( change ) {
                        self.setState({primaryKey, collection, userId: change.userId || '--'});
                    }
                    else{
                        self.setState({primaryKey, collection, userId: '--'});
                    }
                    changeQueue.shift();            // remove ourselves from the queue
                    if ( changeQueue.length > 0 ) 
                        changeQueue[0]();           // invoke the next
                })
            }  );

            if ( startQueue ) {
                changeQueue[0]();       // start the queue up
            }
        }
    }

    onChangeHistory(e) {
        console.log("onChangeHistory()");
        preventClickthrough(e);

        const { primaryKey, collection } = this.state;
        this.setState({dialog: <ChangesView appContext={this.props.appContext} primaryKey={primaryKey} collection={collection} onDone={(e) => {
            preventClickthrough(e);
            this.setState({dialog: null});
        }} />});
    }

    render() {
        const { appContext } = this.props;
        let { userId, dialog } = this.state;

        if ( userId !== '--')
            userId = <User appContext={appContext} userId={userId} userIdHidden={false} />;
        return <div>Last changed by {userId}
            <Tooltip title='Display Change History'><IconButton onClick={this.onChangeHistory.bind(this)}><HisotoryIcon /></IconButton></Tooltip>{dialog}</div>
    }
};

export default ChangedByButton;
