import React, { Component } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    MenuItem,
    Select
} from '@material-ui/core/';

import Plan from '@apricityhealth/web-common-lib/components/Plan';

class SaveDependentDialog extends Component {
    constructor(props) {
        super(props);

        if (! props.appContext ) throw new Error("appContext property missing.");
        if (! props.onConfirm ) throw new Error("onConfirm property missing");

        let { plan } = props.appContext.state;
        this.state = { 
            plans: plan.dependencies || [],
            selectedPlan: plan.dependencies.length > 0 ? plan.dependencies[0] : ''
        };
        // if we have 0 or 1 dependencies, automatically invoke the onConfirm
        if ( this.state.plans.length <= 1 ) {
            let planId = this.state.plans.length > 0 ? this.state.plans[0] : null;
            console.log("SaveDependentDialog:", planId );
            props.onConfirm(planId);
        }
    }

    render() {
        let self = this;
        let { appContext } = this.props;
        let { plans, selectedPlan } = this.state;

        let items = [];
        for(let i=0;i<plans.length;++i) {
            items.push(<MenuItem key={i} value={plans[i]}><Plan appContext={appContext} planId={plans[i]} /></MenuItem>);
        }

        return (
            <Dialog open={true}>
                <DialogTitle>Select Dependent Plan</DialogTitle>
                <DialogContent>
                    <Select style={{width: 400}} value={selectedPlan} onChange={(e) => self.setState({selectedPlan: e.target.value})}>
                        {items}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={() => self.props.onConfirm(selectedPlan)}>OK</Button>
                    <Button variant="contained" style={styles.button} onClick={self.props.onCancel}>CANCEL</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = {
    div: {
        margin: 10
    },
    button: {
        margin: 10
    }
};

export default SaveDependentDialog;
