import React from 'react';

export const DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';

function roundTo(n, place) {
    return +(Math.round(n + "e+" + place) + "e-" + place);
}

export class EventsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            events: props.events,
            clusters: props.clusters,
            history: []
        }
    }

    componentDidMount() {
        const { appContext: { stores: { DataTypesStore } } } = this.props;
        this.dataTypeCategory = DataTypesStore.getDataTypes().reduce((p, c) => {
            p[c.dataId] = c.category;
            return p;
        }, {});

        this.generateHistory();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.events !== this.props.events) {
            this.setState({ events: this.props.events }, this.generateHistory.bind(this))
        }
    }

    // generates a list of data changes only, from -> to, event objects should be pass into this function
    getEventDetails(event) {
        function getDataId(k) {
            let sep = k.indexOf(':');
            if ( sep >= 0) {
                return k.substring(0,sep);
            }
            return k;
        }
        let other = [];
        let labs = [];
        let symptoms = [];
        for (let k in event.data) {
            const value = Number(event.data[k]);
            const category = this.dataTypeCategory[getDataId(k)];
            if (category === 'labTest') {
                const abnormal = value >= 1 || value <= -1;
                const color = abnormal ? 'red' : 'green';
                const fontWeight = abnormal ? 'bold' : 'normal';
                labs.push(<li key={k}>
                    <span style={{ color, fontWeight }}>{`${k}: ${roundTo(value, 3)}`}</span>
                </li>);
            } else if (category === 'symptom') {
                const abnormal = value > 0;
                const color = abnormal ? 'red' : 'green';
                const fontWeight = abnormal ? 'bold' : 'normal';
                symptoms.push(<li key={k}>
                    <span style={{ color, fontWeight }}>{`${k}: ${roundTo(value, 3)}`}</span>
                </li>);
            } else {
                other.push(<li key={k}>
                    {`${k}: ${roundTo(value, 3)}`}
                </li>);
            }
        }
        if (other.length === 0 && labs.length === 0 && symptoms.length === 0) {
            return null;
        }

        return <ul>
            {other.length > 0 && other}
            {labs.length > 0 && <li>Labs:<ul>{labs}</ul></li>}
            {symptoms.length > 0 && <li>Symptoms:<ul>{symptoms}</ul></li>}
        </ul>;
    }

    generateHistory() {
        const history = [];

        const { events, clusters } = this.state;
        events.sort((a, b) => a.day - b.day);

        for (let i = 0; i < events.length; ++i) {
            const event = events[i];
            const details = this.getEventDetails(event);
            if (!details) continue;
            history.push(<li key={history.length}>{`Day: ${event.day}, ${event.eventTime.format(DATE_FORMAT)}, ${clusters[event.closestCluster].clusterName}`}{details}</li>);
        }

        this.setState({ history });
    }

    render() {
        const { history } = this.state;
        return history.length > 0 ? <ul>{history}</ul> : "No events...";
    }
}

export default EventsView;
