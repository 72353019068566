import React from "react";

import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Paper,
} from '@material-ui/core/';

import ReactJson from 'react-json-view';

class JSONDataDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataType: props.dataType,
            collapsed: props.collapsed || 3
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataType !== this.props.dataType)
            this.setState({ dataType: this.props.dataType });
    }

    onDone() {
        if (this.props.onDone)
            this.props.onDone(this.state.dataType);
    }

    render() {
        let { dataType, collapsed } = this.state;

        return (
            <Dialog maxWidth={'md'} fullWidth={true} model="false" open={true}>
                <DialogContent>
                    <div style={{ marginTop: '10px', minWidth: '400px' }} />
                    <Paper key="2" style={styles.jsonPaper}>
                        <ReactJson src={dataType}
                            collapsed={collapsed} name={this.props.name || dataType.name} collapseStringsAfterLength={64} displayDataTypes={false} />
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={this.onDone.bind(this)}>Ok</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = {
    div: {
        margin: 10
    },

    button: {
        margin: 10
    },
    jsonPaper: {
        margin: 5,
        padding: 10,
        width: 700,
        borderRadius: 6
    }
};

export default JSONDataDialog;
