import React from 'react';
import { 
    Drawer
} from '@material-ui/core/';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import FeatureModelView from './MLModelView';

import Config from '@apricityhealth/web-common-lib/Config';
import Axios from '@apricityhealth/web-common-lib/utils/Axios';
import PubSub from 'pubsub-js'
import moment from 'moment';

export class FeatureModelsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dependencies: true,
            filter: '',
            models: []
        }
    }
    
    componentDidMount() {
        this.token = PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected') {
                if ( this._table ) {
                    this._table.loadData();
                }
            }
        });
    }

    componentWillUnmount(){
        PubSub.unsubscribe(this.token);
    }

    loadContent({ offset, limit, order, orderBy }) {
        return new Promise((resolve, reject) => {
            let { appContext: { state: { idToken, plan: { planId } } } } = this.props;

            const getFeatureModels = {
                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${planId}/ml/*?offset=${offset}&limit=${limit}&order=${order}&orderBy=${orderBy}`,
                method: 'GET',
                headers: { "Authorization": idToken }
            }
            Axios(getFeatureModels).then(({ data: { models, totalModels }}) => {
                resolve({ data: models, dataLength: totalModels });
            }).catch((err) => {
                reject(err);
            });
        })
    }

    onCloseDialog() {
        this.setState({ dialog: null });
        if (this._table) {
            this._table.setState({ selected: [] }, this._table.loadData.bind(this._table));
        }
    }

    onSelectModel(s, t) {
        console.log("selectModel:", s, t);
        if (s.length > 0) {
            const model = this._table.state.data[s[0]]; //this.state.filtered ? this.state.filtered[s[0]] : this.state.models[s[0]];
            this.onOpenModel( model );
        }
        else {
            // nothing selected..
            this.setState({ dialog: null });
        }
    }

    onOpenModel(model) {
        console.log("onOpenModel:", model );
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <FeatureModelView appContext={this.props.appContext} model={model} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ dialog });
    }

    onCreateModel() {
        console.log("onCreateModel");
        let newModel = { 
            algorithm: 'kmeans', 
            planId: '', 
            name: '', 
            description: '', 
            modelId: '', 
            biomarkers: {
                dataId: '',
                tupleIndex: 0,
                value: ''
            }
        };
        let dialog = <Drawer variant="persistent" anchor="right" open={true} >
            <FeatureModelView appContext={this.props.appContext} model={newModel} onClose={this.onCloseDialog.bind(this)} />
        </Drawer>;
        this.setState({ progress: null, dialog: dialog });
    }

    render() {
        const { dialog } = this.state;
        const columnData = [
            { id: 'modelId', numeric: false, disabledPadding: false, label: 'Model ID' },
            { id: 'name', numeric: false, disabledPadding: false, label: 'Name' },
            { id: 'description', numeric: false, disabledPadding: false, label: 'Description' },
            { id: 'algorithm', numeric: false, disabledPadding: false, label: 'Algorithm' },
            { id: 'jobs', label: 'Jobs', formatValue: (v) => {
                const sorted = Object.keys(v).sort().reduce((p,c) => {
                    p[c] = v[c];
                    return p;
                }, {} );

                let jobs = '';
                for(let k in sorted) {
                    if ( jobs ) {
                        jobs += ", "
                    }
                    jobs += `${k}: ${v[k]}`
                }
                return jobs;
            } },
            { id: 'nextJobRun', label: 'Next Job', formatValue: (v) => {
                if ( v ) {
                    return moment(v).format("YYYY-MM-DD HH:mm");
                }
                return '';
            }},
            { id: 'planId', numeric: false, disabledPadding: false, label: 'Plan ID', formatValue: (v) => {
                return <Plan appContext={this.props.appContext} planId={v} /> } }
        ];

        return (
            <table width="100%"><tbody>
                <tr>
                    <td colSpan="2">
                        <EnhancedTable
                            rowsPerPage={25}
                            disableMultiSelect={true}
                            onTable={table => this._table = table}
                            onSelected={this.onSelectModel.bind(this)}
                            onAdd={this.onCreateModel.bind(this)}
                            orderBy='name'
                            columnData={columnData}
                            data={this.loadContent.bind(this)}
                            title='ML Models' />
                        {dialog}
                    </td>
                </tr>
            </tbody></table>
        );
    }
}

export default FeatureModelsView;
