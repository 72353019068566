import React from 'react';
import {
    Select,
    MenuItem,
    CircularProgress,
    IconButton,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip,
    Slider,
    Drawer,
    Typography,
    AppBar,
    Toolbar,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tabs,
    Tab,
    Divider
} from '@material-ui/core';
import Moment from 'moment';
import PubSub from 'pubsub-js'

import RefreshIcon from '@material-ui/icons/Refresh';
import NavigationClose from '@material-ui/icons/Close';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import ViewEventIcon from '@material-ui/icons/Details';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import FilterIcon from '@material-ui/icons/FilterList';

import Config from '@apricityhealth/web-common-lib/Config';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { Patient } from '@apricityhealth/web-common-lib/components/Patient';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { EventChangesView, DATE_FORMAT } from './EventChangesView';
import EventsView from './EventsView';
import ClusterDetails from './ClusterDetails';

import { loadJobResult } from '../../utils/LoadJobResults';
import { roundTo } from '../../utils/RoundTo';

// Add JSON.parseMore, to allow us to parse python generated JSON data..
require('../../utils/json_parseMore');

const MAX_NODES = 500;
const JOB_ALGORITHMS = "kmeans,kmeans-generative,knn";
const JOB_POLL_INTERVAL = 1000 * 5;         // how often to check the status of a job
const LABEL_SCALE = 0.075 / 25;

function pickRandomColor(dark = false) {
    return new THREE.Color(
        (Math.random() * 0.5) + (dark ? 0.5 : 0),
        (Math.random() * 0.5) + (dark ? 0.5 : 0),
        (Math.random() * 0.5) + (dark ? 0.5 : 0)
    );
}
export default class ClusteringView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            currentAxis: 0,
            dialog: null,
            eventLog: [],
            patientId: props.patientId || '',
            selectedClusters: [],
            selectedPatients: [],
            darkMode: false,
            sceneScale: 25,
            twoD: false,
            orthographic: true,
            negativeSlopes: false,
            positiveSlopes: false,
            progress: null,
            loading: null,
            jobs: [],
            selectedJobId: '',
            sceneData: {
                nodes: [],
                axes: [],
                patientIds: [],
                clusterEvents: [],
                clusters: {},
                pca_results: {},
                sceneDays: 0
            },
            day: 0,
            animate: true,
            animateSpeed: 1,
            displayLeftPanel: true,
            renderClusters: true,
            renderGrid: true,
            renderAxes: false
        }
        this.materials = [];
        this.lastRender = Moment()
    }

    componentDidMount() {
        this.loadContent();
        this.token = PubSub.subscribe('PLAN_TOPIC', this.loadContent.bind(this));
        this.keyPressHandler = (e) => {
            if (e.key === ' ') {
                this.setState({ animate: !this.state.animate })
            }
        }
        window.addEventListener('keypress', this.keyPressHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('keypress', this.keyPressHandler);

        if (this.loadingTimer) {
            clearTimeout(this.loadingTimer);
            this.loadingTimer = null;
        }
        this.shutdownScene();
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const { appContext: { stores: { DataTypesStore }, state: { plan: { planId }, idToken } } } = this.props;
        if (planId && idToken) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            const getFeatureDetectJobs = {
                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${planId}/ml/job/*?algorithm=${encodeURIComponent(JOB_ALGORITHMS)}&status=done,created,running`,
                method: 'GET',
                headers: { "Authorization": idToken }
            };
            console.log("getFeatureDetectJobs request:", getFeatureDetectJobs);
            AxiosRequest(getFeatureDetectJobs).then(({ data: { jobs } }) => {
                console.log("getFeatureDetectJobs result:", jobs);
                this.setState({ jobs, progress: null });
            }).catch((err) => {
                this.setState({ progress: null, error: getErrorMessage(err) })
            })
        }
        this.dataTypeCategory = DataTypesStore.getDataTypes().reduce((p, c) => {
            p[c.dataId] = c.category;
            return p;
        }, {});
    }

    loadJob() {
        const { selectedJobId } = this.state;
        const { appContext: { state: { plan: { planId }, idToken } } } = this.props;
        if (selectedJobId && this.loadingJob !== selectedJobId && planId && idToken) {
            this.loadingJob = selectedJobId;

            const getFeatureDetectJob = {
                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${planId}/ml/job/${selectedJobId}`,
                method: 'GET',
                headers: { "Authorization": idToken }
            }

            AxiosRequest(getFeatureDetectJob).then(({ data: { jobs } }) => {
                const job = jobs.find((e) => e.jobId === selectedJobId);
                if (!job) {
                    throw new Error(`Job ${selectedJobId} not found!`)
                }

                console.log("loadJob:", job)
                if (job.status === 'done') {
                    if (!job.result) {
                        this.loadingJob = null;
                        return this.setState({ loading: null, error: 'Job is done, but no result data available!' })
                    }
                    this.setState({ loading: <CircularProgress />, error: null, selectedPatients: [], selectedClusters: [], dialog: null }, this.shutdownScene.bind(this));
                    loadJobResult(this.props.appContext, job).then((job) => {
                        if (this.state.selectedJobId !== selectedJobId) {
                            throw new Error(`Loading of job ${selectedJobId} cancelled!`)
                        }

                        //console.log("loadReportJob data:", response.data)
                        return this.generateSceneData(JSON.parseMore(job.result), job);
                    }).then((sceneData) => {
                        if (this.state.selectedJobId !== selectedJobId) {
                            throw new Error(`Loading of job ${selectedJobId} cancelled!`)
                        }
                        this.setState({ eventLog: [], loading: null, job, sceneData, error: sceneData.truncatedData ? 'Data was truncated due to the size.' : null }, this.initializeScene.bind(this));
                        this.loadingJob = null;
                    }).catch((err) => {
                        this.setState({ loading: null, error: getErrorMessage(err) });
                        this.loadingJob = null;
                    })
                } else if (job.status === 'error') {
                    this.setState({ loading: null, error: job.error });
                    this.loadingJob = null;
                } else {        // status should be created or running
                    this.setState({ loading: <CircularProgress />, error: `Job's current status is '${job.status}', run time ${Moment().diff(Moment(job.createdAt), 'minutes')} minutes...` }, this.shutdownScene.bind(this));
                    this.loadingJob = null;

                    if (this.loadingTimer) {
                        clearTimeout(this.loadingTimer);
                        this.loadingTimer = null;
                    }

                    // set a timeout, then attempt to load this job again after the poll interval is done..
                    this.loadingTimer = setTimeout(() => {
                        // firstly, check that the job ID hasn't changed, if it has, then we're now trying to load a different job..
                        if (this.state.selectedJobId === selectedJobId) {
                            // jobId is the same, so call back into this function again..
                            this.loadJob();
                        } else {
                            console.log(`JobId has changed from ${selectedJobId}, cancelling load...`)
                        }
                    }, JOB_POLL_INTERVAL);
                }
            }).catch((err) => {
                console.error("getFeatureDetectJob error:", err);
                this.setState({ loading: null, error: getErrorMessage(err) });
                this.loadingJob = null;
            })
        }
    }

    generateSceneData(data, job) {
        const { dark } = this.state;
        const { metaData = {} } = job;

        console.log("generateSceneData starting:", data, job);
        return new Promise((resolve, reject) => {
            try {
                const sceneData = {
                    dataId: job.biomarkers.dataId,
                    accuracy: data['accuracy'],
                    totalNodes: 0,
                    axes: [],
                    nodes: [],
                    clusterEvents: [],
                    clusters: {},
                    sceneRadius: 1,
                    sceneDays: 0               // total scene time in days, 0 - sceneTime for purposes of calculating the t for the curves
                }

                function getCluster(clusterId) {
                    if (sceneData.clusters[clusterId] === undefined) {
                        //console.log("clusterId:", clusterId)
                        const clusterTargets = data['cluster_targets'][clusterId];
                        const targets = clusterTargets && clusterTargets['targets'];
                        let clusterName = (metaData.labels && metaData.labels[clusterId]) || clusterTargets.clusterName;
                        if (! clusterName ) {
                            if ( targets ) {
                                console.log("targets:", targets );
                                const highestTarget = Object.keys(targets).map((k) => ({ target: k, ...targets[k] }) ).reduce((p,c) => {
                                    if ( p ) {
                                        if ( p.percent < c.percent ) {
                                            p = c;
                                        }
                                    } else {
                                        p = c;
                                    }
                                    return p;
                                })
                                console.log("highestTarget:", highestTarget );
                                if ( highestTarget ) {
                                    clusterName = `${highestTarget.target} ${Math.floor(highestTarget.percent)}%`.toUpperCase();
                                }
                            }
                            // name the cluster after the highest percentage target in this given cluster
                            if (! clusterName ) {
                                clusterName = `Cluster ${clusterId}`;
                            }
                        }
                        sceneData.clusters[clusterId] = {
                            clusterId,
                            clusterName,
                            color: pickRandomColor(dark),
                            targetPercents: targets,
                            featureWeights: data['cluster_feature_weights'] && data['cluster_feature_weights'][clusterId],
                            patients: []
                        }
                    }
                    return sceneData.clusters[clusterId];
                }

                if (data['pca_result']) {
                    const components = data['pca_result']['components'];
                    for (let i = 0; i < components.length; ++i) {
                        sceneData.axes.push({
                            name: components[i].name,
                            features: components[i].features
                        })
                    }
                }

                const clusters = data['clusters'];            // data['clusters] is the current format, the old format had the clusters at the root, can be removed soon..
                for (let i in clusters) {
                    const events = clusters[i];
                    //console.log("events:", events);
                    for (let j = 0; j < events.length; ++j) {
                        const { closestCluster, X, Y, Z, data, eventTime, patientId, target } = events[j];
                        const cluster = getCluster(closestCluster);
                        const { color } = cluster;
                        const etm = Moment(eventTime);
                        let node = sceneData.nodes.find((e) => e.patientId === patientId);
                        if (!node) {
                            if (sceneData.totalNodes < MAX_NODES) {
                                node = {
                                    patientId,
                                    events: [{ closestCluster, color, eventTime: etm, X, Y, Z, data, target }],
                                    startTime: etm,
                                    endTime: etm
                                }
                                sceneData.nodes.push(node);
                                sceneData.clusters[closestCluster].patients.push({ patientId, event: node.events[0] });
                                sceneData.totalNodes += 1;
                                //console.log("Added node:", node);
                            } else {
                                // don't add anymore nodes, just return a flag to indicate we had to truncate data
                                sceneData.truncatedData = true;
                            }
                        } else {
                            if (etm < node.startTime) {
                                node.startTime = etm;
                            }
                            if (etm > node.endTime) {
                                node.endTime = etm;
                            }
                            node.events.push({ closestCluster, color, eventTime: etm, X, Y, Z, data, target })
                        }
                    }
                }
                sceneData.patientIds = sceneData.nodes.map((e) => e.patientId);

                //console.log("Normalizing nodes and calculatime times for curves..", sceneData );
                for (let i = 0; i < sceneData.nodes.length; ++i) {
                    const node = sceneData.nodes[i];
                    const { events } = node;
                    events.sort((a, b) => a.eventTime - b.eventTime);       // sort the dates from oldest to newest

                    node.days = Math.ceil(node.endTime.diff(node.startTime, 'days', true));     // round up
                    if (node.days > sceneData.sceneDays) {
                        sceneData.sceneDays = node.days;
                    }
                }

                // now that we have sceneDays, calculate the spline curve for each node, so we can move them in time correctly..
                for (let i = 0; i < sceneData.nodes.length; ++i) {
                    const node = sceneData.nodes[i];
                    const { events } = node;
                    for (let j = 0; j < events.length; ++j) {
                        const event = events[j];
                        event.day = Moment(event.eventTime).diff(node.startTime, 'days');
                        event.data = Object.keys(event.data).sort().reduce((obj, key) => {         // sort the data by key
                            obj[key] = event.data[key];
                            return obj;
                        }, {});
                    }
                    //console.log(`node[${i}].events:`, events );

                    const points = [];
                    let prevEvent = node.events[0];
                    for (let day = 0; day <= sceneData.sceneDays; ++day) {
                        const event = node.events.findLast((e) => e.day <= day);

                        // create a cluster event anytime a patient changes clusters or the target changes
                        if (event.closestCluster !== prevEvent.closestCluster
                            /*|| event.target !== prevEvent.target*/) {
                            sceneData.clusterEvents.push({
                                node,
                                day: event.day,
                                dt: event.eventTime,
                                patientId: node.patientId,
                                prevEvent,
                                event
                            });
                        }
                        prevEvent = event;

                        //console.log(`i: ${i}, d: ${d}, t: ${t.toISOString()}, eventTime: ${event.eventTime.toISOString()}, event:`, event );
                        const point = new THREE.Vector3(event.X, event.Y, event.Z);
                        const magnitude = point.length();
                        if (magnitude > sceneData.sceneRadius) {
                            sceneData.sceneRadius = magnitude;
                        }
                        points.push(point);
                    }

                    node.curve = new THREE.CatmullRomCurve3(points);
                }

                // sort all the cluster events by day, starting with 0 and ending with the last day
                sceneData.clusterEvents.sort((a, b) => a.day - b.day);
                sceneData.nodes.forEach((node) => {
                    node.clusterEvents = sceneData.clusterEvents.filter((e) => e.patientId === node.patientId)
                })

                console.log("generateSceneData done:", sceneData);
                resolve(sceneData);
            } catch (err) {
                console.error("generateSceneData error:", err);
                reject(err)
            }
        })
    }

    initializeScene(resetCamera = false) {
        const { sceneData, darkMode, twoD, orthographic, sceneScale, renderClusters } = this.state;
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        const addPointLight = ({ x, y, z, color = 0xffffff, intensity = 10000 }) => {
            const light = new THREE.PointLight(color, intensity);
            light.position.set(x, y, z);
            light.layers.disableAll();
            light.layers.enable(1);

            this.scene.add(light);
        }

        this.cleanUpCubesAndMaterials();
        this.scene = new THREE.Scene();

        this.scene.add(new THREE.AmbientLight(0x404040));
        addPointLight({ x: 0, y: 50, z: 20 });
        addPointLight({ x: 0, y: -75, z: -35 });

        if (!this.renderer) {
            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setClearColor(darkMode ? 0 : 0xffffff);
            this.mount.appendChild(this.renderer.domElement);
        }
        this.renderer.setSize(width, height);

        if (!this.camera || resetCamera) {
            if (orthographic) {
                const aspect = width / height;
                const sceneSize = sceneData.sceneRadius * 20;
                //console.log(`sceneSize: ${sceneSize}, sceneScale: ${sceneScale}, sceneRadius: ${sceneData.sceneRadius}`)
                const w = sceneSize * aspect;
                const v = sceneSize;
                this.camera = new THREE.OrthographicCamera(-w, w, v, -v, -1000, 1000);
                this.camera.position.z = sceneSize * 1.5;
                if (!twoD) {
                    this.camera.position.y = (sceneSize * 0.707);
                    this.camera.position.x = (sceneSize * 0.707);
                }
            } else {
                this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
                this.camera.position.z = sceneData.sceneRadius * 20;
                if (!twoD) {
                    this.camera.position.y = 5;
                    this.camera.position.x = 0;
                }
            }
        }
        this.camera.layers.disableAll();
        this.camera.layers.enable(1);
        this.camera.layers.enable(2);

        if (this.labelRenderer) {
            this.mount.removeChild(this.labelRenderer.domElement);
            this.labelRenderer = null;
        }

        let boundingRect = this.mount.getBoundingClientRect();
        let adjustX = (boundingRect.width - width) / 2;
        let adjustY = (boundingRect.height - height) / 2;
        console.log(`width: ${width}, adjustX: ${adjustX}, height: ${height}, adjustY: ${adjustY}, boundingRect:`, boundingRect);
        this.labelRenderer = new CSS3DRenderer();
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.top = `${boundingRect.top + adjustY}px`;
        this.labelRenderer.domElement.style.left = `${boundingRect.left + adjustX}px`;
        this.labelRenderer.domElement.style.zIndex = 1000;
        this.labelRenderer.domElement.style.pointerEvents = 'none';
        //this.renderer.domElement.appendChild(this.labelRenderer.domElement);
        this.mount.appendChild(this.labelRenderer.domElement);

        this.labelRenderer.setSize(width, height);
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);

        if (this.sphere) {
            this.sphere.dispose();
            this.sphere = null;
        }
        if (!this.axesHelper) {
            this.axesHelper = new THREE.AxesHelper(5);
            this.axesHelper.layers.enable(2);
            this.axesHelper.position.set(-sceneData.sceneRadius * 15, 0, 0);
        }
        this.scene.add(this.axesHelper);

        this.sphere = new THREE.SphereGeometry(0.25 * sceneData.sceneRadius, 10, 10);

        this.grid = new THREE.GridHelper(sceneData.sceneRadius * sceneScale, 10); //new THREE.Mesh(this.xz_plane_geo, this.xz_plane_mat);
        this.grid.layers.disableAll();
        this.grid.layers.enable(2);
        this.scene.add(this.grid);

        //ADD Your 3D Models here
        const addSphere = ({ x = 0, y = 0, z = 0, color = 0xff0000, userData = {} } = {}) => {
            //const material = new THREE.MeshBasicMaterial({ color });
            const material = new THREE.MeshStandardMaterial({ color });
            material.transparent = true;
            material.opacity = darkMode ? 0.8 : 0.4;
            const mesh = new THREE.Mesh(this.sphere, material);
            mesh.position.set(x * sceneScale, y * sceneScale, z * sceneScale);
            mesh.userData = userData;
            mesh.layers.disableAll();
            //mesh.layers.enable(1);

            this.scene.add(mesh);
            this.materials.push(material);
            return mesh;
        }

        for (let i = 0; i < sceneData.nodes.length; ++i) {
            const node = sceneData.nodes[i];
            node.mesh = addSphere({ x: node.events[0].X, y: node.events[0].Y, z: node.events[0].Z, color: node.events[0].color, userData: node });
            const scale = sceneData.sceneRadius * 20;
            console.log("scale:", scale );
            //node.mesh.scale.set( new THREE.Vector3( scale, scale, scale) );
        }
        if (renderClusters) {
            for (let k in sceneData.clusters) {
                const cluster = sceneData.clusters[k];
                if (cluster.mesh) {
                    this.scene.add(cluster.mesh);
                }
            }
        }

        if (!this.raycaster) {
            this.raycaster = new THREE.Raycaster();
            this.raycaster.layers.set(1);
        }
        if (!this.pointer) {
            this.pointer = new THREE.Vector2();
        }

        if (!this.pointerMoveHandler) {
            this.pointerMoveHandler = this.onPointerMove.bind(this);
            window.addEventListener('mousemove', this.pointerMoveHandler);
        }

        this.renderScene();
        this.startAnimations();
    }

    onPointerMove(e) {
        if (this.pointer && this.mount) {
            const r = this.mount.getBoundingClientRect();
            this.mousePosition = { x: e.clientX - r.left, y: e.clientY - r.top, r };
            this.pointer.x = (this.mousePosition.x / r.width) * 2 - 1;
            this.pointer.y = -((this.mousePosition.y / r.height) * 2 - 1);
            //console.log("onPointerMove:", this.pointer, r, e.clientX, e.clientY );
        }
    }

    cleanUpCubesAndMaterials() {
        //console.log("cleanUpCubesAndMaterials:", this.cubes, this.materials );
        if (this.scene) {
            this.scene.clear();
            this.scene = null;
        }
        for (let i = 0; i < this.materials.length; ++i) {
            this.materials[i].dispose();
        }
        this.materials = [];
    }

    shutdownScene() {
        this.stopAnimations();

        if (this.labelRenderer) {
            this.mount.removeChild(this.labelRenderer.domElement);
            this.labelRenderer = null;
        }
        if (this.renderer) {
            this.mount.removeChild(this.renderer.domElement);
            this.renderer.dispose();
            this.renderer = null;
        }
        if (this.pointerMoveHandler) {
            window.removeEventListener('mousemove', this.pointerMoveHandler);
            this.pointerMoveHandler = null;
        }
        if (this.sphere) {
            this.sphere.dispose();
            this.sphere = null;
        }
        if (this.controls) {
            this.controls.dispose();
            this.controls = null;
        }

        this.cleanUpCubesAndMaterials();
        this.camera = null;
    }

    startAnimations() {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate.bind(this));
        }
    }

    stopAnimations() {
        if (this.frameId) {
            cancelAnimationFrame(this.frameId);
            this.frameId = null;
        }
    }

    animate() {
        // animate models here
        this.renderScene();
        this.frameId = window.requestAnimationFrame(this.animate.bind(this));
    }

    getEventLog(day) {
        const { sceneData, selectedPatients, selectedClusters } = this.state;
        return (sceneData.clusterEvents || []).filter((e) => e.day <= day
            && (selectedPatients.length === 0 || selectedPatients.indexOf(e.patientId) >= 0)
            && (selectedClusters.length === 0 || (selectedClusters.indexOf(e.event.closestCluster) >= 0 || selectedClusters.indexOf(e.prevEvent.closestCluster) >= 0))
        ).sort((a, b) => b.day - a.day);
    }

    renderScene() {
        if (this.renderer && this.scene && this.camera) {
            const { sceneData, sceneScale, animate, animateSpeed, day, selectedClusters, selectedPatients, renderClusters, renderGrid, renderAxes, darkMode } = this.state;
            const elapsedTime = Moment().diff(this.lastRender, 'milliseconds');
            this.lastRender = Moment();

            if (animate) {
                let newDay = day + ((elapsedTime / 1000) * animateSpeed);
                if (newDay > sceneData.sceneDays) {
                    newDay = 0;     // wrap back to day 0
                }
                this.setState({ eventLog: this.getEventLog(newDay), day: newDay });       // advance the day for the next render
            }
            if (sceneData.sceneDays > 0) {
                const t = day / sceneData.sceneDays;
                //console.log(`day: ${day}, t: ${t}`);

                // reset the patients in each cluster, we will add them back in this next loop
                for (let k in sceneData.clusters) {
                    const cluster = sceneData.clusters[k];
                    cluster.patients = [];
                    cluster.points = [];
                }

                // update the position & color of all the nodes, based on where they are on their curve..
                for (let i = 0; i < sceneData.nodes.length; ++i) {
                    const node = sceneData.nodes[i];
                    const { curve, mesh, events } = node;
                    if (curve === undefined || mesh === undefined) {
                        continue;       // not initialized yet..
                    }
                    const event = events.findLast((e) => e.day <= day);
                    const point = curve.getPoint(t).multiplyScalar(sceneScale);
                    const cluster = sceneData.clusters[event.closestCluster]
                    mesh.position.set(point.x, point.y, point.z);

                    cluster.patients.push({ patientId: node.patientId, event });
                    cluster.points.push(point);

                    if (!mesh.material.color.equals(event.color)) {
                        //console.log(`node: ${i}, t: ${t}, point: ${point.toArray()}, eventTime: ${eventTime}, event.eventTime: ${event.eventTime}, event.color: ${event.color.getHexString()}`, node);
                        mesh.material.color = event.color; //.set( event.color );          // set the correct cluster color
                    }
                    node.rendering = (selectedClusters.length === 0 || selectedClusters.indexOf(event.closestCluster) >= 0)
                        && (selectedPatients.length === 0 || selectedPatients.indexOf(node.patientId) >= 0);
                    node.rendering ? mesh.layers.enable(1) : mesh.layers.disable(1);
                }
            }

            if (this.grid) {
                if (renderGrid) {
                    this.grid.layers.enable(2)
                } else {
                    this.grid.layers.disable(2);
                }
            }
            if (this.axesHelper) {
                if (renderAxes) {
                    this.axesHelper.layers.enable(2)
                } else {
                    this.axesHelper.layers.disable(2);
                }
            }

            if (renderClusters) {
                for (let k in sceneData.clusters) {
                    const cluster = sceneData.clusters[k];
                    if (cluster.points.length > 0) {
                        cluster.center = cluster.points.reduce((p,c) => {
                            return p.add(c);
                        }, new THREE.Vector3(0,0,0) ).multiplyScalar(1.0 / cluster.points.length);
                        //console.log("center:", cluster.center, cluster.points );
                        const lines = [];
                        for(let j=0;j<cluster.points.length;++j) {
                            lines.push(cluster.center);
                            lines.push(cluster.points[j]);
                        }
                        //console.log("lines:", lines, cluster.center, cluster.points );
                        cluster.geo = new THREE.BufferGeometry().setFromPoints(lines);
                        if (!cluster.material) {
                            cluster.material = new THREE.LineBasicMaterial({ color: cluster.color });
                            cluster.material.transparent = true;
                            cluster.material.opacity = 0.5;
                        }
                        
                        if (!cluster.mesh) {
                            cluster.mesh = new THREE.LineSegments(cluster.geo, cluster.material);
                            this.scene.add(cluster.mesh);
                        } else {
                            cluster.mesh.geometry = cluster.geo;
                        }

                        const makeLabelDiv = (text) => {
                            const newDiv = document.createElement('div');
                            newDiv.className = 'label';
                            newDiv.textContent = text;
                            newDiv.style.backgroundColor = 'transparent';
                            newDiv.style.color = darkMode ? 'white' : 'black';
                            newDiv.style.alignContent = 'center';
                            newDiv.style.fontSize = '9px';
                            newDiv.style.pointerEvents = 'none';
                            return newDiv;
                        }
                        if (cluster.label && cluster.darkMode !== darkMode) {
                            cluster.mesh.remove(cluster.label);
                            cluster.label = null;
                        }
                        if (!cluster.label) {
                            cluster.darkMode = darkMode;
                            cluster.label = new CSS3DObject(makeLabelDiv(cluster.clusterName));
                            cluster.label.layers.enable(2);
                            const scale = LABEL_SCALE * sceneScale * sceneData.sceneRadius;
                            cluster.label.scale.set( scale, scale, scale );
                            cluster.label.position.set( cluster.center.x, cluster.center.y, cluster.center.z );
                            cluster.mesh.add( cluster.label );
                        } else {
                            //cluster.label.rotation.set( lookDirection );
                            const scale = LABEL_SCALE * sceneScale * sceneData.sceneRadius;
                            cluster.label.scale.set( scale, scale, scale );
                            cluster.label.position.set( cluster.center.x, cluster.center.y, cluster.center.z );
                        }

                        cluster.rendering = (selectedClusters.length === 0 || selectedClusters.indexOf(cluster.clusterId) >= 0)
                            && (selectedPatients.length === 0 || cluster.patients.find((e) => selectedPatients.indexOf(e.patientId) >= 0));
                    } else {
                        cluster.rendering = false;
                    }

                    if (cluster.rendering) {
                        if( cluster.mesh ) {
                            cluster.mesh.layers.enable(2);
                        }
                        if( cluster.label ) {
                            cluster.label.layers.enable(2);
                        }
                    } else {
                        if( cluster.mesh ) {
                            cluster.mesh.layers.disable(2);
                        }
                        if( cluster.label ) {
                            cluster.label.layers.disable(2);
                        }
                    }
                }
            } else {
                for (let k in sceneData.clusters) {
                    const cluster = sceneData.clusters[k];
                    if (cluster.mesh) {
                        cluster.mesh.layers.disable(2)
                    }
                    if (cluster.label) {
                        cluster.label.layers.disable(2)
                    }
                }
            }

            this.labelRenderer.render(this.scene, this.camera);
            this.renderer.render(this.scene, this.camera);
            //this.setState({sceneData});
        }
        if (this.raycaster) {
            if (this.intersects && this.intersects.length > 0) {
                // restore the original material color before we re-test intersecitons
                //this.intersects[0].object.material.emissive.set( 0 ); //color.set(this.intersects[0].object.userData.color);
            }
            this.raycaster.setFromCamera(this.pointer, this.camera);
            this.intersects = this.raycaster.intersectObjects(this.scene.children);
            if (this.intersects.length > 0) {
                let intersects = null;
                for (let i = 0; i < this.intersects.length; ++i) {
                    const { userData } = this.intersects[i].object;
                    if (userData.events) {
                        intersects = userData;
                        break;
                    }
                }
                //this.intersects[0].object.material.emissive.set(0xffffff);
                if (this.state.intersects !== intersects) {
                    this.setState({ intersects });
                }
            } else if (this.state.intersects) {
                this.setState({ intersects: null });
            }
        }
    }

    onPatientFilter(patientId) {
        const { selectedPatients, day } = this.state;
        const newSelectedPatients = [ ...selectedPatients ];
        const remove = newSelectedPatients.indexOf(patientId);
        if ( remove < 0 ) 
            newSelectedPatients.push( patientId );
        else
            newSelectedPatients.splice( remove, 1 );

        this.setState({ selectedPatients: newSelectedPatients }, () => {
            this.setState( {eventLog: this.getEventLog(day) })
        });
    }

    onPatientDetails(node) {
        if ( this.state.dialogPatientId === node.patientId ) {
            return this.setState({dialog: null, dialogPatientId: null})
        }
        const { appContext } = this.props;
        const { sceneData } = this.state;
        
        let dialog = <Drawer variant="persistent" anchor="right" open={true}>
            <AppBar style={{ backgroundColor: "#FF9800", width: '100%' }} position="static">
                <Toolbar>
                    <IconButton onClick={() => this.setState({ dialog: null, dialogPatientId: null })}><NavigationClose /></IconButton>
                    <Typography variant="h6" color="inherit">Patient Details</Typography>
                </Toolbar>
            </AppBar>
            <Paper align='left' style={{ padding: 5, margin: 5, overflowX: 'auto', width: 800 }}>
                <h4>Details:</h4>
                <table style={{ width: '100%' }}><tbody>
                    <tr><td>Patient</td><td><Patient appContext={appContext} patientId={node.patientId} /></td></tr>
                    <tr><td>Patient ID:</td><td>{node.patientId}</td></tr>
                    <tr><td>History Length:</td><td>{node.days} Days</td></tr>
                    <tr><td>History Begins:</td><td>{node.startTime.format(DATE_FORMAT)}</td></tr>
                    <tr><td>History End:</td><td>{node.endTime.format(DATE_FORMAT)}</td></tr>
                    <tr><td>Cluster Events:</td><td>{node.clusterEvents.length}</td></tr>
                    <tr><td>Events:</td><td>{node.events.length}</td></tr>
                </tbody></table>
                <Accordion defaultExpanded={true} style={{ margin: 5 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Changes</AccordionSummary>
                    <AccordionDetails><EventChangesView appContext={appContext} events={node.events} dataId={sceneData.dataId} clusters={sceneData.clusters} /></AccordionDetails>
                </Accordion>
                <Accordion style={{ margin: 5 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Event Details</AccordionSummary>
                    <AccordionDetails><EventsView appContext={appContext} events={node.events} clusters={sceneData.clusters} /></AccordionDetails>
                </Accordion>
            </Paper>
        </Drawer>;

        this.setState({ dialog, dialogPatientId: node.patientId, clusterDetails: null })
    }

    displayClusterDetails() {
        const { clusterDetails } = this.state;
        if (clusterDetails) {
            return <Drawer variant="persistent" anchor="right" open={true}>
                <AppBar style={{ backgroundColor: "#FF9800", width: '100%' }} position="static">
                    <Toolbar>
                        <IconButton onClick={() => this.setState({ clusterDetails: null })}><NavigationClose /></IconButton>
                        <Typography variant="h6" color="inherit">Cluster Details</Typography>
                    </Toolbar>
                </AppBar>
                <ClusterDetails parent={this} />
            </Drawer>;
        }
    }

    onClusterDetails(cluster) {
        if ( this.state.clusterDetails === cluster ) {
            return this.setState({clusterDetails: null})
        }
        this.setState({ clusterDetails: cluster })
    }

    onClusterFilter(clusterId) {
        const { selectedClusters, day } = this.state;
        const newselectedClusters = [ ...selectedClusters ];
        const remove = newselectedClusters.indexOf(clusterId);
        if ( remove < 0 ) 
            newselectedClusters.push(clusterId);
        else
            newselectedClusters.splice(remove, 1);

        this.setState({ selectedClusters: newselectedClusters }, () => {
            this.setState( {eventLog: this.getEventLog(day) })
        });
    }


    render() {
        const { appContext } = this.props;
        const { currentTab, currentAxis, sceneData, progress, error, darkMode, twoD, orthographic, animate, animateSpeed,
            sceneScale, selectedJobId, selectedClusters, selectedPatients, jobs, loading, intersects, day, dialog, eventLog, displayLeftPanel,
            renderGrid, renderClusters, renderAxes } = this.state;
        const { innerHeight } = window;

        //console.log("selectedClusters:", selectedClusters );
        let tooltip = '';
        if (intersects) {
            //console.log("intersects:", intersects );
            const { events, patientId } = intersects;
            const event = events.findLast((e) => e.day <= day);
            tooltip = <React.Fragment>
                Event Time: {event.eventTime.toISOString()}, Cluster: {event.closestCluster}<br />
                Patient: <Patient appContext={appContext} patientId={patientId} />
            </React.Fragment>;
        }
        return <Tooltip title={tooltip}
            arrow={true}
            disableFocusListener={true}
            onMouseMove={(e) => {
                const { clientX, clientY } = e;
                this.tooltipPos = { clientX, clientY }
            }}
            PopperProps={{
                modifiers: {
                    computeStyle: {
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    left: `${this.tooltipPos.clientX}px`,
                                    top: `${this.tooltipPos.clientY}px`
                                }
                            }
                        }
                    }
                }
            }}
        >
            <table style={{ width: '100%' }}><tbody>
                <tr>
                    <td>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FormControl style={{ margin: 5, width: 350 }}>
                                <InputLabel>Select Clustering Job Result</InputLabel>
                                <Select value={selectedJobId} onChange={(e) => {
                                    this.setState({ selectedJobId: e.target.value }, this.loadJob.bind(this))
                                }}>
                                    {jobs.map((j) => {
                                        return <MenuItem key={j.jobId} value={j.jobId}>{`${j.createdAt}: ${j.name}`}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl style={{ margin: 5, width: 250 }}>
                                <InputLabel>Patient Filter</InputLabel>
                                <Select multiple={true}
                                    value={selectedPatients}
                                    renderValue={(data) => data.map((e, i) => <span key={i}>{i > 0 ? ', ' : ''}<Patient appContext={appContext} patientId={e} /></span>)}
                                    onChange={(e) => {
                                        let selectedPatients = e.target.value;
                                        if (selectedPatients.indexOf('all') >= 0) {
                                            selectedPatients = [];
                                        }
                                        this.setState({ selectedPatients }, () => {
                                            //this.initializeScene();
                                            this.setState({ eventLog: this.getEventLog(day) });
                                        });
                                    }}>
                                    <MenuItem value='all'>All</MenuItem>
                                    {sceneData.patientIds.map((patientId) => {
                                        return <MenuItem key={patientId} value={patientId}><Patient appContext={appContext} patientId={patientId} /></MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl style={{ margin: 5, width: 250 }}>
                                <InputLabel>Cluster Filter</InputLabel>
                                <Select multiple={true}
                                    value={selectedClusters}
                                    onChange={(e) => {
                                        let selectedClusters = e.target.value;
                                        if (selectedClusters.indexOf('all') >= 0) {
                                            selectedClusters = [];
                                        }
                                        this.setState({ selectedClusters }, () => {
                                            this.setState({ eventLog: this.getEventLog(day) });
                                        })
                                    }}>
                                    <MenuItem value='all'>All</MenuItem>
                                    {Object.values(sceneData.clusters).map((cluster) => {
                                        return <MenuItem key={cluster.clusterId} value={cluster.clusterId}>{`${cluster.clusterName} (${cluster.patients.length} Patients)`}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </td>
                    <td align='right'>
                        <span style={{ color: 'red' }}>{error}</span>
                        <FormControlLabel style={{ margin: 5 }} label='Dark' control={<Checkbox checked={darkMode} onChange={(e) => {
                            if (this.renderer) {
                                this.renderer.setClearColor(e.target.checked ? 0 : 0xffffff);
                            }
                            this.setState({ darkMode: e.target.checked }, this.initializeScene.bind(this,false))
                        }} />} />
                        <FormControlLabel style={{ margin: 5 }} label='Grid' control={<Checkbox checked={renderGrid} onChange={(e) => this.setState({ renderGrid: e.target.checked })} />} />
                        <FormControlLabel style={{ margin: 5 }} label='Clusters' control={<Checkbox checked={renderClusters} onChange={(e) => this.setState({ renderClusters: e.target.checked })} />} />
                        <FormControlLabel style={{ margin: 5 }} label='Axes' control={<Checkbox checked={renderAxes} onChange={(e) => this.setState({ renderAxes: e.target.checked })} />} />
                        <FormControlLabel style={{ margin: 5 }} label='2D' control={<Checkbox checked={twoD} onChange={(e) => this.setState({ twoD: e.target.checked }, this.initializeScene.bind(this, true))} />} />
                        <FormControlLabel style={{ margin: 5 }} label='Orthographic' control={<Checkbox checked={orthographic} onChange={(e) => this.setState({ orthographic: e.target.checked }, this.initializeScene.bind(this, true))} />} />
                        <TextField style={{ width: 50, margin: 5 }} label='Scale' value={sceneScale} type='number' onChange={(e) => {
                            this.setState({ sceneScale: e.target.value }, () => {
                                if (this.scaleDebounce) {
                                    clearTimeout(this.scaleDebounce);
                                }
                                this.scaleDebounce = setTimeout(() => {
                                    this.scaleDebounce = null;
                                    this.initializeScene();
                                }, 500);
                            })
                        }} />
                        <TextField style={{ width: 50, margin: 5 }} label='Speed' value={animateSpeed} type='number' onChange={(e) => this.setState({ animateSpeed: e.target.value })} />
                        <IconButton onClick={this.loadContent.bind(this)} disabled={progress !== null}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} align='left'>
                        <table style={{ width: '100%' }}><tbody>
                            <tr><td valign='top'>
                                <Paper style={{ height: displayLeftPanel ? (innerHeight * 0.85) : 50, width: displayLeftPanel ? 600 : 50, overflowY: displayLeftPanel ? 'auto' : 'hidden' }}>
                                    <Tooltip title='Toggle Panel'><IconButton onClick={() => {
                                        this.setState({ displayLeftPanel: !displayLeftPanel }, () => {
                                            // this changes the renderer size, so we need to completely destroy everything, then re-create just like we reloaded..
                                            this.shutdownScene();
                                            this.initializeScene(true);
                                        });
                                    }}><MenuIcon /></IconButton></Tooltip>
                                    {displayLeftPanel &&
                                        <Tabs value={currentTab} onChange={(e, currentTab) => this.setState({ currentTab })} variant='scrollable'>
                                            <Tab label='Events' value={0} />
                                            <Tab label='Patients' value={1} />
                                            <Tab label='Clusters' value={2} />
                                            <Tab label='Axes' value={3} />
                                        </Tabs>}
                                    {displayLeftPanel && currentTab === 0 && eventLog.map((e,index) => {
                                        return <span key={index}><IconButton style={{ padding: 5 }} onClick={this.onPatientDetails.bind(this, e.node)}><ViewEventIcon /></IconButton>
                                            <IconButton style={{ padding: 5 }} onClick={this.onPatientFilter.bind(this, e.node.patientId)}><FilterIcon /></IconButton>
                                            {`Day ${e.day}, ${e.event.eventTime.format(DATE_FORMAT)}: `}<b><Patient appContext={appContext} patientId={e.patientId} /></b>
                                            {e.event.closestCluster !== e.prevEvent.closestCluster && <span>{`, Changed from ${sceneData.clusters[e.prevEvent.closestCluster].clusterName} to ${sceneData.clusters[e.event.closestCluster].clusterName}`}</span>}
                                            {false && e.event.target !== e.prevEvent.target && <b>{`, ${sceneData.dataId} changed from ${e.prevEvent.target} to ${e.event.target}`}</b>}
                                            <br />
                                        </span>;
                                    })}
                                    {displayLeftPanel && currentTab === 1 && sceneData.nodes
                                        .filter((e) => selectedPatients.length === 0 || selectedPatients.indexOf(e.patientId) >= 0)
                                        .filter((e) => selectedClusters.length === 0 || selectedClusters.find((clusterId) => sceneData.clusters[clusterId].patients.find((p) => p.patientId === e.patientId)) !== undefined)
                                        .map((node,index) => {
                                            return <span key={index}><IconButton style={{ padding: 5 }} onClick={this.onPatientDetails.bind(this, node)}><ViewEventIcon /></IconButton>
                                                <IconButton style={{ padding: 5 }} onClick={this.onPatientFilter.bind(this, node.patientId)}><FilterIcon /></IconButton>
                                                <Patient appContext={appContext} patientId={node.patientId} />{` (${node.events.length} Events, ${node.clusterEvents.length} Cluster Events)`}
                                                <br />
                                            </span>
                                        })
                                    }
                                    {displayLeftPanel && currentTab === 2 && Object.values(sceneData.clusters)
                                        .filter((cluster) => selectedPatients.length === 0 || selectedPatients.find((patientId) => cluster.patients.find((p) => p.patientId === patientId)))
                                        .filter((cluster) => selectedClusters.length === 0 || selectedClusters.indexOf(cluster.clusterId) >= 0)
                                        .map((cluster,index) => {
                                            return <span key={index}><IconButton style={{ padding: 5 }} onClick={this.onClusterDetails.bind(this, cluster)}><ViewEventIcon /></IconButton>
                                                <IconButton style={{ padding: 5 }} onClick={this.onClusterFilter.bind(this, cluster.clusterId)}><FilterIcon /></IconButton>
                                                {`${cluster.clusterName} (${cluster.patients.length} Patients)`}<br /></span>;
                                        })}
                                    {displayLeftPanel && currentTab === 3 && <Tabs variant='scrollable' value={currentAxis} onChange={(e, t) => this.setState({ currentAxis: t })}>{sceneData.axes.map((axis, index) => {
                                        return <Tab key={index} label={axis.name} value={index} style={{ color: 'white', backgroundColor: ['#a00000', '#00a000', '#0000a0'][index % 3] }} />
                                    })}</Tabs>}
                                    {displayLeftPanel && currentTab === 3 && <table width='100%'><tbody>
                                        {(sceneData.axes[currentAxis] || { features: [] }).features.sort((a, b) => b.weight - a.weight).map((f,index) => {
                                            const value = f.abs_weight * 200;
                                            const color = f.weight < 0 ? 'red' : 'green';
                                            //console.log(`${f.name}: ${value}`);
                                            return <tr key={index}><td>{f.name}</td><td style={{ color, align: 'center' }}>{roundTo(f.weight, 3)}
                                                <Divider light={false} variant='middle' style={{ background: color, width: `${value}px` }} /> </td></tr>
                                        })}
                                    </tbody></table>}
                                </Paper>
                            </td>
                                <td align='center'>
                                    {sceneData.accuracy && <span>Accuracy {(sceneData.accuracy * 100).toFixed(2)}%</span>}
                                    <div style={{ margin: 5, width: '98%', height: innerHeight * 0.75 }} ref={(mount) => this.mount = mount}
                                        onClick={(e) => {
                                            if (intersects) {
                                                let newSelectedPatients = [...selectedPatients];
                                                console.log("onClick intersects:", intersects);
                                                let i = newSelectedPatients.indexOf(intersects.patientId);
                                                if (i < 0)
                                                    newSelectedPatients.push(intersects.patientId);
                                                else
                                                    newSelectedPatients.splice(i, 1);

                                                console.log("selectedPatients:", newSelectedPatients);
                                                this.setState({ selectedPatients: newSelectedPatients }, () => {
                                                    this.initializeScene();
                                                    this.setState({ eventLog: this.getEventLog(day) });
                                                });
                                            }
                                        }}>
                                        {loading}
                                        {dialog}
                                        {this.displayClusterDetails()}
                                    </div>
                                    <IconButton style={{ top: -15 }} onClick={() => this.setState({ animate: !animate })}>{animate ? <PauseIcon /> : <PlayIcon />}</IconButton>
                                    <Slider style={{ margin: 5, width: 800 }}
                                        valueLabelDisplay='on'
                                        size='small'
                                        value={Math.floor(day)}
                                        min={0}
                                        step={0.01}
                                        max={sceneData.sceneDays}
                                        onChange={(e, v) => {
                                            let newDay = v;
                                            if (newDay < 0) {
                                                newDay = 0;
                                            } else if (newDay > sceneData.sceneDays) {
                                                newDay = sceneData.sceneDays;
                                            }
                                            this.setState({ animate: false, eventLog: this.getEventLog(newDay), day: newDay })
                                        }}
                                        // needed to filter the clusterEvents, we can't have two marks with the same day or it floods the console with errors
                                        marks={sceneData.clusterEvents
                                            .filter((v) => selectedPatients.length === 0 || selectedPatients.indexOf(v.patientId) >= 0)
                                            .filter((v) => selectedClusters.length === 0 || selectedClusters.find((clusterId) => v.event.closestCluster === clusterId || v.prevEvent.closestCluster === clusterId))
                                            .filter((v, i, a) => a.findIndex((k) => k.day === v.day) === i).map((e) => ({ value: e.day, label: '|' }))}
                                    /><br />
                                    <span><i>Hold left mouse button to rotate, Hold right mouse button to move, Use your mouse wheel to zoom.</i></span>
                                </td>
                            </tr>
                        </tbody></table>
                    </td>
                </tr>
            </tbody></table>
        </Tooltip>
    }
}

