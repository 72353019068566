import React from 'react';
import {
    TextField,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    withStyles,
    IconButton
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Config from '@apricityhealth/web-common-lib/Config';
import { Patient } from '@apricityhealth/web-common-lib/components/Patient';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';

import { roundTo } from '../../utils/RoundTo';

const FEATURE_WEIGHT_CAP = 15;
export class ClusterDetails extends React.Component {
    onUpdateClusterName(cluster, e) {
        const { parent } = this.props;
        const { appContext: { state: { idToken }}} = parent.props;
        const { job } = parent.state;
        if ( job.metaData === undefined ) job.metaData = {};
        const { metaData } = job;

        console.log("onUpdateClusterName:", cluster, e.target.value );
        cluster.clusterName = e.target.value;
        if ( cluster.label && cluster.mesh ) {
            cluster.mesh.remove( cluster.label );
            cluster.label = null;
        }
        
        if ( metaData.labels === undefined ) metaData.labels = {};
        metaData.labels[cluster.clusterId] = cluster.clusterName;

        if ( this.updateJobTimer ) {
            clearTimeout( this.updateJobTimer );
            this.updateJobTimer = null;
        }

        parent.setState({ job });
        this.updateJobTimer = setTimeout( () => {
            const { metaData } = job;
            const updateJob = {
                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${job.planId}/ml/job/${job.jobId}`,
                method: 'PUT',
                headers: { "Authorization": idToken },
                data: {
                    metaData,
                    keep: true
                }
            }
            console.log("updateJob request:", updateJob, parent.state );
            AxiosRequest(updateJob).then((result) => {
                console.log("updateJob result:", result.data );
                this.updateJobTimer = null;
            }).catch((err) => {
                console.error("updateJob error:", err );
                this.updateJobTimer = null;
            })
    
        }, 500 );
    }


    render() {
        function getDataId(k) {
            let sep = k.indexOf(':');
            if (sep >= 0) {
                return k.substring(0, sep);
            }
            return k;
        }

        const { parent } = this.props;
        const { clusterDetails } = parent.state;
        if (clusterDetails) {
            const { appContext } = parent.props;
            const { day, sceneData, showAllFeatures } = parent.state;

            let patients = sceneData.clusters[clusterDetails.clusterId].patients;
            const demographics = patients.length > 0 && Object.keys(patients[0].event.data).map((k) => {
                const category = parent.dataTypeCategory[getDataId(k)];
                if (category !== 'symptom' && category !== 'labTest' && category !== 'proteomicsData') {
                    let row = patients.map((patient, i) => {
                        return <td key={i}>{roundTo(patient.event.data[k], 3)}</td>;
                    })
                    return <tr><td>{k}</td>{row}</tr>
                }
                return null;
            }).filter((e) => e !== null);
            const symptoms = patients.length > 0 && Object.keys(patients[0].event.data).map((k) => {
                const category = parent.dataTypeCategory[getDataId(k)];
                if (category === 'symptom') {
                    let row = patients.map((patient, i) => {
                        const value = Number(patient.event.data[k]);
                        return <td key={i} style={{ color: (value > 0) ? 'red' : 'green' }}>{roundTo(value, 3)}</td>;
                    })
                    return <tr><td>{k}</td>{row}</tr>
                }
                return null;
            }).filter((e) => e !== null);
            const labs = patients.length > 0 && Object.keys(patients[0].event.data).map((k) => {
                const category = parent.dataTypeCategory[getDataId(k)];
                if (category === 'labTest') {
                    let row = patients.map((patient, i) => {
                        const value = Number(patient.event.data[k]);
                        return <td key={i} style={{ color: (value >= 1 || value <= -1) ? 'red' : 'green' }}>{roundTo(value, 3)}</td>;
                    })
                    return <tr><td>{k}</td>{row}</tr>
                }
                return null;
            }).filter((e) => e !== null);
            const proteomics = patients.length > 0 && Object.keys(patients[0].event.data).map((k) => {
                const category = parent.dataTypeCategory[getDataId(k)];
                if (category === 'proteomicsData') {
                    let row = patients.map((patient, i) => {
                        const value = Number(patient.event.data[k]);
                        return <td key={i}>{roundTo(value, 3)}</td>;
                    })
                    return <tr><td>{k}</td>{row}</tr>
                }
                return null;
            }).filter((e) => e !== null);

            const featureWeights = clusterDetails.featureWeights && Object.keys(clusterDetails.featureWeights).reduce((p,c) => {
                p.push( { key: c, percent: Number(clusterDetails.featureWeights[c]) * 100 })
                return p;
            }, [] ).filter((e) => !Number.isNaN(e.percent) && Math.floor(e.percent) > 0).sort((a,b) => b.percent - a.percent);
            let featuresTruncated = false;
            if (featureWeights && !showAllFeatures && featureWeights.length > FEATURE_WEIGHT_CAP) {
                featureWeights.splice(FEATURE_WEIGHT_CAP, featureWeights.length - FEATURE_WEIGHT_CAP);      // only show the first 10 features
                featuresTruncated = true;
            }
            //console.log("featureWeights:", featureWeights );
            const targetPercents = clusterDetails.targetPercents && Object.keys(clusterDetails.targetPercents).reduce((p,c) => {
                p.push( { key: c, ...clusterDetails.targetPercents[c] })
                return p;
            }, []).filter((e) => !Number.isNaN(e.percent) && Math.floor(e.percent) > 0).sort((a,b) => b.percent - a.percent);
            //console.log("targetPercents:", targetPercents );

            return <Paper align='left' style={{ padding: 5, margin: 5, overflowX: 'auto', width: 1200 }}>
                    <h4>Details:</h4>
                    <table><tbody>
                        <tr><td><TextField style={{width: 300, margin: 0}} value={clusterDetails.clusterName} onChange={this.onUpdateClusterName.bind(this,clusterDetails)} label='Cluster Name' /></td></tr>
                        <tr><td>Cluster ID:</td><td>{clusterDetails.clusterId}</td></tr>
                        {targetPercents && targetPercents.length > 0 && <tr><td valign='top'>Target Percentages:</td><td>
                            {targetPercents.map((e,index) => <div key={index}>{e.percent > 10 ? <b>{`${e.key}: ${roundTo(e.percent,1)}%`}</b> : <i>{`${e.key}: ${roundTo(e.percent,1)}%`}</i>}</div>)}
                        </td></tr>}
                        {featureWeights && featureWeights.length > 0 && <tr><td valign='top'>Feature Weights:<br />
                            {featuresTruncated && <IconButton variant='outlined' onClick={() => parent.setState({showAllFeatures: true})}><ExpandMoreIcon /></IconButton>}
                            {showAllFeatures && <IconButton variant='outlined' onClick={() => parent.setState({showAllFeatures: false})}><ExpandLessIcon /></IconButton>}
                        </td><td>
                            {featureWeights.map((e,index) => <div key={index}>{e.percent > 10 ? <b>{`${e.key}: ${roundTo(e.percent,1)}%`}</b> : <i>{`${e.key}: ${roundTo(e.percent,1)}%`}</i>}</div>)}
                        </td></tr>}
                    </tbody></table>
                    <Accordion style={{ margin: 5 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><h4>Data for Day {Math.floor(day)} ({patients.length} patients)</h4></AccordionSummary>
                        <AccordionDetails>
                            <table><tbody>
                                <tr>
                                    <td></td>
                                    {patients.map((patient, index) => {
                                        return <td key={index}>
                                            <b><Patient appContext={appContext} patientId={patient.patientId} /></b>
                                        </td>
                                    })}
                                </tr>
                                <tr>
                                    {patients.length > 0 && <td><b>Target</b></td>}
                                    {patients.map((patient, index) => {
                                        return <td key={index}>
                                            {patient.event.target}
                                        </td>
                                    })}
                                </tr>
                                {demographics.length > 0 && <tr><td><b>Demographics</b></td></tr>}
                                {demographics}
                                {symptoms.length > 0 && <tr><td><b>Symptoms</b></td></tr>}
                                {symptoms}
                                {labs.length > 0 && <tr><td><b>Labs</b></td></tr>}
                                {labs}
                                {proteomics.length > 0 && <tr><td><b>Proteomics</b></td></tr>}
                                {proteomics}
                            </tbody></table>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
        } else {
            return null;
        }
    }
}

const styles = {
};

export default withStyles(styles)(ClusterDetails);