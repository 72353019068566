
import React from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core/'
import T from 'i18n-react';
export class IdleDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countDown: 60
        };
    }

    componentDidMount() {
        const self = this;
        this.intervalTimer = setInterval(() => {
            let { countDown } = self.state;
            countDown -= 1;
            if (countDown < 0)
                self.props.appContext.confirmSignOut();
            else
                self.setState({ countDown });
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalTimer);
    }

    onCancel() {
        this.props.appContext.setState({ dialog: null });
    }

    render() {
        let { countDown } = this.state;
        return <Dialog open={true} onClose={this.onCancel.bind(this)}>
            <DialogContent>
                <T.span text={{ key: 'idleTimeout', countDown }} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={this.onCancel.bind(this)}><T.span text='cancel' /></Button>
            </DialogActions>
        </Dialog>
    }
}

export default IdleDialog;