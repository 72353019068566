import Config from '@apricityhealth/web-common-lib/Config';
import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import clsx from 'clsx';
import { Route, Routes, Link, Navigate } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import PubSub from 'pubsub-js';

import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Toolbar,
    Typography,
    withStyles,
    Divider,
    Menu
} from '@material-ui/core/';

import ApricityLogo from './apricityTextLogo.png';
import NavigationMenu from '@material-ui/icons/Menu';
//import ProfileIcon from '@material-ui/icons/Person';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FeatureModelsIcon from '@material-ui/icons/DonutLarge';
import SimilarPatientsIcon from '@material-ui/icons/DonutSmall'
import DLPPIcon from '@material-ui/icons/ViewModule';
import ImportIcon from '@material-ui/icons/ImportExport';
import GraphIcon from '@material-ui/icons/BarChart';

import DataTypesStore from '@apricityhealth/web-common-lib/stores/DataTypesStore'
import SelectTheme from '@apricityhealth/web-common-lib/components/SelectThemeV2';
import SelectPlan from '@apricityhealth/web-common-lib/components/SelectPlan';
import LoginView from '@apricityhealth/web-common-lib/views/LoginView';

import { IdleDialog } from './views/IdleDialog';
import UserInfoView from './views/UserInfoView';
import FeatureModelsViews from './views/MLModelsView';
import DLPPView from './views/DLPPView';
import ClusteringView from './views/clusteringView';
import PCAView from './views/pcaView';
import ImportProteomicsDataView from './views/ImportProteomicsDataView';
import ProteomicsGraph from './views/proteomicsGraph';

import './App.css';

const IDLE_TIMEOUT = (Config.stage === 'local' || Config.stage === 'develop') ? 1000 * 60 * 1440 : 1000 * 60 * 15;

class App extends React.Component {
    /**
     * Initialize the Sage Portal
     * @param {Sage.App.state} props Initial session properties
     */
    constructor(props) {
        super(props);

        let pathname = window.location.pathname.slice(1).split('/');
        this.state = {
            plan: {
                planId: ''
            },
            idToken: null,
            currentPath: pathname[0],
            refreshToken: null,
            dialog: null,
            username: null,
            theme: 'light',
            open: true,
            menuOpen: false,
            anchorEl: null,
            language: (localStorage.getItem("language") ||
                (navigator.languages && navigator.languages[0]) ||
                navigator.language ||
                navigator.userLanguage).toLowerCase() ||
                'en-us',

            faq: []
        };
        this.stores = {
            DataTypesStore: new DataTypesStore(this, this)
        }

        PubSub.subscribe('PLAN_TOPIC', (msg, data) => {
            if (data.action === 'PlanSelected' && data.plan) {
                this.setState({ plan: data.plan }, () => this.stores.DataTypesStore.loadDataTypes());
            }
        });

        T.setTexts(require(`./locale/${this.state.language}.json`));
    }

    componentDidMount() {
        // This is lame, but aparently you have to poll to find out when the path
        // location has changed..
        this.checkPath = setInterval( () => {
            let pathname = window.location.pathname.slice(1).split('/');
            if ( this.state.currentPath !== pathname[0] ) {
                //console.log(`Updating currentPath tp ${pathname[0]}`)
                this.setState({currentPath: pathname[0]});
            }
        }, 500 );
    }

    componentWillUnmount() {
        clearInterval( this.checkPath );
    }

    onSignIn() {
        this.stores.DataTypesStore.loadDataTypes();
    }

    onSignOut() {
        const self = this;
        const { classes } = self.props;

        this.handleMenuClose();
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}
                    onClose={() => self.cancelSignOut()}
                >
                    <DialogTitle id="signoutDialog" >Sign Out</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to sign out?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="signoutCancel" className={classes.button} variant="contained" self={self} onClick={(e) => { self.cancelSignOut(); }} >Cancel</Button>,
                        <Button id="signoutConfirm" className={classes.button} variant="contained" self={self} onClick={(e) => { self.confirmSignOut(); }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    onSettings() {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}
                    onClose={() => self.confirmSettings()}
                >
                    <DialogTitle>Settings</DialogTitle>
                    <DialogContent>
                        <UserInfoView appContext={this} />
                        <SelectTheme labelStyle={{ marginLeft: '1rem' }} style={{ marginLeft: '1rem' }} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.confirmSettings(); }}>DONE</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }


    confirmSignOut() {
        sessionStorage.removeItem('idToken');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('userId');

        this.setState({ idToken: null, username: null, refreshToken: null, dialog: null });
    }

    cancelSignOut() {
        this.setState({ dialog: null });
    }

    getMenu(currentPath) {
        const { open } = this.state;
        const { classes } = this.props;

        const menuItems = [
            <MenuItem
                key='ml_models'
                component={Link}
                to="/ml_models"
                selected={currentPath === 'ml_models'}>
                <ListItemIcon><FeatureModelsIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="ml_models" />} />
            </MenuItem>,
            <MenuItem
                style={{paddingLeft: 50}}
                key='pca'
                component={Link}
                to="/pca"
                selected={currentPath === 'pca'}>
                <ListItemIcon><SimilarPatientsIcon /></ListItemIcon>
                <ListItemText primary={<T.span text='pca' />} />
            </MenuItem>,
            <MenuItem
                style={{paddingLeft: 50}}
                key='clustering'
                component={Link}
                to="/clustering"
                selected={currentPath === 'clustering'}>
                <ListItemIcon><SimilarPatientsIcon /></ListItemIcon>
                <ListItemText primary={<T.span text='clustering' />} />
            </MenuItem>,
            <MenuItem
                key='dlpp'
                component={Link}
                to="/dlpp"
                selected={currentPath === 'dlpp'}>
                <ListItemIcon><DLPPIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="dlpp" />} />
            </MenuItem>,
            <MenuItem
                key='import_proteomics'
                component={Link}
                to="/import_proteomics"
                selected={currentPath === 'import_proteomics'}>
                <ListItemIcon><ImportIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="import_proteomics" />} />
            </MenuItem>,
            <MenuItem
                style={{paddingLeft: 50}}
                key='graph_proteomics'
                component={Link}
                to="/graph_proteomics"
                selected={currentPath === 'graph_proteomics'}>
                <ListItemIcon><GraphIcon /></ListItemIcon>
                <ListItemText primary={<T.span text="graph_proteomics" />} />
        </MenuItem>,
    ];

        return <Drawer
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            variant="persistent"
            open={open}>
            <MenuList>
                <div className={classes.sidebarHeader}>
                    <img className={classes.logo} src={ApricityLogo} alt="apricity logo" />
                </div>
                <Divider />
                {menuItems}
            </MenuList>
        </Drawer>
    }


    onToggleMenu(e) {
        this.setState({ open: !this.state.open });
    }

    onActive() {
        console.log("onActive");
    }

    onIdle() {
        if (this.state.idToken) {
            this.setState({ dialog: <IdleDialog appContext={this} /> })
        }
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget, menuOpen: true });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null, menuOpen: false });
    };

    handleProfileMenuOpen = event => {
        this.setState({ accountMenuOpen: false });
    };


    render() {
        const { classes } = this.props;
        const { idToken, open, username, anchorEl, dialog, currentPath } = this.state;
        if (!idToken) {
            const stage = Config.stage !== 'production' ? Config.stage.toUpperCase() : '';
            return <div className="App">
                <LoginView stage={stage} appContext={this} group={'auditors'} onDone={this.onSignIn.bind(this)} />
            </div>
        }

        const contentStyle = Object.assign({}, styles.content);
        if (open)
            contentStyle.marginLeft = DRAWER_WIDTH;

        return <div id='appDiv'>
            <IdleTimer onActive={this.onActive.bind(this)} onIdle={this.onIdle.bind(this)} debounce={250} timeout={IDLE_TIMEOUT}>
                {this.getMenu(currentPath)}
                <div style={contentStyle}>
                    <AppBar style={{ backgroundColor: '#026991' }} position="static">
                        <Toolbar disableGutters={!open}>
                            <IconButton onClick={() => this.onToggleMenu()}><NavigationMenu /></IconButton>
                            <Typography variant="h6" color="inherit" className={classes.grow}>
                                {<T.span text={currentPath} />}
                            </Typography>
                            {idToken && (
                                <div>
                                    <SelectPlan hideProtected={false} appContext={this} style={
                                        {
                                            backgroundColor: 'white',
                                            margin: '10px', padding: '5px 5px 5px 25px', borderRadius: '10px'
                                        }} />
                                    <span >{username}</span>
                                    <IconButton
                                        aria-owns={open ? 'menu-appbar' : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => this.handleMenu(e)}
                                        color="inherit"
                                    >
                                        <AccountCircle id="userProfle" />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={() => this.handleMenuClose()}
                                    >
                                        <MenuItem id="settings" onClick={(e) => { this.onSettings(); }}>Settings</MenuItem>
                                        <MenuItem id="signout" onClick={(e) => { this.onSignOut(); }}>Logout</MenuItem>
                                    </Menu>
                                </div>
                            )}
                        </Toolbar>
                    </AppBar>
                    <Routes>
                        <Route exact path="/" element={<Navigate to="/ml_models" />} />
                        <Route path="/ml_models" element={<FeatureModelsViews appContext={this} />} />
                        <Route path="/dlpp" element={<DLPPView appContext={this} />} />
                        <Route path="/clustering" element={<ClusteringView appContext={this} />} />
                        <Route path="/pca" element={<PCAView appContext={this} />} />
                        <Route path="/import_proteomics" element={<ImportProteomicsDataView appContext={this} />} />
                        <Route path="/graph_proteomics" element={<ProteomicsGraph appContext={this} />} />
                    </Routes>
                </div>
                {dialog}
            </IdleTimer>
        </div>;
    }
}
const DRAWER_WIDTH = 300;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    logo: {
        height: '90px',
        marginBottom: '10px',
    },
    sidebarHeader: {
        display: 'flex',
        padding: '10px 10px 0 10px',
        justifyContent: 'center'
    },
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            easing: '150ms',
            duration: '150ms',
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: '150ms',
            duration: '150ms',
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) /*+ 1*/,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) /*+ 1*/,
        },
    },
    grow: {
        flexGrow: 1,
    }
});

App.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(App);