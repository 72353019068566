import { AppBar, Drawer, Toolbar, IconButton, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ValidIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear'
import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';

export default class TriggerSelectionView extends React.Component {
    constructor(props) {
        super(props);

        if (!props.appContext) throw new Error("TriggerSelectionView expects a appContext property!")
        if (!props.model) throw new Error("TriggerSelectionView expects a model property!")
        if (!props.onCloseView) throw new Error("TriggerSelectionView expects a onCloseView property!")

        this.state = {
            model: props.model,
            showAdded: false,
            showRemoved: false,
            filter: '',
            expanded: {}
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.model !== this.props.model) {
            this.setState({ model: this.props.model }, this.loadContent.bind(this));
        }
    }

    loadContent() {
    }

    onAddFeatures(table, ids, primaryKey) {
        const { model } = this.state;
        const selected = table.state.data.filter((e) => table.state.selected.indexOf(e._index) >= 0);
        console.log(`onAddFeatures, ids: ${ids}, primaryKey: ${primaryKey}, selected:`, selected);
        ids = ids.split(',');


        for (let i = 0; i < selected.length; ++i) {
            const add = selected[i][primaryKey];
            ids.forEach((id) => {
                if (!Array.isArray(model.biomarkers[id])) model.biomarkers[id] = [];
                if (model.biomarkers[id].indexOf(add) < 0) {
                    model.biomarkers[id].push(add);
                }
                console.log(`model.biomarkers[${id}]:`, model.biomarkers[id])
            });
        }

        this.setState({ model });
        table.setState({ selected: [] });
    }

    onRemoveFeatures(table, ids, primaryKey) {
        const { model } = this.state;
        const selected = table.state.data.filter((e) => table.state.selected.indexOf(e._index) >= 0);
        console.log(`onRemoveFeatures, id: ${ids}, primaryKey: ${primaryKey}, selected:`, selected);
        ids = ids.split(',');

        for (let i = 0; i < selected.length; ++i) {
            const remove = selected[i][primaryKey];
            ids.forEach((id) => {
                if (!Array.isArray(model.biomarkers[id])) model.biomarkers[id] = [];
                let j = model.biomarkers[id].indexOf(remove);
                if (j >= 0) {
                    model.biomarkers[id].splice(j, 1)
                }
                console.log(`model.biomarkers[${ids}]:`, model.biomarkers[ids])
            });
        }

        this.setState({ model });
        table.setState({ selected: [] });
    }

    render() {
        const { expanded, model, filter, showAdded, showRemoved } = this.state;
        const { biomarkers } = model;
        const { appContext: { stores: { DataTypesStore } } } = this.props;

        if (!Array.isArray(biomarkers.conditionTriggers)) biomarkers.conditionTriggers = [];
        if (!Array.isArray(biomarkers.medicationTriggers)) biomarkers.medicationTriggers = [];
        if (!Array.isArray(biomarkers.procedureTriggers)) biomarkers.procedureTriggers = [];

        function getFeatureTable(self, { title, id, options, primaryKey }) {
            console.log(`getFeatureTable, title: ${title}, id: ${id}, primaryKey: ${primaryKey}, options:`, options);
            if (model.biomarkers[id] === undefined) model.biomarkers[id] = [];
            const columns = [
                {
                    id: primaryKey, width: 10, label: 'Added', formatValue: (v) => {
                        const added = model.biomarkers[id].find((e) => e === v);          // it's either an array if ID's or an array with a dataId property, so just search for either
                        return added ? <ValidIcon key='valid' style={{ height: 20, fill: 'green', padding: 0, margin: 0 }} /> : '';
                    }
                },
                {
                    id: 'system', label: 'System'
                },
                {
                    id: 'code', label: 'Code'
                },
                {
                    id: 'name', label: 'Name'
                },
            ];

            if (showAdded) {
                options = options.filter((e) => model.biomarkers[id].find((k) => k === e[primaryKey]))
            }
            if (showRemoved) {
                options = options.filter((e) => !model.biomarkers[id].find((k) => k === e[primaryKey]))
            }
            if (filter) {
                options = options.filter((e) => 
                    (e.name || '').toLowerCase().indexOf(filter.toLowerCase()) >= 0
                    || (e.code || '').toLowerCase().indexOf(filter.toLowerCase()) >= 0
                );
            }

            return <div align='left'>
                <TextField style={{ width: 500 }} label='Filter' value={filter} onChange={(e) => self.setState({ filter: e.target.value })} />
                <IconButton disabled={!filter} onClick={() => self.setState({ filter: '' })}><ClearIcon /></IconButton>
                <FormControlLabel label='Added' control={<Checkbox checked={showAdded} onChange={(e) => self.setState({ showAdded: e.target.checked, showRemoved: false })} />} />
                <FormControlLabel label='Removed' control={<Checkbox checked={showRemoved} onChange={(e) => self.setState({ showRemoved: e.target.checked, showAdded: false })} />} />
                <EnhancedTable
                    style={{ width: 950 }}
                    onActions={(table, numSelected, actions) => {
                        if (numSelected > 0) {
                            actions.unshift(<Tooltip title='Remove' key='remove'><IconButton onClick={self.onRemoveFeatures.bind(self, table, id, primaryKey)}><RemoveIcon /></IconButton></Tooltip>);
                            actions.unshift(<Tooltip title='Add' key='add'><IconButton onClick={self.onAddFeatures.bind(self, table, id, primaryKey)}><AddIcon /></IconButton></Tooltip>);
                        }
                    }}
                    disableDelete={true}
                    disableAdd={true}
                    orderBy='name'
                    columnData={columns}
                    data={options}
                    title={title} />
            </div>
        }

        const isExpanded = (id) => {
            if (expanded[id] === undefined) expanded[id] = false;
            return expanded[id];
        }
        const handleAccordian = (id) => (e, isExpanded) => {
            expanded[id] = isExpanded;
            this.setState({ expanded });
        }

        return <Drawer open={true} variant='persistent' anchor='right'>
            <AppBar style={{ width: '100%', backgroundColor: '#FF9800' }} position="static">
                <Toolbar><IconButton onClick={this.props.onCloseView}><CloseIcon /></IconButton>
                    <Typography variant="h6" color="inherit">Select Triggers</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ width: 1000 }}>
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }} expanded={isExpanded('conditionTriggers')} onChange={handleAccordian('conditionTriggers')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Conditions<i>({biomarkers.conditionTriggers.length})</i></AccordionSummary>
                    <AccordionDetails>
                        {isExpanded('conditionTriggers') ? getFeatureTable(this, { title: 'Conditions', id: 'conditionTriggers', options: DataTypesStore.getConditions(), primaryKey: 'conditionId' }) : null}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }} expanded={isExpanded('medicationTriggers')} onChange={handleAccordian('medicationTriggers')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Medications<i>({biomarkers.medicationTriggers.length})</i></AccordionSummary>
                    <AccordionDetails>
                        {isExpanded('medicationTriggers') ? getFeatureTable(this, { title: 'Medications', id: 'medicationTriggers', options: DataTypesStore.getMedications(), primaryKey: 'medicationId' }) : null}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }} expanded={isExpanded('procedureTriggers')} onChange={handleAccordian('procedureTriggers')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Procedures<i>({biomarkers.procedureTriggers.length})</i></AccordionSummary>
                    <AccordionDetails>
                        {isExpanded('procedureTriggers') ? getFeatureTable(this, { title: 'Procedures', id: 'procedureTriggers', options: DataTypesStore.getProcedures(), primaryKey: 'procedureId' }) : null}
                    </AccordionDetails>
                </Accordion>
            </div>
        </Drawer>
    }
}