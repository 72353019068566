import React from "react";

import {
    Drawer,
    AppBar,
    Typography,
    Toolbar,
    IconButton,
    CircularProgress
} from '@material-ui/core/';

import NavigationClose from '@material-ui/icons/Close';

import {
    getReportSeries, isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services';

import { Diff2Html } from "diff2html";
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import User from "@apricityhealth/web-common-lib/components/User";

// CSS
import "../styles/content.css";
import "diff2html/dist/diff2html.min.css";
import preventClickthrough from "react-prevent-clickthrough";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import Plan from "@apricityhealth/web-common-lib/components/Plan";

class ChangesView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changeKeys: props.changeKeys,
            primaryKey: props.primaryKey || [],
            collection: props.collection || [],
            limit: props.limit || 100,
            changes: [],
            diff: ''
        };
    }

    componentDidUpdate(oldProps) {
        console.log(`component did update `, oldProps.primaryKey, this.props.primaryKey)
        if (this.props.primaryKey !== oldProps.primaryKey || this.props.changeKeys !== oldProps.changeKeys || this.props.collection !== oldProps.collection) {
            this.setState({ primaryKey: this.props.primaryKey, changeKeys: this.props.changeKeys, collection: this.props.collection }, () => this.loadContent())
        }
    }


    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        const { appContext } = this.props;
        let { diff } = this.state;
        const { planId } = appContext.state.plan ? appContext.state.plan : '';
        this.setState({ progress: <CircularProgress size={20} />, error: null });
        let { changeKeys, primaryKey, collection, limit } = this.state;
        if (!Array.isArray(collection)) collection = [collection];
        if (!Array.isArray(primaryKey)) primaryKey = [primaryKey];

        if ( changeKeys ) {
            collection = [];
            primaryKey = [];
            for(let k in changeKeys) {
                if ( collection.indexOf(k) < 0)
                    collection.push(k);
                let keys = changeKeys[k];
                for(let j in keys) {
                    if ( primaryKey.indexOf(keys[j]) < 0)
                        primaryKey.push( keys[j] );
                }
            }
        }

        let args = { planId, dataId: primaryKey, collectionName: collection, limit };
        getReportSeries(appContext, 'Change', args).then((response) => {
            let changes = response.data ? response.data.results : [];
            let versions = response.data ? response.data.versions : [];
            if (isArrayValid(changes)) {
                let change = changes[0]
                diff = change.diff ? Diff2Html.getPrettyHtml(change.diff,
                    { inputFormat: 'diff', showFiles: false, matching: 'lines', renderNothingWhenEmpty: true, outputFormat: 'line-by-line' }
                ) : "";
            }
            this.setState({ progress: null, primaryKey, collection, changes, versions, diff });
        }).catch((error) => {
            this.setState({ progress: null, errror: getErrorMessage(error) });

        });
    }

    onChangeSelected(s, t) {
        if (s.length > 0) {
            const { changes } = this.state;
            const change = changes[s[0]];
            let diff = change.diff ? Diff2Html.getPrettyHtml(change.diff,
                { inputFormat: 'diff', showFiles: false, matching: 'lines', renderNothingWhenEmpty: true, outputFormat: 'line-by-line' }
            ) : "";

            this.setState({ diff });
        } else {
            this.setState({ diff: '' });
        }
    }

    getChangesReport() {
        const { changes } = this.state;
        let columData = [
            { id: 'userId', label: 'User', formatValue: (v) => <User appContext={this.props.appContext} userId={v} userIdHidden={false} /> },
            { id: 'dataId', label: 'Primary Key' },
            { id: 'changeType', label: 'Change Type' },
            { id: 'createDate', label: 'Date' },
            { id: 'version', label: 'Version' },
            { id: 'planId', label: 'Plan', formatValue: (v) => <Plan appContext={this.props.appContext} planId={v} /> }
        ];
        return <EnhancedTable
            onSelected={(s, t) => this.onChangeSelected(s, t)}
            disableMultiSelect={true}
            disableActions={true}
            onTable={table => this._table = table}
            orderBy='createdAt'
            columnData={columData}
            data={changes}
            rowsPerPage={5}
            title='Changes'
        />;
    }

    render() {
        let { diff, collection, primaryKey, progress, error } = this.state;
        if (!Array.isArray(primaryKey)) primaryKey = [primaryKey];
        if (!Array.isArray(collection)) collection = [collection];

        const keys = primaryKey.slice(0, 5).join(',').slice(0,64);
        const title = `Changes to ${keys} (${collection.join(',')})`
        return <Drawer variant="persistent" anchor="right" open={true} onClick={(e) => preventClickthrough(e)}>
            <AppBar style={styles.appBar} position="static">
                <Toolbar>
                    <IconButton onClick={this.props.onDone}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="h6" color="inherit">{title}</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ width: 1000 }}>
                {progress}{error}
                <div style={{ width: '98%', float: 'left' }}>
                    {this.getChangesReport()}
                    <div dangerouslySetInnerHTML={{ __html: diff }} />
                </div>
            </div>
        </Drawer>;
    }
};

const styles = {
    appBar: {
        backgroundColor: "#FF9800"
    }
}

export default ChangesView;

