import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import React from 'react';

import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import {
    getReportSeries,
    loadDataTypes,
} from '@apricityhealth/web-common-lib/utils/Services';

import Config from '@apricityhealth/web-common-lib/Config';
import { FormControl, InputLabel, Select, MenuItem, TextField, IconButton } from '@material-ui/core';

import RefreshIcon from '@material-ui/icons/Refresh';

HighchartsMore(Highcharts);

Highcharts.seriesType('lowmedhigh', 'boxplot', {
    keys: ['low', 'median', 'high'],
    tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: ' +
            'Low <b>{point.low} NOK</b> - Median <b>{point.median} NOK</b> - High <b>{point.high} NOK</b><br/>'
    }
}, {
    // Change point shape to a line with three crossing lines for low/median/high
    // Stroke width is hardcoded to 1 for simplicity
    drawPoints: function () {
        const series = this;
        this.points.forEach(function (point) {
            let graphic = point.graphic;
            const verb = graphic ? 'animate' : 'attr',
                shapeArgs = point.shapeArgs,
                width = shapeArgs.width,
                left = Math.floor(shapeArgs.x) + 0.5,
                right = left + width,
                crispX = left + Math.round(width / 2) + 0.5,
                highPlot = Math.floor(point.highPlot) + 0.5,
                medianPlot = Math.floor(point.medianPlot) + 0.5,
                // Sneakily draw low marker even if 0
                lowPlot = Math.floor(point.lowPlot) +
                    0.5 - (point.low === 0 ? 1 : 0);

            if (point.isNull) {
                return;
            }

            if (!graphic) {
                point.graphic = graphic = series.chart.renderer
                    .path('point')
                    .add(series.group);
            }

            graphic.attr({
                stroke: point.color || series.color,
                'stroke-width': 2
            });

            graphic[verb]({
                d: [
                    'M', left, highPlot,
                    'H', right,
                    'M', left, medianPlot,
                    'H', right,
                    'M', left, lowPlot,
                    'H', right,
                    'M', crispX, highPlot,
                    'V', lowPlot
                ]
            });
        });
    }
});

export class ProteomicsGraph extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            patientId: '',
            responseFilter: '',
            importId: '',
            imports: [],
            dataTypes: [],
            dataId: 'A1BG',
            report: {},
            error: null
        };
    }
    
    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        this.loadImports();
        this.loadDataTypes();
        this.loadReportSeries();
    }

    loadReportSeries() {
        const { importId, dataId, responseFilter } = this.state;

        this.setState({error: null, report: {} });
        getReportSeries(this.props.appContext, "ProteomicsData", { importId, proteomicDataId: dataId, responseFilter } ).then((result) => {
            console.log("loadReportSeries result:", result );
            this.setState({ report: result.data.results })
        }).catch((err) => {
            console.error("loadReportSeries error:", err );
            this.setState({error: getErrorMessage(err) });
        })
    }

    loadDataTypes() {
        loadDataTypes(this.props.appContext, { category: 'proteomicsData' }).then((dataTypes) => {
            console.log("loadDataTypes result:", dataTypes );
            this.setState({ dataTypes })
        }).catch((err) => {
            console.error("loadDataTypes error:", err );
        })
    }

    loadImports() {
        const { appContext: { state: { idToken } } } = this.props;
        const getImports = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/imports`,
            method: 'GET',
            headers: { "Authorization": idToken }
        }
        console.log("getImports request:", getImports);
        AxiosRequest(getImports).then(({ data }) => {
            console.log("getImports result:", data);
            this.setState({ imports: data });
        }).catch((err) => {
            this.setState({ error: getErrorMessage(err) });
        })
    }

    render() {
        const { importId, imports, dataId, dataTypes, report, responseFilter } = this.state;

        let graph = null;
        if ( report.proteomicDataId ) {

            const weeks = [];
            const series = [];
            for(let k in report.responses) {
                series.push({ name: k, data: [] });
                for(let week in report.responses[k] ) {
                    if ( weeks.indexOf(Number(week)) < 0 ) {
                        weeks.push( Number(week) );
                    }
                }
            }
            weeks.sort((a,b) => a - b );
            console.log("weeks:", weeks );

            const options = {
                chart: {
                    type: 'lowmedhigh'
                },
                title: {
                    text: `Proteomics Graph for ${report.proteomicDataId}`,
                    align: 'left'
                },
                subtitle: {
                    text: '',
                    align: 'left'
                },
                accessibility: {
                    point: {
                        descriptionFormat: '{#unless isNull}{category}, low {low}, median {median}, high {high}{/unless}'
                    },
                    series: {
                        descriptionFormat: '{series.name}, series {seriesNumber} of {chart.series.length} with {series.points.length} data points.'
                    },
                    typeDescription: 'Low, median, high. Each data point has a low, median and high value, depicted vertically as small ticks.' // Describe the chart type to screen reader users, since this is not a traditional boxplot chart
                },
                xAxis: [{
                    accessibility: {
                        description: 'Week'
                    },
                    crosshair: true,
                    categories: weeks
                }],
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    shared: true,
                    stickOnContact: true
                },
                plotOptions: {
                    series: {
                        stickyTracking: true,
                        whiskerWidth: 5
                    }
                },
                series
            }

            for(let k in report.responses) {
                const response = report.responses[k];
                const graph = series.find((e) => e.name === k);
                for(let i=0;i<weeks.length;++i) {
                    const week = weeks[i];
                    if ( response[week] !== undefined ) {
                        const data = response[week];
                        graph.data.push([ data.low, data.median, data.high]);
                    } else {
                        graph.data.push([ null, null, null]);       // no data for this week
                    }
                }
            }

            graph = <HighchartsReact
                options={options}
                highcharts={Highcharts}
            />
        }

        return <div>
            <FormControl style={{margin: 5, width: 300}}>
                <InputLabel>Select Protein Type</InputLabel>
                <Select value={dataId} onChange={(e) => this.setState({ dataId: e.target.value}, this.loadReportSeries.bind(this))}>
                    {dataTypes.map((t) => <MenuItem value={t.dataId}>{t.name} ({t.dataId})</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl style={{margin: 5, width: 300}}>
                <InputLabel>Select Data Import</InputLabel>
                <Select value={importId} onChange={(e) => this.setState({ importId: e.target.value}, this.loadReportSeries.bind(this))}>
                    {imports.map((i) => <MenuItem value={i.importId}>{i.description}</MenuItem>)}
                </Select>
            </FormControl>
            <TextField style={{margin: 5, width: 300, marginTop: 5 }} label="Response Filter" value={responseFilter} onChange={(e) => {
                this.setState({responseFilter: e.target.value})
            }} />
            <IconButton onClick={this.loadReportSeries.bind(this)}>
                <RefreshIcon />
            </IconButton>
            <br />
            {graph}
        </div>
    }
}

export default ProteomicsGraph;