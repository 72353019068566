import React from 'react';

import {
    IconButton
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

const LIST_CAP = 5;

function roundTo(n, place) {
    return +(Math.round(n + "e+" + place) + "e-" + place);
}

export class EventChangesView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataId: props.dataId ,
            startDate: props.startDate || null,
            events: props.events,
            clusters: props.clusters,
            history: [],
            showAllLabs: false,
            showAllSymptoms: false,
            showAllOther: false,
            showAllProteomics: false
        }
    }

    componentDidMount() {
        const { appContext: { stores: { DataTypesStore } } } = this.props;
        this.dataTypeCategory = DataTypesStore.getDataTypes().reduce((p, c) => {
            p[c.dataId] = c.category;
            return p;
        }, {});

        this.generateHistory();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.events !== this.props.events) {
            this.setState({ events: this.props.events }, this.generateHistory.bind(this))
        }
    }

    // generates a list of data changes only, from -> to, event objects should be pass into this function
    generateDataChanges(from, to) {
        const { clusters } = this.state;

        function getDataId(k) {
            let sep = k.indexOf(':');
            if ( sep >= 0) {
                return k.substring(0,sep);
            }
            return k;
        }

        let other = [];
        let labs = [];
        let symptoms = [];
        let proteomics = [];
        let labsTruncated = false;
        let symptomsTruncated = false;
        let otherTruncated = false;
        let proteomicsTruncated = false;

        if (from.closestCluster !== to.closestCluster) {
            other.push(<li key='cluster'><b>{`Cluster changed from ${clusters[from.closestCluster].clusterName} to ${clusters[to.closestCluster].clusterName}`}</b></li>);
        }
        if (from.target !== to.target) {
            other.push(<li key='target'><b>{`${this.state.dataId} changed from ${from.target} to ${to.target}`}</b></li>)
        }
        // collect all the data, then sort by the weight of the feature in the to to cluster
        let sortedData = Object.keys(to.data).reduce((p,k) => {
            const cluster = clusters[to.closestCluster];
            const toWeight = cluster.featureWeights && cluster.featureWeights[k] && roundTo(cluster.featureWeights[k] * 100, 1);
            const toValue = Number(to.data[k]);
            const fromValue = Number(from.data[k]);
            const sortValue = toWeight !== undefined ? toWeight : Math.abs(toValue - fromValue);
            
            p.push({ k, fromValue, toValue, toWeight, sortValue })
            return p;
        }, []).sort((a,b) => b.sortValue - a.sortValue );

        for(let i=0;i<sortedData.length;++i) {
            const { k, fromValue, toValue, toWeight } = sortedData[i];
            const delta = toValue - fromValue;
            //console.log(`${k}, fromValue: ${fromValue}, toValue: ${toValue}, delta: ${delta}`);
            if (!Number.isNaN(delta) && delta !== 0) {
                const category = this.dataTypeCategory[getDataId(k)];
                if (category === 'labTest') {
                    if ( to.labsExpanded || labs.length < LIST_CAP ) {
                        const abnormal = toValue >= 1 || toValue <= -1;
                        const color = abnormal ? 'red' : 'green';
                        const fontWeight = abnormal ? 'bold' : 'normal';
                        labs.push(<li key={k}>
                            <span style={{ color, fontWeight }}>{`${k}: ${roundTo(fromValue, 3)} -> ${roundTo(toValue, 3)} (${delta > 0 ? '+' : ''}${roundTo(delta, 3)}) `}{toWeight !== undefined ? <b>{`(${toWeight}%)`}</b> : ''}</span>
                        </li>);
                    } else {
                        labsTruncated = true;
                    }
                }
                else if ( category === 'proteomicsData' ) {
                    if ( to.proteomicsExpanded || proteomics.length < LIST_CAP ) {
                        proteomics.push(<li key={k}>
                            <span>{`${k}: ${roundTo(fromValue, 3)} -> ${roundTo(toValue, 3)} (${delta > 0 ? '+' : ''}${roundTo(delta, 3)}) `}{toWeight !== undefined ? <b>{`(${toWeight}%)`}</b> : ''}</span>
                        </li>);
                    } else {
                        proteomicsTruncated = true;
                    }
                } else if (category === 'symptom') {
                    if ( to.symptomsExpanded || symptoms.length < LIST_CAP ) {
                        const abnormal = toValue > 0;
                        const color = abnormal ? 'red' : 'green';
                        const fontWeight = abnormal ? 'bold' : 'normal';
                        symptoms.push(<li key={k}>
                            <span style={{ color, fontWeight }}>{`${k}: ${roundTo(fromValue, 3)} -> ${roundTo(toValue, 3)} (${delta > 0 ? '+' : ''}${roundTo(delta, 3)}) `}{toWeight !== undefined ? <b>{`(${toWeight}%)`}</b> : ''}</span>
                        </li>);
                    } else {
                        symptomsTruncated = true;
                    }
                } else {
                    if ( to.otherExpanded || other.length < LIST_CAP ) {
                        other.push(<li key={k}>
                            {`${k}: ${roundTo(fromValue, 3)} -> ${roundTo(toValue, 3)} (${delta > 0 ? '+' : ''}${roundTo(delta, 3)}) `}{toWeight !== undefined ? <b>{`(${toWeight}%)`}</b> : ''}
                        </li>);
                    } else {
                        otherTruncated = true;
                    }
                }
            }
        }
        if (other.length === 0 && labs.length === 0 && symptoms.length === 0 && proteomics.length === 0) {
            return null;
        }

        const toggleShow = (prop) => () => {
            if ( to[prop] === undefined ) {
                to[prop] = true;
            } else {
                to[prop] = !to[prop]
            }
            //console.log(`toggleShow(${prop}): ${to[prop]}`)
            this.generateHistory();
        }
        return <ul>
            {other.length > 0 && ((otherTruncated || to.otherExpanded) && <IconButton onClick={toggleShow('otherExpanded')}>{(to.otherExpanded && <ExpandLessIcon />) || <ExpandMoreIcon />}</IconButton>)}
            {other.length > 0 && other}
            {labs.length > 0 && <li>Labs:
                {((labsTruncated || to.labsExpanded) && <IconButton onClick={toggleShow('labsExpanded')}>{(to.labsExpanded && <ExpandLessIcon />) || <ExpandMoreIcon />}</IconButton>)}
                <ul>{labs}</ul></li>}
            {proteomics.length > 0 && <li>Proteomics:
                {((proteomicsTruncated || to.proteomicsExpanded) && <IconButton onClick={toggleShow('proteomicsExpanded')}>{(to.proteomicsExpanded && <ExpandLessIcon />) || <ExpandMoreIcon />}</IconButton>)}
                <ul>{proteomics}</ul></li>}
            {symptoms.length > 0 && <li>Symptoms:
                {((symptomsTruncated || to.symptomsExpanded) && <IconButton onClick={toggleShow('symptomsExpanded')}>{(to.symptomsExpanded && <ExpandLessIcon />) || <ExpandMoreIcon />}</IconButton>)}
                <ul>{symptoms}</ul></li>}
        </ul>;
    }

    generateHistory() {
        const history = [];

        const { events, clusters } = this.state;
        events.sort((a, b) => a.day - b.day);
        console.log("generateHistory:", events);
        if ( events.length > 0 ) {
            const event = events[0];
            const clusterAssigned = <li key='cluster'><b>{`Assigned to cluster ${clusters[event.closestCluster].clusterName}`}</b></li>;
            const targetAssigned = <li key='target'><b>{`${this.state.dataId} is ${event.target}`}</b></li>;
            history.push(<li key={history.length}>{`Day: ${event.day}, ${event.eventTime.format(DATE_FORMAT)}`}<ul>{clusterAssigned}{targetAssigned}</ul></li>);
        }

        for (let i = 0; i < (events.length - 1); ++i) {
            const toEvent = events[i + 1];
            const fromEvent = events[i];
            //console.log('to/from event:', toEvent, fromEvent );
            const changes = this.generateDataChanges(fromEvent, toEvent);
            if (!changes) continue;
            const eventDelta = <li key={history.length}>{`Day: ${toEvent.day}, ${toEvent.eventTime.format(DATE_FORMAT)}`}{changes}</li>;
            history.push(eventDelta);
        }

        this.setState({ history });
    }

    render() {
        const { history } = this.state;
        return history.length > 0 ? <ul>{history}</ul> : "No event changes...";
    }
}

export default EventChangesView;
