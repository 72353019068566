export function createIdFromText(text) {
    function isAlphaNumeric(code) {
        return (code >= '0' && code <= '9')     // numbers (0-9)
            || (code >= 'A' && code <= 'Z')     // upper alpha (A-Z)
            || (code >= 'a' && code <= 'z');   // lower alpha (a-z)
    };
    let id = '';
    let upperCaseNext = false;
    for (let i = 0; i < text.length; ++i) {
        let code = text[i];
        if (isAlphaNumeric(code)) {
            if (upperCaseNext) {
                code = code.toUpperCase();
                upperCaseNext = false;
            } else {
                code = code.toLowerCase();
            }
            id += code;
        } else {
            upperCaseNext = true;
        }
    }
    return id;
}
export function createTextFromId(id) {
    let text = '';
    for (let i = 0; i < id.length; ++i) {
        let next = id[i];
        if (i === 0)
            next = next.toUpperCase();
        if (next === next.toUpperCase()) {
            text += " ";
            text += next;
        } else {
            text += next;
        }

    }
    return text;
}

export default createIdFromText;