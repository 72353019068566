import React, { Component } from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select
} from '@material-ui/core/';

import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import OverrideDialog from "../dialogs/OverrideDialog";

class EditTupleDescriptionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            tupleDescription: JSON.parse( JSON.stringify(props.tupleDescription))
        };
    }

    componentWillReceiveProps(props) {
        this.setState({ tupleDescription: JSON.parse( JSON.stringify(props.tupleDescription)) });
    }


    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    displayOverrideDialog( oldPlanId, callback) {
        this.setState({ dialog: <OverrideDialog appContext={this.props.appContext} 
            oldPlanId={oldPlanId} parent={this} onConfirm={callback} /> });        
    }

    onDone(planId = null) {
        const self = this;
        const { onDone, appContext, dataType, tupleIndex, disableSave } = this.props;
        const { tupleDescription } = this.state;
        const store = appContext.stores.DataTypesStore;

        if ( disableSave === true ) {
            dataType.tupleDescriptions[tupleIndex] = tupleDescription;
            if ( onDone )
                onDone(dataType);
        }
        else {
            if ( !planId && dataType.planId && dataType.planId !== appContext.state.plan.planId ) {
                return this.displayOverrideDialog( dataType.planId, this.onDone.bind(this) );
            }

            self.setState({
                progress: <CircularProgress />
            });

            dataType.tupleDescriptions[tupleIndex] = tupleDescription;
            console.log("onDone:", dataType, tupleIndex );
            store.saveDataType(dataType, planId).then(() => {
                if (onDone)
                    onDone(dataType);
                self.setState({ progress: null });
            }).catch((error) => {
                self.setState({ progress: error });
            });
        }
    }

    onChange = type => e => {
        let { tupleDescription } = this.state;
        tupleDescription[type] = e.target ? e.target.value : e;
        this.setState({ tupleDescription });
    }

    onUpdateValueDescriptions(values) {
        let { tupleDescription } = this.state;
        tupleDescription.valueDescriptions = values; 
        this.setState( { tupleDescription });
    }

    render() {
        let { tupleDescription, progress, dialog } = this.state;
        let self = this;

        if (!tupleDescription) {
            tupleDescription = {
                description: "description",
                dataType: 'String',
                classType: 'Answer',
                origin: '',
                valueDescriptions: [],
            };
            this.setState({ tupleDescription });
        }
        if (! Array.isArray(tupleDescription.valueDescriptions))
            tupleDescription.valueDescriptions = [];

        const columnData = [
            { id: 'value', numeric: false, disabledPadding: false, editType: 'text', width: "5rem", label: 'Value' },
            { id: 'description', numeric: false, disabledPadding: false, editType: 'text',  width: "30rem", label: 'Description' },
        ];

        return (
            <Dialog maxWidth={'md'} fullWidth={true} model="false" open={true}>
                <DialogContent>
                    <FormControl style={styles.text}>
                        <InputLabel>Data Type:</InputLabel>
                        <Select value={tupleDescription.dataType} onChange={self.onChange("dataType")}>
                            <MenuItem value='String'>String</MenuItem>
                            <MenuItem value='Number'>Number</MenuItem>
                            <MenuItem value='Date'>Date</MenuItem>
                            <MenuItem value='Image'>Image</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <FormControl style={styles.text}>
                        <InputLabel>Class Type:</InputLabel>
                        <Select value={tupleDescription.classType} onChange={self.onChange("classType")}>
                            <MenuItem value='Answer'>Answer</MenuItem>
                            <MenuItem value='Baseline'>Baseline</MenuItem>
                            <MenuItem value='BodyLocation'>Body Location</MenuItem>
                            <MenuItem value='Cumulative'>Cumulative</MenuItem>
                            <MenuItem value='Duration'>Duration</MenuItem>
                            <MenuItem value='DurationSame'>DurationSame</MenuItem>
                            <MenuItem value='Lab'>Lab</MenuItem>
                            <MenuItem value='LabNormalized'>LabNormalized</MenuItem>
                            <MenuItem value='PostBaseline'>PostBaseline</MenuItem>
                            <MenuItem value='Rate'>Rate</MenuItem>
                            <MenuItem value='RecordId'>Record ID</MenuItem>
                            <MenuItem value='Score'>Score</MenuItem>
                            <MenuItem value='Slope'>Slope</MenuItem>
                            <MenuItem value='Unit'>Unit</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <TextField
                        style={styles.description}
                        label="Description"
                        value={tupleDescription.description}
                        onChange={self.onChange("description")}
                    />

                    <br />
                    <TextField
                        style={styles.text}
                        label="Origin"
                        value={tupleDescription.origin}
                        onChange={self.onChange("origin")}
                    />
                    <TextField
                        style={styles.text}
                        label="Unit"
                        value={tupleDescription.unit}
                        onChange={self.onChange("unit")}
                    />
                    <br />
                    <EnhancedTable
                        disableMultiSelect={false}
                        rowsPerPage={5}
                        orderBy='value'
                        columnData={columnData}
                        data={tupleDescription.valueDescriptions}
                        onDataChanged={this.onUpdateValueDescriptions.bind(this)}
                        title='Values' />
                    {progress}
                    {dialog}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onDone() }}>Save</Button>
                    <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = {
    div: {
        margin: 10
    },
    text: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    },
    button: {
        margin: 10
    }
};

export default EditTupleDescriptionDialog;
