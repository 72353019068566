import { AppBar, Drawer, Toolbar, IconButton, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ValidIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear'
import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';

import preventClickThrough from 'react-prevent-clickthrough';

const DEMOGRAPHIC_TYPES = [
    { id: 'age', name: 'Age' },
    { id: 'gender', name: 'Gender' },
    { id: 'race', name: 'Race' },
    { id: 'ethnicity', name: 'Ethnicity' },
    { id: 'latitude', name: 'Latitude' },
    { id: 'longitude', name: 'Longitude' },
];

export default class FeatureSelectionView extends React.Component {
    constructor(props) {
        super(props);

        if (!props.appContext) throw new Error("FeatureSelectionView expects a appContext property!")
        if (!props.model) throw new Error("FeatureSelectionView expects a model property!")
        if (!props.onCloseView) throw new Error("FeatureSelectionView expects a onCloseView property!")

        this.state = {
            model: props.model,
            showAdded: false,
            showRemoved: false,
            filter: '',
            expanded: {}
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.model !== this.props.model) {
            this.setState({ model: this.props.model }, this.loadContent.bind(this));
        }
    }

    loadContent() {
        // const { appContext: { stores: { DataTypesStore }} } = this.props;
        // const { model } = this.state;

    }

    onAddFeatures(table, ids, primaryKey) {
        const { model } = this.state;
        const selected = table.state.data.filter((e) => table.state.selected.indexOf(e._index) >= 0);
        console.log(`onAddFeatures, ids: ${ids}, primaryKey: ${primaryKey}, selected:`, selected);
        ids = ids.split(',');


        for (let i = 0; i < selected.length; ++i) {
            const add = selected[i][primaryKey];
            ids.forEach((id) => {
                if (!Array.isArray(model.biomarkers[id])) model.biomarkers[id] = [];
                if (model.biomarkers[id].indexOf(add) < 0) {
                    model.biomarkers[id].push(add);
                }
                console.log(`model.biomarkers[${id}]:`, model.biomarkers[id])
            });
        }

        this.setState({ model });
        table.setState({ selected: [] });
    }

    onRemoveFeatures(table, ids, primaryKey) {
        const { model } = this.state;
        const selected = table.state.data.filter((e) => table.state.selected.indexOf(e._index) >= 0);
        console.log(`onRemoveFeatures, id: ${ids}, primaryKey: ${primaryKey}, selected:`, selected);
        ids = ids.split(',');

        for (let i = 0; i < selected.length; ++i) {
            const remove = selected[i][primaryKey];
            ids.forEach((id) => {
                if (!Array.isArray(model.biomarkers[id])) model.biomarkers[id] = [];
                let j = model.biomarkers[id].indexOf(remove);
                if (j >= 0) {
                    model.biomarkers[id].splice(j, 1)
                }
                console.log(`model.biomarkers[${ids}]:`, model.biomarkers[ids])
            });
        }

        this.setState({ model });
        table.setState({ selected: [] });
    }

    render() {
        const { expanded, model, filter, showAdded, showRemoved } = this.state;
        const { biomarkers } = model;
        const { appContext: { stores: { DataTypesStore } } } = this.props;

        if (!Array.isArray(biomarkers.demographics)) biomarkers.demographics = [];
        if (!Array.isArray(biomarkers.proteomicsData)) biomarkers.proteomicsData = [];
        if (!Array.isArray(biomarkers.labs)) biomarkers.labs = [];
        if (!Array.isArray(biomarkers.labs_normal)) biomarkers.labs_normal = [];
        if (!Array.isArray(biomarkers.labs_high)) biomarkers.labs_high = [];
        if (!Array.isArray(biomarkers.labs_low)) biomarkers.labs_low = [];
        if (!Array.isArray(biomarkers.symptoms)) biomarkers.symptoms = [];
        if (!Array.isArray(biomarkers.conditions)) biomarkers.conditions = [];
        if (!Array.isArray(biomarkers.medications)) biomarkers.medications = [];
        if (!Array.isArray(biomarkers.procedures)) biomarkers.procedures = [];

        function getFeatureTable(self, { title, id, options, primaryKey }) {
            console.log(`getFeatureTable, title: ${title}, id: ${id}, primaryKey: ${primaryKey}, options:`, options);
            if (model.biomarkers[id] === undefined) model.biomarkers[id] = [];
            let enableSystemCode = options.length > 0 && (options[0].code || options[0].system);
            const columns = [
                {
                    id: primaryKey, width: 10, label: 'Added', formatValue: (v) => {
                        const added = model.biomarkers[id].find((e) => e === v);          // it's either an array if ID's or an array with a dataId property, so just search for either
                        return added ? <ValidIcon key='valid' style={{ height: 20, fill: 'green', padding: 0, margin: 0 }} /> : '';
                    }
                }
            ];
            if ( enableSystemCode ) {
                columns.push(
                    {
                        id: 'system', label: 'System'
                    },
                    {
                        id: 'code', label: 'Code'
                    }
                )
            } else {
                columns.push(
                    {
                        id: primaryKey, label: 'ID'
                    }
                )
            }
            columns.push( { id: 'name', label: 'Name' })

            if (showAdded) {
                options = options.filter((e) => model.biomarkers[id].find((k) => k === e[primaryKey]))
            }
            if (showRemoved) {
                options = options.filter((e) => !model.biomarkers[id].find((k) => k === e[primaryKey]))
            }
            if (filter) {
                options = options.filter((e) => 
                    (e.name || '').toLowerCase().indexOf(filter.toLowerCase()) >= 0
                    || (e.code || '').toLowerCase().indexOf(filter.toLowerCase()) >= 0
                    || (e[primaryKey] || '').toLowerCase().indexOf(filter.toLowerCase()) >= 0
                );
            }

            return <div align='left'>
                <TextField style={{ width: 500 }} label='Filter' value={filter} onChange={(e) => self.setState({ filter: e.target.value })} />
                <IconButton disabled={!filter} onClick={() => self.setState({ filter: '' })}><ClearIcon /></IconButton>
                <FormControlLabel label='Added' control={<Checkbox checked={showAdded} onChange={(e) => self.setState({ showAdded: e.target.checked, showRemoved: false })} />} />
                <FormControlLabel label='Removed' control={<Checkbox checked={showRemoved} onChange={(e) => self.setState({ showRemoved: e.target.checked, showAdded: false })} />} />
                <EnhancedTable
                    style={{ width: 950 }}
                    onActions={(table, numSelected, actions) => {
                        if (numSelected > 0) {
                            actions.unshift(<Tooltip title='Remove' key='remove'><IconButton onClick={self.onRemoveFeatures.bind(self, table, id, primaryKey)}><RemoveIcon /></IconButton></Tooltip>);
                            actions.unshift(<Tooltip title='Add' key='add'><IconButton onClick={self.onAddFeatures.bind(self, table, id, primaryKey)}><AddIcon /></IconButton></Tooltip>);
                        }
                    }}
                    disableDelete={true}
                    disableAdd={true}
                    orderBy='name'
                    columnData={columns}
                    data={options}
                    title={title} />
            </div>
        }

        function getLabFormatValue(self,id) {
            return (v) => {
                const added = model.biomarkers[id].find((e) => e === v) || false;          // it's either an array if ID's or an array with a dataId property, so just search for either
                console.log(`getLabFormatValue for ${v}, id: ${id}, added: ${added}`);
                return <Checkbox style={{margin: 0, padding: 0}} checked={added} onClick={(e) => {
                    preventClickThrough(e);
                    const b = e.target.checked;
                    if (b) {
                        model.biomarkers[id].push(v);
                    } else {
                        let remove = model.biomarkers[id].indexOf(v);
                        if ( remove >= 0)
                            model.biomarkers[id].splice( remove, 1 );
                    }
                    console.log(`model.biomarkers.${id}:`, b, v, model.biomarkers[id])
                    self.setState({model});
                }} />
            }                
        }
        function getLabsTable(self, { title, options, primaryKey }) {
            console.log(`getLabsTable, title: ${title}, primaryKey: ${primaryKey}, options:`, options);
            const columns = [
                { id: primaryKey, width: 10, label: 'Normalized/Value', formatValue: getLabFormatValue(self, 'labs') },
                { id: primaryKey, width: 10, label: 'Normal', formatValue: getLabFormatValue(self, 'labs_normal') },
                { id: primaryKey, width: 10, label: 'High', formatValue: getLabFormatValue(self, 'labs_high') },
                { id: primaryKey, width: 10, label: 'Low', formatValue: getLabFormatValue(self, 'labs_low') },
                { id: primaryKey, label: 'ID' },
                { id: 'name', label: 'Name' }
            ];

            if (showAdded) {
                options = options.filter((e) => 
                    model.biomarkers.labs.find((k) => k === e[primaryKey]) ||
                    model.biomarkers.labs_normal.find((k) => k === e[primaryKey]) ||
                    model.biomarkers.labs_high.find((k) => k === e[primaryKey]) ||
                    model.biomarkers.labs_low.find((k) => k === e[primaryKey])
                );
            }
            if (showRemoved) {
                options = options.filter((e) => 
                    !model.biomarkers.labs.find((k) => k === e[primaryKey]) &&
                    !model.biomarkers.labs_normal.find((k) => k === e[primaryKey]) &&
                    !model.biomarkers.labs_high.find((k) => k === e[primaryKey]) &&
                    !model.biomarkers.labs_low.find((k) => k === e[primaryKey])
                );
            }
            if (filter) {
                options = options.filter((e) => (e.name || '').toLowerCase().indexOf(filter.toLowerCase()) >= 0 || e.dataId.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
            }

            return <div align='left'>
                <TextField style={{ width: 500 }} label='Filter' value={filter} onChange={(e) => self.setState({ filter: e.target.value })} />
                <IconButton disabled={!filter} onClick={() => self.setState({ filter: '' })}><ClearIcon /></IconButton>
                <FormControlLabel label='Added' control={<Checkbox checked={showAdded} onChange={(e) => self.setState({ showAdded: e.target.checked, showRemoved: false })} />} />
                <FormControlLabel label='Removed' control={<Checkbox checked={showRemoved} onChange={(e) => self.setState({ showRemoved: e.target.checked, showAdded: false })} />} />
                <EnhancedTable
                    style={{ width: 950 }}
                    onActions={(table, numSelected, actions) => {
                        if (numSelected > 0) {
                            actions.unshift(<Tooltip title='Remove' key='remove'><IconButton onClick={self.onRemoveFeatures.bind(self, table, 'labs,labs_normal,labs_high,labs_low', primaryKey)}><RemoveIcon /></IconButton></Tooltip>);
                            actions.unshift(<Tooltip title='Add' key='add'><IconButton onClick={self.onAddFeatures.bind(self, table, 'labs,labs_normal,labs_high,labs_low', primaryKey)}><AddIcon /></IconButton></Tooltip>);
                        }
                    }}
                    disableDelete={true}
                    disableAdd={true}
                    orderBy='name'
                    columnData={columns}
                    data={options}
                    title={title} />
            </div>
        }

        const isExpanded = (id) => {
            if (expanded[id] === undefined) expanded[id] = false;
            return expanded[id];
        }
        const handleAccordian = (id) => (e, isExpanded) => {
            expanded[id] = isExpanded;
            this.setState({ expanded });
        }
        const symptomDataTypes = DataTypesStore.getDataTypes().filter((e) => e.category === 'symptom');
        const labDataTypes = DataTypesStore.getDataTypes().filter((e) => e.category === 'labTest' || e.category === 'predictor');
        const proteomicsDataTypes = DataTypesStore.getDataTypes().filter((e) => e.category === 'proteomicsData');
        return <Drawer open={true} variant='persistent' anchor='right'>
            <AppBar style={{ width: '100%', backgroundColor: '#FF9800' }} position="static">
                <Toolbar><IconButton onClick={this.props.onCloseView}><CloseIcon /></IconButton>
                    <Typography variant="h6" color="inherit">Feature Selection</Typography>
                </Toolbar>
            </AppBar>
            <div style={{ width: 1000 }}>
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }} expanded={isExpanded('demographics')} onChange={handleAccordian('demographics')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Demographics <i>({biomarkers.demographics.length})</i></AccordionSummary>
                    <AccordionDetails>
                        {isExpanded('demographics') ? getFeatureTable(this, { title: 'Demographics', id: 'demographics', options: DEMOGRAPHIC_TYPES, primaryKey: 'id' }) : null}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }} expanded={isExpanded('symptoms')} onChange={handleAccordian('symptoms')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>PRO Symptoms <i>({biomarkers.symptoms.length})</i></AccordionSummary>
                    <AccordionDetails>
                        {isExpanded('symptoms') ? getFeatureTable(this, { title: 'PRO Symptoms', id: 'symptoms', options: symptomDataTypes, primaryKey: 'dataId' }) : null}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }} expanded={isExpanded('proteomicsData')} onChange={handleAccordian('proteomicsData')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Proteomics Data <i>({biomarkers.proteomicsData.length})</i></AccordionSummary>
                    <AccordionDetails>
                        {isExpanded('proteomicsData') ? getFeatureTable(this, { title: 'Proteomics Data', id: 'proteomicsData', options: proteomicsDataTypes, primaryKey: 'dataId' }) : null}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }} expanded={isExpanded('labs')} onChange={handleAccordian('labs')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Labs <i>({biomarkers.labs.length + biomarkers.labs_normal.length + biomarkers.labs_high.length + biomarkers.labs_low.length})</i></AccordionSummary>
                    <AccordionDetails>
                        {isExpanded('labs') ? getLabsTable(this, { title: 'Labs', options: labDataTypes, primaryKey: 'dataId' }) : null}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }} expanded={isExpanded('conditions')} onChange={handleAccordian('conditions')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Conditions <i>({biomarkers.conditions.length})</i></AccordionSummary>
                    <AccordionDetails>
                        {isExpanded('conditions') ? getFeatureTable(this, { title: 'Conditions', id: 'conditions', options: DataTypesStore.getConditions(), primaryKey: 'conditionId' }) : null}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }} expanded={isExpanded('medications')} onChange={handleAccordian('medications')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Medications <i>({biomarkers.medications.length})</i></AccordionSummary>
                    <AccordionDetails>
                        {isExpanded('medications') ? getFeatureTable(this, { title: 'Medications', id: 'medications', options: DataTypesStore.getMedications(), primaryKey: 'medicationId' }) : null}
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }} expanded={isExpanded('procedures')} onChange={handleAccordian('procedures')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Procedures <i>({biomarkers.procedures.length})</i></AccordionSummary>
                    <AccordionDetails>
                        {isExpanded('procedures') ? getFeatureTable(this, { title: 'Procedures', id: 'procedures', options: DataTypesStore.getProcedures(), primaryKey: 'procedureId' }) : null}
                    </AccordionDetails>
                </Accordion>
            </div>
        </Drawer>
    }
}