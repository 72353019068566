import React, { Component } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core/';

import Plan from '@apricityhealth/web-common-lib/components/Plan';

class OverrideDialog extends Component {
    constructor(props) {
        super(props);

        if (! props.appContext ) throw new Error("appContext property missing.");
        if (! props.oldPlanId ) throw new Error("oldPlanId property missing.");
        if (! props.parent ) throw new Error("parent property missing");
        if (! props.onConfirm ) throw new Error("onConfirm property missing");

        this.state = {
            oldPlanId: props.oldPlanId,
            newPlanId: props.appContext.state.plan.planId
        }
    }

    render() {
        const { appContext } = this.props;
        const { newPlanId, oldPlanId } = this.state;

        let oldPlan = <Plan appContext={appContext} planId={oldPlanId} />;
        let newPlan = <Plan appContext={appContext} planId={newPlanId} />;

        return (
            <Dialog open={true}>
                <DialogTitle>Create Override</DialogTitle>
                <DialogContent>Create override of data in {oldPlan} with {newPlan}, are you sure?</DialogContent>
                <DialogActions>
                    <Button variant="contained" style={styles.button} onClick={(e) => {
                        this.props.parent.setState({dialog: null});
                        this.props.onConfirm(newPlanId)
                    }}>Yes</Button>
                    <Button variant="contained" style={styles.button} onClick={(e) => {
                        this.props.parent.setState({dialog: null});
                        this.props.onConfirm(oldPlanId)
                    }}>No</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = {
    div: {
        margin: 10
    },
    button: {
        margin: 10
    }
};

export default OverrideDialog;
