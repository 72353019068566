import React from 'react';
import ReactFileReader from 'react-file-reader';
import Moment from 'moment';

import {
    Button,
    Paper,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    LinearProgress,
    Tabs,
    Tab,
    IconButton,
    CircularProgress,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core/';

import RefreshIcon from '@material-ui/icons/Refresh';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import User from '@apricityhealth/web-common-lib/components/User';
import Config from '@apricityhealth/web-common-lib/Config';
import { addPatient, savePatientData } from '@apricityhealth/web-common-lib/utils/Services';
import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';

import Sequential from 'promise-sequential';

const CSV = require('neat-csv');
const Promise = require('bluebird');

export class ImportView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: null,
            descriptionFiles: [],
            patientRows: [],
            dataFiles: [],
            dataRows: [],
            uniqueDataPoints: 0,
            delta: false,
            mappings: {
                descriptions: {
                    sampleId: 'SampleID',
                    patientId: 'PatientID',
                    eventTime: 'Date Collected',
                    dob: 'Date of Birth',
                    gender: 'Gender',
                    response: 'Response',
                    responseType: 'ResponseType',
                    therapyName: 'Systemic Therapy Name',
                    therapyType: 'Systemic Therapy Type',
                    timepoint: 'Timepoint',
                    txType: 'Tx Type',
                    stage: 'Baseline Stage / TNM'
                },
                data: {
                    dataId: 'Symbol',
                    description: 'Description'
                }
            },
            error: null,
            dialog: null,
            cancel: false,
            tab: 0,
            imports: [],
            deleteImports: [],
            importDescription: ''
        }
    }

    componentDidMount() {
        this.loadImports();
    }

    loadImports() {
        const { state: { idToken } } = this.props.appContext;
        const loadImports = {
            method: 'GET',
            url: Config.baseUrl + `${Config.pathPrefix}patients/imports`,
            headers: { "Authorization": idToken }
        }
        console.log("loadImports request:", loadImports);
        this.setState({progress: <CircularProgress size={20} />, error: null})
        AxiosRequest(loadImports).then((result) => {
            console.log("loadImports result:", result.data);
            this.setState({ imports: result.data, progress: null });
        }).catch((err) => {
            this.setState({ progress: null, error: getErrorMessage(err) })
        })
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    importDescriptionFiles(files) {
        console.log("importDescriptionFiles:", files);

        for (let i = 0; i < files.length; ++i) {
            const file = files[i];
            this.readFile(file).then((result) => {
                return CSV(result);
            }).then((rows) => {
                let { descriptionFiles } = this.state;
                descriptionFiles.push({ name: file.name, rows });
                this.setState({ descriptionFiles }, i === (files.length - 1) ? this.checkMappings.bind(this) : undefined);
            }).catch((err) => {
                console.error("importDescriptionFiles error:", err);
                return this.setState({ error: getErrorMessage(err) })
            })
        }
    }

    importDataFiles(files) {
        console.log("importDataFiles:", files);

        for (let i = 0; i < files.length; ++i) {
            const file = files[i];
            this.readFile(file).then((result) => {
                return CSV(result);
            }).then((rows) => {
                let { dataFiles } = this.state;
                dataFiles.push({ name: file.name, rows });
                this.setState({ dataFiles }, i === (files.length - 1) ? this.checkMappings.bind(this) : undefined)
            }).catch((err) => {
                console.error("importDataFiles error:", err);
                return this.setState({ error: getErrorMessage(err) })
            })
        }
    }

    checkMappings() {
        const { delta, descriptionFiles, dataFiles, mappings: { descriptions: { patientId, sampleId, eventTime }, data: { dataId, description } } } = this.state;
        console.log("checkMappings:", descriptionFiles, dataFiles);

        try {
            let patients = {};
            for (let i = 0; i < descriptionFiles.length; ++i) {
                const file = descriptionFiles[i];
                for (let k = 0; k < file.rows.length; ++k) {
                    const row = file.rows[k];
                    if (!row[patientId]) {
                        throw new Error(`${patientId} column missing from file ${file.name}:${k}`);
                    }
                    if (!row[sampleId]) {
                        throw new Error(`${sampleId} column missing from file ${file.name}:${k}`);
                    }
                    const patientRow = patients[row[patientId]];
                    if (patientRow === undefined) {
                        patients[row[patientId]] = { patientId: row[patientId], samples: [row] };
                    } else {
                        let sampleIndex = patientRow.samples.findIndex((v) => v[sampleId] === row[sampleId]);
                        if (sampleIndex >= 0) {
                            patientRow.samples[sampleIndex] = { ...patientRow.samples[sampleIndex], ...row };       // merge the two rows
                        } else {
                            patientRow.samples.push(row);     // new sample ID, so add a new sample
                        }
                    }
                }
            }
            let patientRows = Object.keys(patients).map((k) => patients[k]);
            console.log("patientRows:", patientRows);

            let missingEventTimes = patientRows.reduce((p, c) => {
                for (let k = 0; k < c.samples.length; ++k) {
                    if (!c.samples[k][eventTime]) {
                        console.log("eventTime missing from sample:", c.samples[k]);
                        p += 1;
                    }
                }
                return p;
            }, 0)
            console.log("missingEventTimes:", missingEventTimes);

            let data = {};
            for (let i = 0; i < dataFiles.length; ++i) {
                const file = dataFiles[i];
                for (let k = 0; k < file.rows.length; ++k) {
                    const row = file.rows[k];
                    if (!row[dataId]) {
                        throw new Error(`${dataId} column missing from file ${file.name}:${k}`);
                    }
                    if (!row[description]) {
                        throw new Error(`${description} column missing from file ${file.name}:${k}`);
                    }

                    const dataRow = data[row[dataId]];
                    if (dataRow === undefined) {
                        data[row[dataId]] = { dataId: row[dataId], description: row[description], rows: [row] }
                    } else {
                        dataRow.rows.push(row);
                    }
                }
            }

            let dataRows = Object.keys(data).map((k) => {
                const d = data[k];
                if (d.rows.length > 1) {
                    // when we encounter more than one row, we need to average all the values together to produce a single row..
                    const averageRow = { ...d.rows[0] };
                    //console.log("averageRow:", averageRow);
                    for (let i = 1; i < d.rows.length; ++i) {
                        const addRow = d.rows[k];
                        for (let k in addRow) {
                            if (!Number.isNaN(addRow[k])) {
                                //console.log(`adding ${k}:`, addRow[k])
                                averageRow[k] += addRow[k];
                            }
                        }
                    }
                    for (let k in averageRow) {
                        if (!Number.isNaN(Number(averageRow[k]))) {
                            //console.log(`averaging ${k}`, Number.isNaN(averageRow[k]), averageRow[k] );
                            averageRow[k] /= d.rows.length;
                        }
                    }

                    // ok, lastly replace the rows with the averaged value...
                    //console.log(`averageRow:`, averageRow, d.rows);
                    d.rows = [averageRow];
                }
                return d;
            });
            console.log("dataRows:", dataRows);

            const uniqueDataPoints = patientRows.reduce((p, c) => {
                let dataPoints = c.samples.reduce((p, c) => {
                    const patientSampleId = c[sampleId];
                    if (patientSampleId && c[eventTime]) {
                        const dataCount = dataRows.reduce((p, c) => {
                            return p + c.rows.filter((e) => e[patientSampleId]).length;
                        }, 0);
                        if ( delta ) {
                            if ( dataCount > 1) {
                                p += dataCount - 1;
                            }
                        } else {
                            p += dataCount;
                        }
                    }
                    return p;
                }, 0);
                p += dataPoints;
                return p;
            }, 0)

            this.setState({ patientRows, dataRows, uniqueDataPoints, error: null });
        } catch (err) {
            console.error("checkMappings error:", err);
            this.setState({ error: getErrorMessage(err) });
        }
    }

    importData() {
        const { importDescription } = this.state;

        this.setState({
            dialog: <Dialog open={true}>
                <DialogTitle>Confirm Import</DialogTitle>
                <DialogContent>Please confirm the import of this proteomics data?
                    <br /><br />
                    <TextField style={{width: 300, margin: 5}} value={importDescription} label='Import Description' onChange={(e) => {
                        this.setState({ importDescription: e.target.value }, this.importData.bind(this));
                    }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ dialog: null })}>Cancel</Button>
                    <Button onClick={this.importDataConfirmed.bind(this)}>Confirm</Button>
                </DialogActions>
            </Dialog>
        });
    }

    updateProgress(status, i, count) {
        console.log("updateProgress:", status, i, count);
        this.setState({
            dialog: <Dialog open={true}>
                <DialogContent>
                    {status}<br />
                    <LinearProgress variant='determinate' value={(i / count) * 100} style={{ width: 500 }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ dialog: null, cancel: true })}>Cancel</Button>
                </DialogActions>
            </Dialog>
        });
    }

    importDataConfirmed() {
        const { appContext, appContext: { stores: { DataTypesStore }, state: { plan, idToken } } } = this.props;
        const { patientRows, dataRows,
            mappings: {
                descriptions: { sampleId, eventTime, dob, gender, response, responseType, therapyName, therapyType, timepoint, txType, stage }
            },
            importDescription,
            delta
        } = this.state;

        console.log("importDataConfirmed:", patientRows, dataRows);
        this.setState({dialog: null});

        const dataTypesMap = DataTypesStore.getDataTypes().reduce((p, c) => {
            p[c.dataId] = c;
            return p;
        }, {});

        const saveImport = {
            method: 'POST',
            url: Config.baseUrl + `${Config.pathPrefix}patients/imports`,
            headers: { "Authorization": idToken },
            data: {
                description: importDescription
            }
        }
        console.log("saveImport request:", saveImport);
        AxiosRequest(saveImport).then((result) => {
            console.log("saveImport result:", result.data);
            const { importId } = result.data;

            // firstly, make any needed data types..
            let promises = [];
            for (let i = 0; i < dataRows.length; ++i) {
                const dataRow = dataRows[i];
                const dataType = dataTypesMap[dataRow.dataId]; //DataTypesStore.getDataTypes().find((e) => e.dataId === dataRow.dataId);
                if (!dataType) {
                    promises.push(() => new Promise((resolve, reject) => {
                        if (this.state.cancel) {
                            return reject(new Error("Import cancelled..."))
                        }
                        this.updateProgress("Adding data types...", i, dataRows.length);

                        DataTypesStore.saveDataType({
                            dataId: dataRow.dataId,
                            name: dataRow.description,
                            description: dataRow.description,
                            category: 'proteomicsData',
                            baseline: false,
                            tupleDescriptions: [
                                {
                                    classType: 'LabNormalized',
                                    dataType: 'Number',
                                    origin: 'LAB',
                                    description: dataRow.description,
                                    index: 0
                                }
                            ]
                        }, plan.planId).then(resolve).catch(reject);
                    }));
                } else {
                    if (dataType.category !== 'proteomicsData') {
                        return this.setState({ dialog: null, error: `Data type conflict found for '${dataRow.dataId}` });
                    }
                }
            }

            for (let i = 0; i < patientRows.length; ++i) {
                promises.push(() => new Promise((resolve, reject) => {
                    if (this.state.cancel) {
                        return reject(new Error("Import cancelled..."))
                    }
                    this.updateProgress("Importing Patients...", i, patientRows.length);

                    const patientRow = patientRows[i];
                    console.log("patientRow:", patientRow);

                    function getSampleValue(prop) {
                        let values = patientRow.samples.map((e) => e[prop]).filter((e) => e);
                        if (values.length > 0) {
                            return values[0];
                        }
                        return '';
                    }

                    // create a new patient record...
                    addPatient(appContext, {
                        firstName: 'ProteomicsImport',
                        lastName: patientRow.patientId,
                        plans: [{ planId: plan.planId }],
                        exportFlag: true,
                        dob: getSampleValue(dob),
                        gender: getSampleValue(gender),
                        importId
                    }).then((patient) => {
                        const dataPoints = [];
                        // now add all the data points for this patient
                        for (let k = 0; k < patientRow.samples.length; ++k) {
                            const sample = patientRow.samples[k];
                            const et = sample[eventTime];
                            if (!et) {
                                console.warn("No event time:", sample);
                                continue;        // skip if we have no valid event time for this sample
                            }
                            const sid = sample[sampleId];
                            if (!sid) {
                                console.warn("No sample ID:", sample);
                                continue;       // skip if no sampleId
                            }

                            const source = [
                                { sampleId: sid }
                            ];
                            dataPoints.push({
                                patientId: patient.patientId,
                                baseline: false,
                                planId: plan.planId,
                                dataId: 'proteomicsSample',
                                eventTime: Moment(et).toDate(),
                                status: 'final',
                                data: [
                                    sample[response],
                                    sample[responseType],
                                    sample[therapyName],
                                    sample[therapyType],
                                    sample[timepoint],
                                    sample[txType],
                                    sample[stage],
                                    sid
                                ],
                                source
                            });

                            for (let j = 0; j < dataRows.length; ++j) {
                                const dataRow = dataRows[j];
                                if (dataRow.rows.length < 1) {
                                    console.warn(`dataRow has no rows:`, dataRow);
                                    continue;
                                }
                                let value = Number(dataRow.rows[0][sid]);
                                if (value === undefined || Number.isNaN(value)) {
                                    console.warn(`Sample ID ${sid} not found in row:`, dataRow);
                                    continue;
                                }

                                dataPoints.push({
                                    patientId: patient.patientId,
                                    baseline: false,
                                    planId: plan.planId,
                                    dataId: dataRow.dataId,
                                    eventTime: Moment(et).toDate(),
                                    status: 'final',
                                    data: [
                                        value
                                    ],
                                    source
                                })
                            }
                        }

                        // we need to save the delta's, instead of the absolute values
                        if (delta) {
                            const dataIds = dataPoints.reduce((p,c) => {
                                if (c.dataId !== 'proteomicsSample' && p.indexOf(c.dataId) < 0) {
                                    p.push(c.dataId)
                                }
                                return p;
                            }, [] )
                            console.log("dataIds:", dataIds );
                            for(let k=0;k<dataIds.length;++k) {
                                const dataId = dataIds[k];

                                let prevValue = undefined;
                                let prevSampleId = undefined;
                                for(let j=0;j<dataPoints.length;++j) {
                                    const dp = dataPoints[j];
                                    if ( dp.dataId !== dataId ) continue;

                                    // remove the first data point, since we can't calcualte a delta in this case
                                    if ( prevValue === undefined ) {
                                        prevValue = dp.data[0];             // grab the value before we delete this data point
                                        prevSampleId = (dp.source.find((e) => e.sampleId) || { sampleId: ''}).sampleId;
                                        dataPoints.splice(j--, 1);   
                                    } else {
                                        const value = dp.data[0];
                                        const sampleId = (dp.source.find((e) => e.sampleId) || { sampleId: ''}).sampleId;
                                        dp.data[0] = value - prevValue;     // save a delta instead of the absolute value
                                        dp.source = [ ...dp.source, {
                                            prevSampleId,
                                            prevValue,
                                            value
                                        } ];
                                        prevValue = value;
                                        prevSampleId = sampleId;
                                    }
                                }
                            }
                        }

                        // save the data in chunks, so we don't timeout trying to send 15k data points..
                        let dataPromises = [];
                        for (let i = 0; i < dataPoints.length; i += 100) {
                            const chunk = dataPoints.slice(i, i + 100);
                            dataPromises.push(() => {
                                if (this.state.cancel) {
                                    return Promise.reject(new Error("Import cancelled..."))
                                }
                                return savePatientData(appContext, patient.patientId, chunk)
                            });
                        }
                        return Promise.map(dataPromises, (promise) => {
                            return promise();
                        }, { concurrency: 20 });
                    }).then(resolve).catch((err) => {
                        console.error("importData error:", err);
                        reject(err);
                    })
                }));
            } // end for patientRows

            this.setState({ error: null, dialog: null, cancel: false });
            return Sequential(promises).then(() => {
                console.log("importDataConfirmed done.");
                this.setState({ dialog: null });
            }).catch((err) => {
                this.setState({ error: getErrorMessage(err), dialog: null })
            })
        })
    }

    onDeleteImport(imp) {
        console.log("onDeleteImport:", imp);
        const { importId } = imp;
        const { deleteImports } = this.state;

        deleteImports.push(importId);
        this.setState({
            dialog: <Dialog open={true}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>Please confirm the delete of imports {deleteImports.join(',')}?</DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.setState({ dialog: null, deleteImports: [] })
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        console.log("deleteImports:", deleteImports);
                        this.setState({dialog: null, progress: <CircularProgress size={20} />, error: null })
                        Promise.map(deleteImports, (importId) => {
                            const { state: { idToken } } = this.props.appContext;
                            const deleteImport = {
                                method: 'DELETE',
                                url: Config.baseUrl + `${Config.pathPrefix}patients/imports/${importId}`,
                                headers: { "Authorization": idToken }
                            }
                            console.log("deleteImport request:", deleteImport);
                            return AxiosRequest(deleteImport).then((result) => {
                                console.log("deleteImport result:", result.data);
                            })
                        }, { concurrency: 1 }).then(() => {
                            this.setState({ dialog: null }, this.loadImports.bind(this))
                        }).catch((err) => {
                            this.setState({progress: null, error: getErrorMessage(err)})
                        })
                    }}>Confirm</Button>
                </DialogActions>
            </Dialog>
        })
    }

    render() {
        const { error, mappings,
            mappings: {
                descriptions: { patientId, sampleId, eventTime, dob, gender, response, responseType, therapyName, therapyType, timepoint, txType, stage },
                data: { dataId, description }
            },
            descriptionFiles, 
            patientRows,
            dataFiles, 
            dataRows,
            uniqueDataPoints,
            dialog,
            tab,
            imports,
            progress,
            delta
        } = this.state;

        const importsColumns = [
            { id: 'importId', label: 'Import ID' },
            { id: 'description', label: 'Description' },
            { id: 'createdAt', label: 'Created' },
            { id: 'userId', label: 'User', formatValue: (v) => <User appContext={this.props.appContext} userId={v} /> }
        ];

        return <div><Tabs value={tab} onChange={(e, tab) => this.setState({ tab })}>
            <Tab label='New Import' value={0} />
            <Tab label='Import History' value={1} />
        </Tabs>
            {tab === 0 && <Paper style={{ margin: 5, padding: 10, width: '98%' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }} >
                    <div style={{ width: '50%', margin: 10 }}>
                        <TextField label='Sample ID' value={sampleId} style={{ width: 250, margin: 5 }} helperText='Column name that will contain the sample ID used to cross reference into the data files.' onChange={(e) => {
                            mappings.descriptions.sampleId = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='Event Time' value={eventTime} style={{ width: 250, margin: 5 }} helperText='Column name that will contain the date a sample was collected.' onChange={(e) => {
                            mappings.descriptions.eventTime = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='Patient ID' value={patientId} style={{ width: 250, margin: 5 }} helperText='Column name that contains a unique ID per patient' onChange={(e) => {
                            mappings.descriptions.patientId = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='DOB' value={dob} style={{ width: 250, margin: 5 }} helperText='Column name for date of birth.' onChange={(e) => {
                            mappings.descriptions.dob = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='Gender' value={gender} style={{ width: 250, margin: 5 }} helperText='Column name for gender.' onChange={(e) => {
                            mappings.descriptions.gender = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='Response' value={response} style={{ width: 250, margin: 5 }} helperText='Column name for response.' onChange={(e) => {
                            mappings.descriptions.response = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='Response Type' value={responseType} style={{ width: 250, margin: 5 }} helperText='Column name for response type.' onChange={(e) => {
                            mappings.descriptions.responseType = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='Therapy Name' value={therapyName} style={{ width: 250, margin: 5 }} helperText='Column name for therapy name.' onChange={(e) => {
                            mappings.descriptions.therapyName = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='Therapy Type' value={therapyType} style={{ width: 250, margin: 5 }} helperText='Column name for therapy type.' onChange={(e) => {
                            mappings.descriptions.therapyType = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='Timepoint' value={timepoint} style={{ width: 250, margin: 5 }} helperText='Column name for timepoint.' onChange={(e) => {
                            mappings.descriptions.timepoint = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='TX Type' value={txType} style={{ width: 250, margin: 5 }} helperText='Column name for TX type.' onChange={(e) => {
                            mappings.descriptions.txType = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='Stage' value={stage} style={{ width: 250, margin: 5 }} helperText='Column name for stage.' onChange={(e) => {
                            mappings.descriptions.stage = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <br /><br />
                        <ReactFileReader fileTypes={[".csv"]} multipleFiles={true} handleFiles={this.importDescriptionFiles.bind(this)} >
                            <Button variant='contained'>Upload Description Files</Button>
                        </ReactFileReader><br />
                        <i>Select one more more CSV files containing descriptions. Each row should be one patient event.</i>
                        <br /><br />
                        {descriptionFiles.map((file, i) => {
                            return <div key={i}>File: <b>{file.name}</b>, Rows: <b>{file.rows.length}</b></div>;
                        })}
                        <br />
                        <table><tbody>
                            <tr><td>Unique Patients:</td><td><b>{patientRows.length}</b></td></tr>
                            <tr><td>Total Rows:</td><td><b>{descriptionFiles.reduce((p, c) => p + c.rows.length, 0)}</b></td></tr>
                            <tr><td>Merged Rows:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.length, 0)}</b></td></tr>
                            <tr><td><br />Rows with values:</td></tr>
                            <tr><td>Sample ID:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[sampleId]).length, 0)}</b></td></tr>
                            <tr><td>Event Time:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[eventTime]).length, 0)}</b></td></tr>
                            <tr><td>DOB:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[dob]).length, 0)}</b></td></tr>
                            <tr><td>Gender:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[gender]).length, 0)}</b></td></tr>
                            <tr><td>Response:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[response]).length, 0)}</b></td></tr>
                            <tr><td>Response Type:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[responseType]).length, 0)}</b></td></tr>
                            <tr><td>Therapy Name:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[therapyName]).length, 0)}</b></td></tr>
                            <tr><td>Therapy Type:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[therapyType]).length, 0)}</b></td></tr>
                            <tr><td>Timepoint:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[timepoint]).length, 0)}</b></td></tr>
                            <tr><td>TX Type:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[txType]).length, 0)}</b></td></tr>
                            <tr><td>Stage:</td><td><b>{patientRows.reduce((p, c) => p + c.samples.filter((r) => r[stage]).length, 0)}</b></td></tr>
                        </tbody></table>
                    </div>
                    <div style={{ width: '50%', margin: 10 }}>
                        <TextField label='Data ID' value={dataId} style={{ width: 250, margin: 5 }} helperText='Column name for the unique data ID.' onChange={(e) => {
                            mappings.data.dataId = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <TextField label='Description' value={description} style={{ width: 250, margin: 5 }} helperText='Column name containing the data type description.' onChange={(e) => {
                            mappings.data.description = e.target.value;
                            this.setState({ mappings }, this.checkMappings.bind(this))
                        }} />
                        <br />
                        <FormControlLabel label='Delta Import'
                            control={<Checkbox checked={delta} onChange={(e,v) => this.setState({ delta: v }, this.checkMappings.bind(this))} />} /><br />
                        <i>If checked, then the delta between the data points will be saved instead of the absolute values. The first data point for each patient will be skipped, since two are required to calculate a delta.</i>
                        <br />
                        <br />
                        <ReactFileReader fileTypes={[".csv"]} multipleFiles={true} handleFiles={this.importDataFiles.bind(this)} >
                            <Button variant='contained'>Upload Data Files</Button>
                        </ReactFileReader>
                        <br />
                        <i>Select one more more CSV files containing data. Each row should be a data type. Colmuns will be crossed referenced into the description data.</i><br />
                        <br />
                        {dataFiles.map((file, i) => {
                            return <div key={i}>File: <b>{file.name}</b>, Rows: <b>{file.rows.length}</b></div>;
                        })}
                        <br />
                        <table><tbody>
                            <tr><td>Unique Data Types:</td><td><b>{dataRows.length}</b></td></tr>
                            <tr><td>Total Rows:</td><td><b>{dataFiles.reduce((p, c) => p + c.rows.length, 0)}</b></td></tr>
                            <tr><td>Unique Data Points:</td><td><b>{uniqueDataPoints}</b></td></tr>
                            <tr><td><br />Rows with values:</td></tr>
                            <tr><td>Data ID:</td><td><b>{dataRows.reduce((p, c) => p + c.rows.filter((r) => r[dataId]).length, 0)}</b></td></tr>
                            <tr><td>Description:</td><td><b>{dataRows.reduce((p, c) => p + c.rows.filter((r) => r[description]).length, 0)}</b></td></tr>
                        </tbody></table>
                    </div>
                </div>
                <br />
                <div align='right'>
                    <span style={{ color: 'red' }}>{error}</span>
                    <br />
                    <Button variant='contained' style={{ margin: 5 }} onClick={() => this.setState({ descriptionFiles: [], dataFiles: [] }, this.checkMappings.bind(this))}>Reset Data</Button>
                    <Button variant='contained' style={{ margin: 5 }} disabled={error !== null || patientRows.length === 0 || dataRows.length === 0} onClick={this.importData.bind(this)}>Import Data</Button>
                </div>
            </Paper>}
            {tab === 1 && <Paper style={{ margin: 5, padding: 10, width: '98%' }}>
                <EnhancedTable
                    onActions={(table, numSelected, actions) => {
                        actions.push(<IconButton disabled={progress !== null} onClick={this.loadImports.bind(this)}>{progress || <RefreshIcon />}</IconButton>)
                        if ( error ) {
                            actions.unshift(<span style={{color: 'red'}}>{error}</span>)
                        }
                    }}
                    orderBy='createdAt'
                    columnData={importsColumns}
                    data={imports}
                    rowsPerPage={25}
                    title='Imports'
                    onDelete={this.onDeleteImport.bind(this)}
                />
            </Paper>}
            {dialog}
        </div>
    }
}

export default ImportView;