import React from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    FormLabel,
    Tooltip,
    IconButton
} from '@material-ui/core/';

import SortableTree from "react-sortable-tree";
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';

import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';

import OverrideDialog from "./OverrideDialog";
import SaveDependentDialog from "./SaveDependentDialog";
import EditTupleDescriptionDialog from './EditTupleDescriptionDialog';

import { SelectDataType } from '@apricityhealth/web-common-lib/components/SelectDataType';
import { SelectTupleIndex } from '@apricityhealth/web-common-lib/components/SelectTupleIndex';
import UploadFile from "@apricityhealth/web-common-lib/components/UploadFile";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import createIdFromText from "../utils/CreateIdFromText";
import ChangedByButton from "../components/ChangedByButton";

const DATA_TYPE_CATEGORIES = require('../data/DataTypeCategories.json');

export class SelectDataTypeCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: props.value
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.value !== oldProps.value)
            this.setState({ category: this.props.value });
    }

    selectCategory(e) {
        this.setState({category: e.target.value});
        if ( this.props.onChange ) {
            this.props.onChange(e);
        }
    }

    render() {
        let { category } = this.state;
        let items = [];
        for(let k in DATA_TYPE_CATEGORIES) {
            items.push(<MenuItem key={k} value={k}>{DATA_TYPE_CATEGORIES[k]}</MenuItem>);
        }
        return <FormControl style={styles.select}>
            <InputLabel>Category</InputLabel>
            <Select styles={{ margin: 0 }} value={category} onChange={this.selectCategory.bind(this)}>
                {items}
            </Select>
        </FormControl>;
    }
}

class EditDataTypeDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataType: props.dataType ? JSON.parse( JSON.stringify( props.dataType )) 
                : { dataId: '', name: '', description: '', category: '', tupleDescriptions: [] },
            progress: null,
            dialog: null,
            saveDependent: false
        };
    }

    componentDidMount() {
        this.createTreeData();
    }

    componentDidUpdate(oldProps) {
        //note - props never seems to be changed in the current interface
        const oldData = JSON.stringify(oldProps.dataType)
        const currentData = JSON.stringify(this.props.dataType)
        if (oldData !== currentData){
            this.setState({ dataType: JSON.parse( JSON.stringify( this.props.dataType ))}, this.createTreeData.bind(this));
        }
    }

    displayOverrideDialog( oldPlanId, callback) {
        this.setState({ dialog: <OverrideDialog appContext={this.props.appContext} 
            oldPlanId={oldPlanId} parent={this} onConfirm={callback} /> });        
    }

    displaySaveDependentDialog( callback ) {
        const self = this;
        this.setState({ dialog: <SaveDependentDialog appContext={this.props.appContext} 
            onCancel={() => {
                self.setState({saveDependent: false, dialog: null});
            }}
            onConfirm={(planId) => {
                self.setState({saveDependent: false, dialog: null}, self.onDone.bind(self, planId) );
            }} /> });
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel)
            onCancel();
    }

    onDone(planId = null) {
        const self = this;
        const { onDone, appContext } = this.props;
        const { dataType, saveDependent } = this.state;

        if ( !planId && dataType.planId && dataType.planId !== appContext.state.plan.planId ) {
            return this.displayOverrideDialog( dataType.planId, this.onDone.bind(this) );
        }
        if ( saveDependent === true ) {
            return this.displaySaveDependentDialog( this.onDone.bind(this) );
        }

        const linkStore = appContext.stores.DataTypesStore;
        let tupleUpdates = [];
        for(let i=0;i<dataType.tupleDescriptions.length;++i) {
            let tupleDesc = dataType.tupleDescriptions[i];
            if ( tupleDesc.oldIndex !== tupleDesc.index ) {
                tupleUpdates.push({oldIndex: tupleDesc.oldIndex, newIndex: tupleDesc.index});
            }
        }

        if ( tupleUpdates.length > 0 ) {
            try { 
                linkStore.updateTupleIndexes(dataType.dataId, tupleUpdates);
            }
            catch(err) {
                console.error("updateTupleIndexs error:", err );
                return self.setState({ error: getErrorMessage(err) });                
            }
        }

        const store = appContext.stores.DataTypesStore;
        self.setState({ progress: <CircularProgress size={20} />, error: null });
        store.saveDataType(dataType, planId).then(() => {
            if (onDone)
                onDone(dataType);
            self.setState({ progress: null });
        }).catch((error) => {
            self.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    onChange = type => e => {
        let { dataType } = this.state;
        dataType[type] = e.target.value;
        this.setState({ dataType });
    }

    onChange = type => e => {
        let { dataType } = this.state;
        dataType[type] = e.target.value;
        if (type === 'name'){
            dataType.dataId = createIdFromText(dataType.name) 
        }
        this.setState({ dataType });
    }

    showConfirmUpdateDialog(){
        let self = this;
        let dialog = <Dialog
            maxWidth={'md'}
            fullWidth={true}
            model="false"
            open={true}
        >
            <DialogContent>
                Are you sure you want to overwrite the current <b> tuple descriptions</b>?
            </DialogContent>
            <DialogActions>
                <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.updateTupleDescriptions() }}>Update</Button>
                <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onCancelUpdateTuple() }}>Cancel</Button>
            </DialogActions>
        </Dialog> 
        this.setState({dialog})
    }

    onCancelUpdateTuple(){
        this.setState({dialog:null})
    }

    confirmUpdateTupleDescriptions(){
        let { dataType } = this.state;
        if (dataType.tupleDescriptions.length > 0){
            this.showConfirmUpdateDialog()
        }else{
            this.updateTupleDescriptions() 
        }
    } 

    editTuple(node) {
        let self = this;
        let { appContext } = this.props;
        self.setState({
            dialog: <EditTupleDescriptionDialog
                disableSave={true}
                appContext={appContext}
                tupleDescription={node.tupleDescription}
                tupleIndex={node.tupleIndex}
                dataType={node.dataType}
                onCancel={() => {
                    self.setState({ dialog: null });
                }}
                onDone={(dataType) => {
                    console.log("editTuple done:", dataType );
                    self.setState({ dataType, dialog: null }, self.createTreeData.bind(self) );
                }} />
        });
    }

    addTuple() {
        let { dataType } = this.state;

        let newTupleDescription = {
            description: 'New Tuple',
            dataType: 'String',
            classType: 'Answer',
            origin: 'PGHD',
            unit: '',
            valueDescriptions: [],
            index: dataType.tupleDescriptions.length
        }

        dataType.tupleDescriptions.push( newTupleDescription );
        this.setState({dataType}, this.createTreeData.bind(this));
    }


    deleteTupleDescription(node) {
        let { dataType } = this.state;
        dataType.tupleDescriptions.splice( node.tupleDescription.index, 1);
        for(let i=0;i<dataType.tupleDescriptions.length;++i)
            dataType.tupleDescriptions[i].index = i;
        
        this.setState({dataType}, this.createTreeData.bind(this));
    }

    updateTupleDescriptions = e => {
        let tupleDescriptions =  require('../data/DataTypeTupleDescriptionTemplates.json'); 
        let { dataType  } = this.state;

        let tupleDescriptionItem = tupleDescriptions.tupleDescriptionItems.find( (tupleDescriptionItem)=> {
            return (tupleDescriptionItem.id === dataType.category)
        })
       
        if ( tupleDescriptionItem ) {
            tupleDescriptionItem = JSON.stringify(tupleDescriptionItem)
            tupleDescriptionItem = tupleDescriptionItem.replace(/{{name}}/g, dataType.name)
            tupleDescriptionItem = JSON.parse(tupleDescriptionItem)

            dataType.tupleDescriptions = tupleDescriptionItem.tupleDescriptions
            this.setState({ dataType, dialog:null }, this.createTreeData.bind(this));
        }
    }

    createTreeData() {
        let { dataType } = this.state;
        let tupleDescriptions = dataType.tupleDescriptions;
        let treeData = [];
        for (let j = 0; j < tupleDescriptions.length; j++) {
            let tupleDescription = tupleDescriptions[j];
            if ( tupleDescription.oldIndex === undefined )
                tupleDescription.oldIndex = tupleDescription.index;     // save off the original index, so we can detect if tuples have been changed
            let description = tupleDescription.description;
            let nodeId = dataType.dataId + tupleDescription.index;

            let node = {
                isTupleDescription: true,
                dataType,
                nodeId,
                tupleDescription,
                tupleIndex: j,
                text: description
            };

            treeData.push(node);
        }

        console.log("treeData:", treeData);
        this.setState({treeData});
    }

    getNodeButtons(node) {
        const { readOnly } = this.props;

        let buttons = [];
        buttons.push(<FormLabel style={styles.index_label}>{node.tupleDescription.index}</FormLabel>);
        if (!readOnly) {
            buttons.push(<Tooltip title='Edit'><IconButton onClick={this.editTuple.bind(this, node)}><CreateIcon /></IconButton></Tooltip>);
            buttons.push(<Tooltip title='Delete'><IconButton onClick={this.deleteTupleDescription.bind(this, node)}>
                <DeleteIcon /></IconButton></Tooltip>);
        }

        return buttons;
    }
    
    getNodeProps({ node }) {
        return {
            listIndex: 0,
            lowerSiblingCounts: [],
            style: styles.tree_node,
            title: node.text,
            buttons: this.getNodeButtons(node)
        };
    }

    onChangeTree( treeData ) {
        let { dataType } = this.state;
        let tupleDescriptions = [];
        for(let i=0;i<treeData.length;++i) {
            let node = treeData[i];
            tupleDescriptions.push( node.tupleDescription );
            tupleDescriptions[i].index = i;
        }
        dataType.tupleDescriptions = tupleDescriptions;
        this.setState({dataType, treeData});
    }

    getTupleTree() {
        const { treeData } = this.state;
        let sortableTree = treeData ? <div style={styles.tree} valign='top'>
            <SortableTree
                treeData={treeData}
                maxDepth={3}
                canDrop={() => true}
                onChange={this.onChangeTree.bind(this)}
                theme={FileExplorerTheme}
                canNodeHaveChildren={() => false}
                isVirtualized={true}
                rowdirection='ltr'
                dndType='symptom'
                generateNodeProps={this.getNodeProps.bind(this)} /></div> : null;
        console.log("getTupleTree:", sortableTree);
        return sortableTree;
    }

    render() {
        const self = this;
        const {appContext} = this.props
        let { dataType, dialog, progress, error, saveDependent } = this.state;

        if ( dataType.timeWindow === undefined)
            dataType.timeWindow = 15;
        if ( dataType.historyWindow === undefined )
            dataType.historyWindow = 90;
        if ( dataType.parentId === undefined )
            dataType.parentId = '';
        if ( dataType.referenceUrl === undefined )
            dataType.referenceUrl = '';
        if ( dataType.referenceTitle === undefined )
            dataType.referenceTitle = '';

        return (
            <Dialog maxWidth={'lg'} fullWidth={true} model="false" open={true}>
                <DialogContent>
                    <TextField disabled={true} style={styles.name} label="Data Id" value={dataType.dataId} onChange={self.onChange("dataId")} />
                    <TextField style={styles.name} label="Name" value={dataType.name} onChange={self.onChange("name")} />
                    <Tooltip title="Enter 0 days for unlimited days"><TextField style={styles.number} label="Time Window" value={dataType.timeWindow} type='numeric' onChange={self.onChange("timeWindow")} /></Tooltip>
                    <Tooltip title="Enter 0 days for unlimited days"><TextField style={styles.number} label="History Window" value={dataType.historyWindow} type='numeric' onChange={self.onChange("historyWindow")} /></Tooltip>
                    <br />
                    <TextField style={styles.description} label="Description" value={dataType.description} onChange={self.onChange("description")} />
                    <br />
                    <TextField style={styles.description} label="Reference Title" value={dataType.referenceTitle} onChange={self.onChange("referenceTitle")} />
                    <TextField style={styles.description} label="Reference URL" value={dataType.referenceUrl} onChange={self.onChange("referenceUrl")} />
                    <UploadFile appContext={appContext} folderPath='ref/' onChange={(e) => {
                        dataType.referenceUrl = e; self.setState({ dataType });
                    }} />
                    <br />
                    <SelectDataTypeCategory value={dataType.category} onChange={self.onChange("category")} />
                    <Button 
                        onClick = {self.confirmUpdateTupleDescriptions.bind(this)}
                        style={{marginTop:16}} variant="contained">Create Tuples</Button>
                    <br />
                    <SelectDataType label="Parent Data Id" style={styles.select} appContext={this.props.appContext} dataId={dataType.parentId} enableNone={true}
                        onSelect={(select) => {
                            dataType.parentId = select ? select.dataId : '';
                            self.setState( {dataType } );
                    }} />
                    <SelectDataType label="Date Data Id" style={styles.select} appContext={this.props.appContext} dataId={dataType.dateId} enableNone={true}
                        onChange={(select) => {
                            dataType.dateId = select ? select.dataId : '';
                            self.setState( {dataType } );
                    }} />
                    {dataType.dateId ? <SelectTupleIndex label='Select Date Tuple' style={styles.select} appContext={this.props.appContext} dataId={dataType.dateId} tupleIndex={dataType.dateTupleIndex}
                        onChange={(tupleIndex) => {
                            dataType.dateTupleIndex = tupleIndex;
                            self.setState( { dataType} );
                        }} /> : null}
                    <br />
                    <FormControlLabel style={{ marginLeft: '0px' }} control={<Checkbox
                        checked={dataType.baseline}
                        onChange={(e) => {
                            dataType.baseline = e.target.checked;
                            self.setState({ dataType });
                        }}
                    />} label="Baseline" />
                    <br/> 
                    <br/> 
                    <div align='right'>
                        <Tooltip title='Add Tuple'><IconButton onClick={this.addTuple.bind(this)}><AddIcon /></IconButton></Tooltip>
                    </div>
                    {this.getTupleTree()}
                    {dialog}
                </DialogContent>
                <DialogActions>
                    <table style={{width: '100%'}}><tbody><tr><td>
                        <ChangedByButton appContext={appContext} primaryKey={dataType.dataId} collection='DataTypeModel' />
                    </td><td align='right'>
                        {error}
                        {progress ? progress : <FormControlLabel label='Save into dependent' control={<Checkbox checked={saveDependent} onChange={(e,v) => self.setState({saveDependent: v}) } />} />}
                        <Button disabled={progress !== null} variant="contained" self={self} style={styles.button} onClick={(e) => { self.onDone() }}>Save</Button>
                        <Button variant="contained" self={self} style={styles.button} onClick={(e) => { self.onCancel() }}>Cancel</Button>
                    </td></tr></tbody></table>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = {
    select: {
        margin: 5,
        width: 300
    },
    tupleBoxHeader:{
        display:'flex',
        justifyContent:'space-between'
    },
    number: {
        margin: 5,
        width: 120
    },
    name: {
        margin: 5,
        width: 300
    },
    description: {
        margin: 5,
        width: 500
    },
    div: {
        margin: 10
    },
    button: {
        margin: 10
    },
    tree: { height: 1000, width: '100%' },
    tree_node: {
        height: 50,
        width: 500,
        border: 0,
        cursor: 'pointer'
    },
    index_label: { fontSize: 10, display: 'inline-block', color: 'purple', width: 50, textAlign: 'right', marginRight: 30, border: 'none' }
};


export default EditDataTypeDialog;
