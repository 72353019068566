import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Dialog,
    IconButton,
    TextField,
    Button,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    CircularProgress,
    Tooltip,
    Tabs,
    Tab,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';
import Moment from 'moment';

import DeleteIcon from '@material-ui/icons/Delete';
import CodeIcon from '@material-ui/icons/Code';
import SaveIcon from '@material-ui/icons/Save';
import SaveCopyIcon from '@material-ui/icons/FileCopy';
import ImageIcon from '@material-ui/icons/Image';
import LogsIcon from '@material-ui/icons/List';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import AddIcon from '@material-ui/icons/Add'
import CanceIcon from '@material-ui/icons/Cancel';
//import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import NavigationClose from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeepIcon from '@material-ui/icons/Favorite';

import Config from '@apricityhealth/web-common-lib/Config';
import Axios from '@apricityhealth/web-common-lib/utils/Axios';
import LogsView from '@apricityhealth/web-common-lib/views/LogsView';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import SelectDataType from '@apricityhealth/web-common-lib/components/SelectDataType';
import SelectTupleIndex from '@apricityhealth/web-common-lib/components/SelectTupleIndex';
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import EditConditions from '@apricityhealth/web-common-lib/components/EditConditions';

import JSONDataDialog from '../dialogs/JSONDataDialog';
import EditDataTypeDialog from '../dialogs/EditDataTypeDialog';
import createIdFromText from '../utils/CreateIdFromText';
import ChangedByButton from '../components/ChangedByButton';
import FeatureSelectionView from './FeatureSelectionView';
import TriggerSelectionView from './TriggerSelectionView';
import { DATE_FORMAT } from './clusteringView/EventChangesView';
import { loadJobResult } from '../utils/LoadJobResults';
import { getReportSeries } from '@apricityhealth/web-common-lib/utils/Services';

const FileDownload = require('js-file-download');

const PNG_IMAGE = 'data:image/png;base64,';

// Add JSON.parseMore, to allow us to parse python generated JSON data..
require('../utils/json_parseMore');

export class MLModelView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            algorithms: [],
            imports: [],
            recurring: false,
            runWhen: new Date(),
            interval: 1,
            intervalType: 'days',
            model: {
                modelId: '',
                name: '',
                description: '',
                algorithm: 'kmeans',
                opts: {},
                biomarkers: {
                    importId: '',
                    dataId: '',
                    tupleIndex: 0,
                    value: [],
                    condition: '',
                    demographics: [],
                    labs: [],
                    labs_normal: [],
                    labs_high: [],
                    labs_low: [],
                    conditions: [],
                    medications: [],
                    procedures: [],
                }
            },
            counts: {},
            tab: '0'
        };
    }

    componentDidMount() {
        if (this.props.model)
            this.loadModel(this.props.model);
        this.loadJobsTimer = setInterval(this.loadJobs.bind(this), 15 * 1000);
        this.loadImportsTimer = setInterval(this.loadImports.bind(this), 20 * 1000 );
        this.loadAlgorithms();
        this.loadImports();
    }

    componentWillUnmount() {
        clearInterval(this.loadJobsTimer);
        clearInterval(this.loadImportsTimer)
    }

    componentDidUpdate(oldProps) {
        if (this.props.model !== oldProps.model)
            this.loadModel(this.props.model);
    }

    onCloseView() {
        console.log("closeView()", this);
        this.props.onClose();
    }

    loadModel(model) {
        console.log("loadModel:", model);
        if (typeof model.biomarkers.value === 'string') {
            model.biomarkers.value = model.biomarkers.value.split(',')
        }

        this.setState({
            model,
            dialog: null
        }, this.loadJobs.bind(this));
    }

    loadAlgorithms() {
        const { appContext: { state: { idToken } } } = this.props;
        const getFeatureDetectAlgorithms = {
            url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/ml/algorithms`,
            method: 'GET',
            headers: { "Authorization": idToken }
        }
        console.log("getFeatureDetectAlgorithms request:", getFeatureDetectAlgorithms);
        Axios(getFeatureDetectAlgorithms).then(({ data: { algorithms } }) => {
            console.log("getFeatureDetectAlgorithms result:", algorithms);
            this.setState({ algorithms });
        }).catch((err) => {
            this.setState({ error: getErrorMessage(err) });
        })
    }

    loadImports() {
        const { appContext: { state: { idToken } } } = this.props;
        const getImports = {
            url: Config.baseUrl + `${Config.pathPrefix}patients/imports`,
            method: 'GET',
            headers: { "Authorization": idToken }
        }
        console.log("getImports request:", getImports);
        Axios(getImports).then(({ data }) => {
            console.log("getImports result:", data);
            this.setState({ imports: data });
        }).catch((err) => {
            this.setState({ error: getErrorMessage(err) });
        })
    }

    loadJobs() {
        const { appContext: { state: { idToken, plan: { planId } } } } = this.props;
        const { model } = this.state;
        if (model && model.modelId) {
            this.setState({ error: null });
            const getFeatureDetectJobs = {
                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${planId}/ml/job/*?modelId=${model.modelId}`,
                method: 'GET',
                headers: { "Authorization": idToken }
            }
            Axios(getFeatureDetectJobs).then(({ data: { jobs } }) => {
                this.setState({ jobs });
            }).catch((err) => {
                this.setState({ error: getErrorMessage(err) });
            })
        }
    }

    onSaveMLModel( copy = false ) {
        const { model, algorithms } = this.state;
        const { appContext: { state: { idToken, plan: { planId } } } } = this.props;

        // clean up the model.opts, so we don't keep old options from other algorithms
        const algorithmOptions = ((algorithms || []).find((e) => e.name === model.algorithm) || { opts: [] }).opts;
        for (let k in model.opts) {
            if (!algorithmOptions.find((e) => e.name === k)) {
                delete model.opts[k];       // option not found, so remove it..
            }
        }

        this.setState({ progress: <CircularProgress size={20} />, error: null });

        delete model.enableJobSchedule;
        delete model.nextJobScheduled;
        delete model.interval;
        delete model.intervalType;
        delete model._id;
        delete model.__v;
        
        if ( copy ) {
            delete model.modelId
        }
        model.biomarkers.value = model.biomarkers.value.filter((e) => e);           // remove empty strings from the array at save

        const saveMLModel = {
            url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${planId}/ml/`,
            method: 'POST',
            headers: { "Authorization": idToken },
            data: model
        };
        console.log("saveMLModel request:", saveMLModel)
        Axios(saveMLModel).then((response) => {
            console.log("saveMLModel response:", response.data);
            this.setState({ model: response.data, progress: null }, copy ? this.loadJobs.bind(this) : undefined );
        }).catch((error) => {
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    onDeleteMLModel() {
        this.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Delete model: {`${this.state.model.modelId}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to delete?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{margin: 5}} onClick={this.onCloseDialog.bind(this)}>Cancel</Button>,
                        <Button variant="contained" style={{ margin: 5 }} onClick={this.onConfirmDelete.bind(this)}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    onShowCode() {
        let { appContext } = this.props;
        let { model } = this.state;
        this.setState({
            dialog: <JSONDataDialog
                appContext={appContext}
                dataType={model}
                onDone={() => {
                    this.setState({ dialog: null });
                }}
            />
        });
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    onConfirmDelete() {
        const self = this;
        const { model } = this.state;
        const { appContext: { state: { idToken, plan: { planId } } } } = this.props;

        self.setState({ dialog: null });
        if (model && model.modelId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            const deleteFeatureDetectModel = {
                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${planId}/ml/${model.modelId}`,
                method: 'DELETE',
                headers: { "Authorization": idToken }
            };
            Axios(deleteFeatureDetectModel).then((result) => {
                console.log("deleteFeatureDetectModel result:", result.data);
                self.setState({ progress: null });
                self.onCloseView();
            }).catch((error) => {
                console.log("deleteFeatureDetectModel error:", error.response);
                self.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    onCreateJob() {
        const { appContext: { state: { idToken, plan: { planId } } } } = this.props;
        const { model, recurring, runWhen, interval, intervalType } = this.state;
        const dialog = <Dialog open={true}>
            <DialogTitle>Confirm Create Job</DialogTitle>
            <DialogContent>Please confirm you want to create a new job for model {model.modelId}?<br /><br />
            <FormControlLabel label="Recurring Job?" control={<Checkbox checked={recurring} onChange={(e,v) => {
                this.setState({recurring: !recurring}, this.onCreateJob.bind(this) );
            }} />} />
            <br />
            {recurring && <TextField style={{ width: 300, margin: 5}} value={Moment(runWhen).format('yyyy-MM-DDTHH:mm')} type='datetime-local' label='Run Job When' onChange={(e) => {
                const value = Moment(e.target.value);
                if ( value.isValid() ) {
                    this.setState({runWhen: value.toDate()}, this.onCreateJob.bind(this))
                }
            }} />}
            {recurring && <TextField style={{width: 200, margin: 5}} value={interval} type='number' label='Interval' onChange={(e) => {
                this.setState({interval: e.target.value}, this.onCreateJob.bind(this));
            }} />}
            {recurring && <FormControl style={{width: 200, margin: 5}}>
                <InputLabel>Interval Type</InputLabel>
                <Select value={intervalType} onChange={(e) => {
                    this.setState({intervalType: e.target.value}, this.onCreateJob.bind(this));
                }}>
                    <MenuItem value='hours'>Hours</MenuItem>
                    <MenuItem value='days'>Days</MenuItem>
                    <MenuItem value='weeks'>Weeks</MenuItem>
                    <MenuItem value='months'>Months</MenuItem>
                    <MenuItem value='years'>Years</MenuItem>
                </Select>
            </FormControl>}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onCloseDialog.bind(this)}>Cancel</Button>
                <Button onClick={() => {
                    this.onCloseDialog();
                    this.setState({ error: null });

                    const args = [];
                    for (let k in model.opts) {
                        if (model.opts[k]) {              // don't pass arguments that are empty
                            args.push(`${k}=${encodeURIComponent(model.opts[k])}`);
                        }
                    }

                    const createFeatureDetectJob = {
                        url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${planId}/ml/job?${args.join('&')}`,
                        method: 'POST',
                        headers: { "Authorization": idToken },
                        data: {
                            planId: model.planId,
                            modelId: model.modelId,
                            recurring,
                            runWhen,
                            interval,
                            intervalType
                        }
                    };
                    console.log("createFeatureDetectJob request:", createFeatureDetectJob);
                    Axios(createFeatureDetectJob).then(() => {
                        this.setState({ dialog: null })
                        this.loadJobs();
                    }).catch((err) => {
                        this.setState({ error: getErrorMessage(err) });
                    })
                }}>Create</Button>
            </DialogActions>
        </Dialog>;

        this.setState({ dialog });
    }

    onCancelJob(job) {
        return <Tooltip key='cancelJob' title='Cancel Job'>
            <span><IconButton disabled={job.status !== 'running' && job.status !== 'pending'} onClick={() => {
                this.setState({dialog: <Dialog open={true}>
                    <DialogTitle>Confirm Cancel</DialogTitle>
                    <DialogContent>Please confirm that you want to cancel job {job.jobId}?</DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({dialog: null})}>Cancel</Button>
                        <Button onClick={() => {
                            const { appContext: { state: { idToken } } } = this.props;

                            const cancelJob = {
                                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${job.planId}/ml/job/${job.jobId}/cancel`,
                                method: 'DELETE',
                                headers: { "Authorization": idToken }
                            };
                            console.log("cancelJob request:", cancelJob);
                            Axios(cancelJob).then((result) => {
                                console.log("cancelJob result:", result.data);
                                this.setState({ dialog: null, error: null })
                                this.loadJobs();
                            }).catch((err) => {
                                console.error("cancelJob error:", err );
                                this.setState({ error: getErrorMessage(err) });
                            })
                        }}>Confirm</Button>
                    </DialogActions>
                </Dialog>})
            }}><CanceIcon /></IconButton></span>
        </Tooltip>;
    }

    onDeleteJob(job,table) {
        return <Tooltip key='deleteJob' title='Delete Job'>
            <span><IconButton disabled={job.status === 'pending' || job.status === 'running'} onClick={() => {
                const { appContext: { state: { idToken } } } = this.props;
                this.setState({dialog: <Dialog open={true}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>Please confirm you want to delete job {job.jobId}?</DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({dialog: null})}>Cancel</Button>
                        <Button onClick={() => {
                            const deleteJob = {
                                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${job.planId}/ml/job/${job.jobId}`,
                                method: 'DELETE',
                                headers: { "Authorization": idToken }
                            };
                            console.log("deleteJob request:", deleteJob);
                            this.setState({dialog: null});
                            Axios(deleteJob).then((result) => {
                                console.log("deleteJob result:", result.data);
                                table.setState({ selected: [] });
                                this.setState({ dialog: null, error: null })
                                this.loadJobs();
                            }).catch((err) => {
                                console.error("deleteJob error:", err );
                                this.setState({ error: getErrorMessage(err) });
                            })
                        }}>Confirm</Button>
                    </DialogActions>
                </Dialog>});
            }}><DeleteIcon /></IconButton></span>
        </Tooltip>;
    }

    onJobDetails(job) {
        return <Tooltip key='viewDetails' title='View Details'><IconButton onClick={() => {
            this.setState({
                dialog: <JSONDataDialog
                    appContext={this.props.appContext}
                    dataType={job}
                    onDone={() => {
                        this.setState({ dialog: null });
                    }}
                />
            });
        }}><CodeIcon /></IconButton></Tooltip>;
    }

    onToggleKeep(job) {
        return <Tooltip key='toggleKeep' title='Toggle Keep Status'><IconButton onClick={() => {
            job.keep = !job.keep;

            const { appContext: { state: { idToken } } } = this.props;
            const updateJob = {
                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${job.planId}/ml/job/${job.jobId}`,
                method: 'PUT',
                data: { keep: job.keep },
                headers: { "Authorization": idToken }
            };
            console.log("updateJob request:", updateJob);
            Axios(updateJob).then((result) => {
                console.log("updateJob result:", result.data);
            }).catch((err) => {
                this.setState({ error: getErrorMessage(err) });
            })

            this.setState({error: null, job});
        }}><KeepIcon /></IconButton></Tooltip>;
    }

    onViewImage(res) {
        this.setState({
            dialog: <Dialog open={true} maxWidth='xl'>
                <DialogContent>
                    <img src={res} alt='Report Results' />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ dialog: null })}>Close</Button>
                </DialogActions>
            </Dialog>
        })
    }

    onJobResult(job) {
        let buttons = [];
        if (job.result && job.result.startsWith("data:image/png;base64,")) {
            buttons.push(<Tooltip key='viewImage' title='View Image'>
                <IconButton onClick={() => {
                    this.onViewImage(job.result);
                }}><ImageIcon /></IconButton>
            </Tooltip>);
        }

        buttons.push(<Tooltip key='jobResult' title='Download Result'><span><IconButton disabled={!job.result} onClick={() => {
            function downloadResult(res) {
                try {
                    if (res.startsWith(PNG_IMAGE)) {
                        FileDownload(Buffer.from(res.substring(PNG_IMAGE.length), 'base64'), `job-${job.jobId}-result.png`);
                    } else {
                        const formattedJson = JSON.stringify(JSON.parseMore(res), null, 4);
                        FileDownload(formattedJson, `job-${job.jobId}-result.json`);
                    }
                } catch( err) {
                    console.warn("OnJobResult not json!", err);
                    FileDownload(res, `job-${job.jobId}-result.txt`);
                }
            }

            loadJobResult(this.props.appContext, job).then(() => {
                downloadResult(job.result);
            }).catch((err) => {
                console.error("loadJobResult error:", err );
            })
        }}><DownloadIcon /></IconButton></span></Tooltip>);

        return buttons
    }

    onJobLogs(job) {
        let { appContext } = this.props;
        return <Tooltip key='jobLogs' title='View Logs'><IconButton onClick={() => {
            this.setState({
                dialog: <LogsView width={1200} appContext={appContext} requestId={job.requestId} onDone={() => this.setState({ dialog: null })} />
            });
        }}><LogsIcon /></IconButton></Tooltip>;
    }

    onUpdateCounts() {
        clearTimeout( this.updateCountsTimer );     // kill any previous timer
        this.updateCountsTimer = setTimeout( () => {
            const { model, model: { biomarkers } } = this.state;
            
            if ( this.requestId === undefined ) this.requestId = 0;
            const requestId = ++this.requestId;
            getReportSeries(this.props.appContext,"BiomarkerData", {
                ...biomarkers,
                getCounts: true,
                planId: model.planId
            } ).then((result) => {  
                if ( requestId === this.requestId ) {
                    console.log("onUpdateCounts result:", result );
                    this.setState({counts: result.data.results });
                }
            }).catch((err) => {
                console.error("onUpdateCounts error:", err );
            })
        }, 250 );
    }

    render() {
        const { appContext } = this.props;
        const { tab, model, jobs, progress, error, dialog, algorithms, imports, counts } = this.state;

        if (!model || algorithms.length === 0) {
            return null;
        }
        if (!model.biomarkers) {
            model.biomarkers = { 
                exported: true, reduce: true, importId: '', dataId: '', tupleIndex: 0, demographics: [], 
                symptoms: [], labs: [], labs_normal: [], labs_high: [], labs_low: [], 
                conditions: [], medications: [], procedures: [] };
        }
        if (model.opts === undefined) model.opts = {};
        const { biomarkers } = model;
        if (biomarkers.exported === undefined) biomarkers.exported = true;
        if (biomarkers.reduce === undefined) biomarkers.reduce = true;
        if (biomarkers.enableExclusive === undefined) biomarkers.enableExclusive = false;

        if (!Array.isArray(biomarkers.demographics)) biomarkers.demographics = [];
        if (!Array.isArray(biomarkers.symptoms)) biomarkers.symptoms = [];
        if (!Array.isArray(biomarkers.proteomicsData)) biomarkers.proteomicsData = [];
        if (!Array.isArray(biomarkers.labs)) biomarkers.labs = [];
        if (!Array.isArray(biomarkers.labs_normal)) biomarkers.labs_normal = [];
        if (!Array.isArray(biomarkers.labs_high)) biomarkers.labs_high = [];
        if (!Array.isArray(biomarkers.labs_low)) biomarkers.labs_low = [];
        if (!Array.isArray(biomarkers.conditions)) biomarkers.conditions = [];
        if (!Array.isArray(biomarkers.medications)) biomarkers.medications = [];
        if (!Array.isArray(biomarkers.procedures)) biomarkers.procedures = [];
        if (!Array.isArray(biomarkers.value)) biomarkers.value = [];
        if (!Array.isArray(biomarkers.conditionTriggers)) biomarkers.conditionTriggers = [];
        if (!Array.isArray(biomarkers.medicationTriggers)) biomarkers.medicationTriggers = [];
        if (!Array.isArray(biomarkers.procedureTriggers)) biomarkers.procedureTriggers = [];

        const jobColumns = [
            //{ id: 'name', label: 'Name' },
            { id: 'status', label: 'Status' },
            { id: 'runWhen', label: 'Start Time', formatValue: (v) => v ? Moment(v).format(DATE_FORMAT) : '' },
            { id: 'recurring', label: 'Recurring', formatValue: (v) => toBoolean(v) ? 'Yes' : 'No' },
            { id: 'keep', label: 'Keep', formatValue: (v) => toBoolean(v) ? 'Yes' : 'No' },
            { id: 'error', label: 'Error' },
            { id: 'createdAt', label: 'Created At', formatValue: (v) => v ? Moment(v).format(DATE_FORMAT) : '' }
        ];

        const algorithmDetails = ((algorithms || []).find((e) => e.name === model.algorithm) || { opts: [], description: '' });
        const featureCount = biomarkers.demographics.length + biomarkers.symptoms.length + biomarkers.proteomicsData.length + biomarkers.labs.length + biomarkers.labs_normal.length
            + biomarkers.labs_high.length + biomarkers.labs_low.length + biomarkers.conditions.length + biomarkers.medications.length + biomarkers.procedures.length;
        const triggerCount = biomarkers.conditionTriggers.length + biomarkers.medicationTriggers.length + biomarkers.procedureTriggers.length;

        const onDialogDone = () => {
            this.onCloseDialog();
            this.onUpdateCounts();
        }
        let tabs = [
            <div key='options' style={{ width: 1300, margin: 5}}>
                <div>
                    <TextField style={{ margin: 5, width: 300 }} label="Name" value={model.name}
                        onChange={(e) => {
                            model.name = e.target.value;
                            model.predictorId = createIdFromText(model.name);
                            this.setState({ model, modified: true });
                        }} />
                    <FormControl style={{ margin: 5, width: 300 }}>
                        <InputLabel>Algorithm</InputLabel>
                        <Select value={model.algorithm} onChange={(e) => {
                            model.algorithm = e.target.value;
                            this.setState({ model, modified: true });
                        }}>
                            {algorithms.map((e) => <MenuItem key={e.name} value={e.name}>{e.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <span style={{ display: 'inline-block', width: 500 }}><i>{algorithmDetails.description}</i></span>
                </div>
                <br />
                <TextField style={{ width: '98.5%', margin: 5 }} label="Description" value={model.description} multiline={true} minRows={3} variant='outlined'
                    onChange={(e) => { model.description = e.target.value; this.setState({ model, modified: true }); }} />
                <br />
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Algorithm Options</AccordionSummary>
                    <AccordionDetails>
                        <div>
                            {algorithmDetails.opts.map((opt) => {
                                if (model.opts[opt.name] === undefined) {
                                    model.opts[opt.name] = opt.defaultValue || '';      // make these all controlled..
                                }
                                return <TextField
                                    error={toBoolean(opt.required) && !model.opts[opt.name]}
                                    helperText={opt.description}
                                    label={opt.name}
                                    key={opt.name}
                                    value={model.opts[opt.name]}
                                    onChange={(e) => {
                                        model.opts[opt.name] = e.target.value;
                                        this.setState({ model });
                                    }}
                                    style={{ margin: 5, width: '95%' }} />
                            })}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <br />
                <Accordion style={{ margin: 5, marginLeft: 15, marginRight: 15 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Patient Filter</AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Tooltip title='Only use data from patients who have been flagged for export from the production environments'><FormControlLabel label='Exported' control={<Checkbox checked={model.biomarkers.exported} onChange={(e) => {
                                model.biomarkers.exported = e.target.checked;
                                this.setState({ model, modified: true }, this.onUpdateCounts.bind(this))
                            }} />} /></Tooltip>
                            <Tooltip title='Remove features when no patients have data for that feature.'><FormControlLabel label='Reduce' control={<Checkbox checked={model.biomarkers.reduce} onChange={(e) => {
                                model.biomarkers.reduce = e.target.checked;
                                this.setState({ model, modified: true }, this.onUpdateCounts.bind(this))
                            }} />} /></Tooltip>
                            <Tooltip title='If enabled, then all features will be included unless selected.'><FormControlLabel label='Exclude Selected Features' control={<Checkbox checked={model.biomarkers.enableExclusive} onChange={(e) => {
                                model.biomarkers.enableExclusive = e.target.checked;
                                this.setState({ model, modified: true }, this.onUpdateCounts.bind(this))
                            }} />} /></Tooltip>
                            <FormControl style={{ margin: 5, width: 400 }}>
                                <InputLabel>Select Import</InputLabel>
                                <Select value={model.biomarkers.importId} onChange={(e) => {
                                    model.biomarkers.importId = e.target.value;
                                    this.setState({ model, modified: true }, this.onUpdateCounts.bind(this))
                                }}>
                                    <MenuItem value=''>None</MenuItem>
                                    <MenuItem value='*'>All</MenuItem>
                                    {imports.map((imp) => <MenuItem value={imp.importId}>{imp.description}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <br />
                            <SelectDataType style={{ margin: 5, width: 300 }} label='Target Data Type' appContext={appContext} dataId={model.biomarkers.dataId}
                                onCreate={() => {
                                    this.setState({
                                        dialog: <EditDataTypeDialog appContext={appContext}
                                            dataType={{ dataId: '', name: '', description: '', tupleDescriptions: [] }}
                                            onCancel={() => this.setState({ dialog: null })}
                                            onDone={(newType) => {
                                                model.biomarkers.dataId = newType.dataId;
                                                this.setState({ dialog: null, model, modified: true }, this.onUpdateCounts.bind(this));
                                            }} />
                                    });
                                }}
                                onChange={(dataType) => {
                                    model.biomarkers.dataId = dataType ? dataType.dataId : '';
                                    this.setState({ model, modified: true }, this.onUpdateCounts.bind(this));
                                }} />
                            <SelectTupleIndex style={{ margin: 5, width: 300 }} label='Target Tuple Index' appContext={appContext} dataId={model.biomarkers.dataId}
                                tupleIndex={model.biomarkers.tupleIndex}
                                onChange={(e) => {
                                    model.biomarkers.tupleIndex = e;
                                    this.setState({ model, modified: true }, this.onUpdateCounts.bind(this));
                                }} />
                            <br />
                            <TextField style={{ margin: 5, width: 600 }} label="Value Filter" value={model.biomarkers.value.join(',')} helperText={'Must match one of the values (comma seperated, Leave blank for any).'}
                                onChange={(e) => { model.biomarkers.value = e.target.value.split(','); this.setState({ model, modified: true }, this.onUpdateCounts.bind(this)) }} />
                            <TextField style={{ margin: 5, width: 200}} label="Days" helperText="How many days of data to include" type='number' value={model.biomarkers.days} onChange={(e) => {
                                model.biomarkers.days = e.target.value;
                                this.setState({ model, modified: true}, this.onUpdateCounts.bind(this))
                            }} />
                            <br />
                            <EditConditions appContext={appContext} name='Selection Conditions' 
                                value={model.biomarkers.condition} 
                                onChange={((e) => {
                                    model.biomarkers.condition = e;
                                    this.setState({ model, modified: true }, this.onUpdateCounts.bind(this));
                                })} 
                            />
                            <br />
                            <Button style={{ margin: 5 }} variant='contained' onClick={() => 
                                this.setState({ dialog: <FeatureSelectionView appContext={appContext} model={model} onCloseView={onDialogDone} /> })
                            }>Select Features</Button>
                            <Button style={{ margin: 5 }} variant='contained' onClick={() => 
                                this.setState({ dialog: <TriggerSelectionView appContext={appContext} model={model} onCloseView={onDialogDone} /> })
                            }>Select Triggers</Button>
                            <br />
                            <br />
                            <span><i>{featureCount} {biomarkers.enableExclusive ? 'Features Excluded' : 'Features Selected'}, {triggerCount} Triggers Selected.</i></span>
                            {counts.patients !== undefined && <span><i> {counts.patients} Patients Found, {counts.dataPoints} Data points found.</i></span>}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>,
            <div key='jobs' style={{ width: 1300, margin: 5}}>
                <EnhancedTable
                    key='jobs'
                    disableMultiSelect={true}
                    onActions={(table, numSelected, actions) => {
                        if (numSelected > 0) {
                            if (numSelected === 1) {
                                const { selected, data } = table.state;
                                let job = data[selected[0]];
                                if (job) {
                                    actions.unshift(<Tooltip title='Add' key='add'><IconButton onClick={() => {
                                        table.setState({ selected: [] });
                                        this.onCreateJob();
                                    }}><AddIcon /></IconButton></Tooltip>)
                                    actions.unshift(this.onJobDetails(job));
                                    actions.unshift(this.onToggleKeep(job));
                                    actions.unshift(...this.onJobResult(job));
                                    actions.unshift(this.onJobLogs(job));
                                    actions.unshift(this.onCancelJob(job));
                                    actions.unshift(this.onDeleteJob(job,table));
                                }
                            }
                        }
                    }}
                    disableDelete={true}
                    onAdd={this.onCreateJob.bind(this)}
                    orderBy='runWhen'
                    order='desc'
                    columnData={jobColumns}
                    data={jobs}
                    rowsPerPage={15}
                    title='Jobs' />
            </div>
        ];

        let disableDelete = model.planId !== appContext.state.plan.planId;
        return (
            <div align="center" >
                <AppBar style={{ backgroundColor: "#FF9800", width: '100%'}} position="static">
                    <Toolbar>
                        <IconButton onClick={() => this.onCloseView()}>
                            <NavigationClose />
                        </IconButton>
                        <Typography variant="h6" color="inherit">ML Model</Typography>
                    </Toolbar>
                </AppBar>

                <div align="left" style={{ margin: 0}}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextField disabled={true} style={{margin: 5, width: 400}} label="Model ID" value={model.modelId}
                                        onChange={(e) => { model.modelId = e.target.value; this.setState({ model, modified: true }); }} />
                                </td>
                                <td valign="top" align="right">
                                    <span style={{color: 'red'}}>{error}</span>
                                    <Tooltip title='Save'><IconButton disabled={progress !== null} onClick={this.onSaveMLModel.bind(this, false)}>{progress || <SaveIcon />}</IconButton></Tooltip>
                                    <Tooltip title='Duplicate'><IconButton disabled={progress !== null} onClick={this.onSaveMLModel.bind(this, true)}>{progress || <SaveCopyIcon />}</IconButton></Tooltip>
                                    <Tooltip title='Show JSON'><IconButton onClick={this.onShowCode.bind(this)}><CodeIcon /></IconButton></Tooltip>
                                    <Tooltip title='Delete'><IconButton disabled={disableDelete} onClick={this.onDeleteMLModel.bind(this)}><DeleteIcon /></IconButton></Tooltip>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <Tabs style={{ width: '100%', backgroundColor: '#96b7eb' }}
                                        value={tab} onChange={(e, newTab) => { this.setState({ tab: `${newTab}` }); }}>
                                        <Tab label='Options' value='0' />
                                        <Tab label='Jobs' value='1' />
                                    </Tabs>
                                    {tabs[tab]}
                                    <br />
                                    <div style={{ margin: 5 }}>
                                        <ChangedByButton appContext={this.props.appContext} primaryKey={model.modelId} collection='MLModel' />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {dialog}
            </div>
        );
    }
}

export default MLModelView;
