import React from 'react';
import {
    Select,
    MenuItem,
    CircularProgress,
    IconButton,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip,
    Slider,
    Paper,
    Tabs,
    Tab,
    Divider
} from '@material-ui/core';
import Moment from 'moment';
import PubSub from 'pubsub-js'

import RefreshIcon from '@material-ui/icons/Refresh';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import MenuIcon from '@material-ui/icons/Menu';

import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import { Patient } from '@apricityhealth/web-common-lib/components/Patient';

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import Axios from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';
import { loadJobResult } from '../../utils/LoadJobResults';

// Add JSON.parseMore, to allow us to parse python generated JSON data..
require('../../utils/json_parseMore');

const JOB_ALGORITHMS = "pca";
const JOB_POLL_INTERVAL = 1000 * 5;         // how often to check the status of a job
const LABEL_OFFSET = 1.25;                   // how much to scale the label x/y by to push them outside the legend 
const MIN_INTERSECT_DISTANCE = 250;         // how close in pixels to select a point

function roundTo(n, place) {
    return +(Math.round(n + "e+" + place) + "e-" + place);
}

function pickRandomColor(dark = false) {
    return new THREE.Color(
        (Math.random() * 0.5) + (dark ? 0.5 : 0),
        (Math.random() * 0.5) + (dark ? 0.5 : 0),
        (Math.random() * 0.5) + (dark ? 0.5 : 0)
    );
}

export default class PCAView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            currentAxis: 0,
            dialog: null,
            eventLog: [],
            patientId: props.patientId || '',
            selectedClusters: [],
            selectedPatients: [],
            darkMode: false,
            sceneScale: 25,
            twoD: true,
            orthographic: true,
            negativeSlopes: false,
            positiveSlopes: false,
            progress: null,
            loading: null,
            jobs: [],
            selectedJobId: '',
            sceneData: {
                axes: [],
                patients: {},
                sceneDays: 0
            },
            day: 0,
            animate: true,
            animateSpeed: 1,
            displayLeftPanel: true,
            renderClusters: true,
            renderGrid: false,
            renderLegend: true
        }
        this.materials = [];
        this.lastRender = Moment()
    }

    componentDidMount() {
        this.loadContent();
        this.token = PubSub.subscribe('PLAN_TOPIC', this.loadContent.bind(this));
        this.keyPressHandler = (e) => {
            if (e.key === ' ') {
                this.setState({ animate: !this.state.animate })
            }
        }
        window.addEventListener('keypress', this.keyPressHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('keypress', this.keyPressHandler);

        if (this.loadingTimer) {
            clearTimeout(this.loadingTimer);
            this.loadingTimer = null;
        }
        this.shutdownScene();
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const { appContext: { stores: { DataTypesStore }, state: { plan: { planId }, idToken } } } = this.props;
        if (planId && idToken) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            const getMLJobs = {
                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${planId}/ml/job/*?algorithm=${encodeURIComponent(JOB_ALGORITHMS)}&status=done,created,running`,
                method: 'GET',
                headers: { "Authorization": idToken }
            };
            console.log("getMLJobs request:", getMLJobs);
            Axios(getMLJobs).then(({ data: { jobs } }) => {
                console.log("getMLJobs result:", jobs);
                this.setState({ jobs, progress: null });
            }).catch((err) => {
                this.setState({ progress: null, error: getErrorMessage(err) })
            })
        }
        this.dataTypeCategory = DataTypesStore.getDataTypes().reduce((p, c) => {
            p[c.dataId] = c.category;
            return p;
        }, {});
    }

    loadJob() {
        const { selectedJobId } = this.state;
        const { appContext: { state: { plan: { planId }, idToken } } } = this.props;
        if (selectedJobId && this.loadingJob !== selectedJobId && planId && idToken) {
            this.loadingJob = selectedJobId;

            const getMLJob = {
                url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${planId}/ml/job/${selectedJobId}`,
                method: 'GET',
                headers: { "Authorization": idToken }
            }

            Axios(getMLJob).then(({ data: { jobs } }) => {
                const job = jobs.find((e) => e.jobId === selectedJobId);
                if (!job) {
                    throw new Error(`Job ${selectedJobId} not found!`)
                }

                console.log("loadJob:", job)
                if (job.status === 'done') {
                    if (!job.result) {
                        this.loadingJob = null;
                        return this.setState({ loading: null, error: 'Job is done, but no result data available!' })
                    }

                    this.setState({ loading: <CircularProgress />, error: null }, this.shutdownScene.bind(this));
                    loadJobResult(this.props.appContext, job).then((job) => {
                        if (this.state.selectedJobId !== selectedJobId) {
                            throw new Error(`Loading of job ${selectedJobId} cancelled!`)
                        }

                        //console.log("loadReportJob data:", response.data)
                        return this.generateSceneData(JSON.parseMore(job.result), job);
                    }).then((sceneData) => {
                        if (this.state.selectedJobId !== selectedJobId) {
                            throw new Error(`Loading of job ${selectedJobId} cancelled!`)
                        }
                        this.setState({ loading: null, job, sceneData, error: sceneData.truncatedData ? 'Data was truncated due to the size.' : null }, this.initializeScene.bind(this));
                        this.loadingJob = null;
                    }).catch((err) => {
                        this.setState({ loading: null, error: getErrorMessage(err) });
                        this.loadingJob = null;
                    })

                } else if (job.status === 'error') {
                    this.setState({ loading: null, error: job.error });
                    this.loadingJob = null;
                } else {        // status should be created or running
                    this.setState({ loading: <CircularProgress />, error: `Job's current status is '${job.status}', run time ${Moment().diff(Moment(job.createdAt), 'minutes')} minutes...` }, this.shutdownScene.bind(this));
                    this.loadingJob = null;

                    if (this.loadingTimer) {
                        clearTimeout(this.loadingTimer);
                        this.loadingTimer = null;
                    }

                    // set a timeout, then attempt to load this job again after the poll interval is done..
                    this.loadingTimer = setTimeout(() => {
                        // firstly, check that the job ID hasn't changed, if it has, then we're now trying to load a different job..
                        if (this.state.selectedJobId === selectedJobId) {
                            // jobId is the same, so call back into this function again..
                            this.loadJob();
                        } else {
                            console.log(`JobId has changed from ${selectedJobId}, cancelling load...`)
                        }
                    }, JOB_POLL_INTERVAL);
                }
            }).catch((err) => {
                console.error("getFeatureDetectJob error:", err);
                this.setState({ loading: null, error: getErrorMessage(err) });
                this.loadingJob = null;
            })
        }
    }

    generateSceneData(data, job) {
        const { dark, sceneScale } = this.state;

        console.log("generateSceneData starting:", data, job);
        return new Promise((resolve, reject) => {
            try {
                const sceneData = {
                    axes: [],
                    patients: {},
                    sceneRadius: 1,
                    sceneDays: 0               // total scene time in days, 0 - sceneTime for purposes of calculating the t for the curves
                }

                function getPatient(patientId) {
                    if (sceneData.patients[patientId] === undefined) {
                        sceneData.patients[patientId] = {
                            color: pickRandomColor(dark),
                            z: 0, //Object.keys(sceneData.patients).length * sceneScale,
                            events: []
                        }
                    }
                    return sceneData.patients[patientId];
                }

                if (data['components']) {
                    const components = data['components'];
                    for (let i = 0; i < components.length; ++i) {
                        sceneData.axes.push({
                            component: components[i].component,
                            name: components[i].name,
                            features: components[i].features
                        })
                    }
                }

                if (data['patients_transformed'] && data['patient_ids']) {
                    const transformed_data = {};
                    let min = 0, max = 0;
                    for (let k in data['patients_transformed']) {
                        transformed_data[k] = Object.values(data['patients_transformed'][k]);
                        min = Math.min(min, ...transformed_data[k]);
                        max = Math.max(max, ...transformed_data[k]);
                    }
                    sceneData.sceneRanges = { min, max };
                    sceneData.sceneRadius = (Math.abs(min) + max) * sceneScale;
                    const eventTimes = Object.values(data['patient_ids']['eventTime']);
                    const endTime = Moment(eventTimes[0]);
                    const startTime = Moment(eventTimes[eventTimes.length - 1]);
                    sceneData.sceneDays = Math.ceil(endTime.diff(startTime, 'days', true));

                    const patientIds = Object.values(data['patient_ids']['patientId']);
                    if (eventTimes.length !== patientIds.length) {
                        throw new Error("eventTimes & patientIds should be the same length!")
                    }
                    for (let i = 0; i < patientIds.length; ++i) {
                        const patientId = patientIds[i];
                        const eventTime = Moment(eventTimes[i]);
                        const patient = getPatient(patientId);
                        const startTime = patient.events.length > 0 ? patient.events[0].eventTime : eventTime;

                        patient.events.push(
                            {
                                eventTime,
                                day: Moment(startTime).diff(eventTime, 'days'),
                                data: sceneData.axes.reduce((p, axis) => {
                                    p[axis.name] = transformed_data[axis.name][i];
                                    return p;
                                }, {}),
                                points: sceneData.axes.map((axis, j) => {
                                    const value = sceneScale * (Math.abs(min) + transformed_data[axis.name][i]);      // add the min value from all the data, we never want a value < 0
                                    const angle = ((Math.PI * 2) / sceneData.axes.length) * j;
                                    return new THREE.Vector3(Math.sin(angle) * value, Math.cos(angle) * value, patient.z);
                                })
                            }
                        );
                    }

                    // need to sort the events in ascending order..
                    for (let k in sceneData.patients) {
                        sceneData.patients[k].events.sort((a, b) => a.day - b.day)
                    }
                }

                console.log("generateSceneData done:", sceneData);
                resolve(sceneData);
            } catch (err) {
                console.error("generateSceneData error:", err);
                reject(err)
            }
        })
    }

    toScreenPosition(v3) {
        if ( this.camera ) {
            const halfWidth = this.mount.clientWidth * 0.5;
            const halfHeight = this.mount.clientHeight * 0.5;
            let projected = v3.clone();
            projected.project(this.camera);
            projected.x = (projected.x * halfWidth) + halfWidth;
            projected.y = -(projected.y * halfHeight) + halfHeight;
            return projected;
        } else {
            return new THREE.Vector3(0,0,0);
        }
    }

    initializeScene(resetCamera = false) {
        const { sceneData, darkMode, twoD, orthographic, sceneScale } = this.state;
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        const addPointLight = ({ x, y, z, color = 0xffffff, intensity = 10000 }) => {
            const light = new THREE.PointLight(color, intensity);
            light.position.set(x, y, z);
            light.layers.disableAll();
            light.layers.enable(1);

            this.scene.add(light);
        }

        this.cleanUpCubesAndMaterials();
        this.scene = new THREE.Scene();

        this.scene.add(new THREE.AmbientLight(0x404040));
        addPointLight({ x: 0, y: 50, z: 20 });
        addPointLight({ x: 0, y: -75, z: -35 });

        if (!this.renderer) {
            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setClearColor(darkMode ? 0 : 0xffffff);
            this.mount.appendChild(this.renderer.domElement);
        }
        this.renderer.setSize(width, height);

        if (!this.camera || resetCamera) {
            if (orthographic) {
                const aspect = width / height;
                const sceneSize = sceneData.sceneRadius * 1.5;
                //console.log(`sceneSize: ${sceneSize}, sceneScale: ${sceneScale}, sceneRadius: ${sceneData.sceneRadius}`)
                const w = sceneSize * aspect;
                const v = sceneSize;
                this.camera = new THREE.OrthographicCamera(-w, w, v, -v, 0, 5000);
                this.camera.position.z = sceneData.sceneRadius * 5;
                if (!twoD) {
                    this.camera.position.y = (sceneSize * 0.707);
                    this.camera.position.x = (sceneSize * 0.707);
                }
            } else {
                this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 5000);
                this.camera.position.z = sceneData.sceneRadius * 2;
                if (!twoD) {
                    this.camera.position.y = sceneData.sceneRadius * 0.707;
                    this.camera.position.x = sceneData.sceneRadius * 0.707;
                }
            }
            if (this.labelRenderer) {
                this.mount.removeChild(this.labelRenderer.domElement);
                this.labelRenderer = null;
            }
        }
        this.camera.layers.disableAll();
        this.camera.layers.enable(1);
        this.camera.layers.enable(2);

        if (!this.labelRenderer) {
            let boundingRect = this.renderer.domElement.getBoundingClientRect();
            let adjustX = (boundingRect.width - width) / 2;
            let adjustY = (boundingRect.height - height) / 2;
            console.log(`width: ${width}, adjustX: ${adjustX}, height: ${height}, adjustY: ${adjustY}, boundingRect:`, boundingRect);
            this.labelRenderer = new CSS3DRenderer();
            this.labelRenderer.domElement.style.position = 'absolute';
            this.labelRenderer.domElement.style.top = `${boundingRect.top + adjustY}px`;
            this.labelRenderer.domElement.style.left = `${boundingRect.left + adjustX}px`;
            this.labelRenderer.domElement.style.zIndex = 1000;
            this.labelRenderer.domElement.style.pointerEvents = 'none';
            //this.renderer.domElement.appendChild(this.labelRenderer.domElement);
            this.mount.appendChild(this.labelRenderer.domElement);
        }
        this.labelRenderer.setSize(width, height);
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);

        if (this.sphere) {
            this.sphere.dispose();
            this.sphere = null;
        }
        this.sphere = new THREE.SphereGeometry(0.5, 10, 10);

        this.grid = new THREE.GridHelper(sceneData.sceneRadius * sceneScale, 10); //new THREE.Mesh(this.xz_plane_geo, this.xz_plane_mat);
        this.grid.layers.disableAll();
        this.grid.layers.enable(2);
        this.scene.add(this.grid);

        if (!this.pointer) {
            this.pointer = new THREE.Vector2();
        }

        if (!this.pointerMoveHandler) {
            this.pointerMoveHandler = this.onPointerMove.bind(this);
            window.addEventListener('mousemove', this.pointerMoveHandler);
        }

        for (let k in sceneData.patients) {
            const patient = sceneData.patients[k];
            if (patient.lineLoop) {
                this.scene.add(patient.lineLoop);
            }
        }

        const makeLabelDiv = (axis) => {
            const newDiv = document.createElement('div');
            newDiv.className = 'label';
            newDiv.textContent = axis.name;
            newDiv.style.backgroundColor = 'transparent';
            newDiv.style.color = darkMode ? 'white' : 'black';
            newDiv.style.alignContent = 'center';
            newDiv.style.fontSize = '9px';
            return newDiv;
        }
        // test if a rect overlaps another rect.. rects should be an array of rects, a rect is an object with left, right, top, and bottom properties
        const isIntersecting = (rect, rects) => {
            for (let i = 0; i < rects.length; ++i) {
                const testRect = rects[i];
                if (rect.right < testRect.left) continue;
                if (rect.left > testRect.right) continue;
                if (rect.bottom < testRect.top) continue;
                if (rect.top > testRect.bottom) continue;
                //console.log("rects intersect:", rect, testRect );
                return true;
            }
            return false;
        }

        let labelRects = [];
        this.legendPoints = sceneData.axes.map((axis, j) => {
            const angle = ((Math.PI * 2) / sceneData.axes.length) * j;
            const point = new THREE.Vector3(Math.sin(angle) * sceneData.sceneRadius, Math.cos(angle) * sceneData.sceneRadius, 0);
            const labelWidth = axis.name.length * 10;
            const labelHeight = 12;
            const center = this.toScreenPosition(point);
            const rect = { left: center.x - (labelWidth * 0.5), right: center.x + (labelWidth * 0.5), top: center.y - (labelHeight * 0.5), bottom: center.y + (labelHeight * 0.5) };
            //console.log(`rect for ${axis.name}:`, rect );

            let label = null;
            if (!isIntersecting(rect, labelRects)) {
                labelRects.push(rect);

                label = new CSS3DObject(makeLabelDiv(axis));
                label.layers.enable(2);
                label.position.x = point.x * LABEL_OFFSET;
                label.position.y = point.y * LABEL_OFFSET;

                // const box = new THREE.BoxHelper(label, 0xffff00);
                // this.scene.add(box);
            }

            return { angle, point, label }
        });
        this.setState({ labelRects });

        this.legendGeo = new THREE.BufferGeometry().setFromPoints(this.legendPoints.map((e) => e.point));
        this.legendMat = new THREE.LineBasicMaterial({ color: darkMode ? 'white' : 'black', linewidth: 5 });
        this.legendMesh = new THREE.LineLoop(this.legendGeo, this.legendMat);
        this.legendMesh.layers.enable(2);
        this.legendMesh.position.z = 20;
        for (let i = 0; i < this.legendPoints.length; ++i) {
            const { label } = this.legendPoints[i];
            if (label) {
                this.legendMesh.add(label);
            }
        }
        this.scene.add(this.legendMesh);

        this.baselinePoints = sceneData.axes.map((axis, j) => {
            const angle = ((Math.PI * 2) / sceneData.axes.length) * j;
            const baselineRadius = Math.abs(sceneData.sceneRanges.min) * sceneScale;     // we want the baseline to be at 0
            return new THREE.Vector3(Math.sin(angle) * baselineRadius, Math.cos(angle) * baselineRadius, 0);
        });
        this.baselineGeo = new THREE.BufferGeometry().setFromPoints(this.baselinePoints);
        this.baselineMat = new THREE.LineBasicMaterial({ color: darkMode ? 'white' : 'black', linewidth: 5 });
        this.baselineMesh = new THREE.LineLoop(this.baselineGeo, this.baselineMat);
        this.baselineMesh.layers.enable(2);
        this.baselineMesh.position.z = 20;
        this.scene.add(this.baselineMesh);

        this.renderScene();
        this.startAnimations();
    }

    cleanUpCubesAndMaterials() {
        //console.log("cleanUpCubesAndMaterials:", this.cubes, this.materials );
        if (this.scene) {
            this.scene.clear();
            this.scene = null;
        }
        for (let i = 0; i < this.materials.length; ++i) {
            this.materials[i].dispose();
        }
        this.materials = [];
    }

    shutdownScene() {
        this.stopAnimations();

        if (this.labelRenderer) {
            this.mount.removeChild(this.labelRenderer.domElement);
            this.labelRenderer = null;
        }
        if (this.renderer) {
            this.mount.removeChild(this.renderer.domElement);
            this.renderer.dispose();
            this.renderer = null;
        }
        if (this.pointerMoveHandler) {
            window.removeEventListener('mousemove', this.pointerMoveHandler);
            this.pointerMoveHandler = null;
        }
        if (this.sphere) {
            this.sphere.dispose();
            this.sphere = null;
        }
        if (this.controls) {
            this.controls.dispose();
            this.controls = null;
        }

        this.cleanUpCubesAndMaterials();
        this.camera = null;
    }

    startAnimations() {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate.bind(this));
        }
    }

    stopAnimations() {
        if (this.frameId) {
            cancelAnimationFrame(this.frameId);
            this.frameId = null;
        }
    }

    animate() {
        // animate models here
        this.renderScene();
        this.frameId = window.requestAnimationFrame(this.animate.bind(this));
    }

    renderScene() {
        if (this.renderer && this.scene && this.camera) {
            const { sceneData, animate, animateSpeed, day, selectedPatients, renderGrid, renderLegend } = this.state;
            const elapsedTime = Moment().diff(this.lastRender, 'milliseconds');
            this.lastRender = Moment();

            if (animate) {
                let newDay = day + ((elapsedTime / 1000) * animateSpeed);
                if (newDay > sceneData.sceneDays) {
                    newDay = 0;     // wrap back to day 0
                }
                this.setState({ day: newDay });       // advance the day for the next render
            }

            for (let patientId in sceneData.patients) {
                const patient = sceneData.patients[patientId];
                if (selectedPatients.length > 0 && selectedPatients.indexOf(patientId) < 0) {
                    if (patient.lineLoop) {
                        patient.lineLoop.layers.disable(1)
                    }
                    continue;
                }
                const event = patient.events.findLast((e) => e.day <= day);
                if (!patient.lastPoints || patient.lastPoints.length !== event.points.length ) {
                    patient.lastPoints = event.points;
                } 
                for(let k=0;k<event.points.length;++k) {
                    const delta = new THREE.Vector3().subVectors(event.points[k], patient.lastPoints[k]).multiplyScalar((elapsedTime / 1000) * (animateSpeed * 0.5));
                    patient.lastPoints[k].add( delta );
                }
                patient.geo = new THREE.BufferGeometry().setFromPoints(patient.lastPoints);
                if (!patient.material) {
                    patient.material = new THREE.LineBasicMaterial({ color: patient.color });
                    this.materials.push(patient.material);
                }
                if (!patient.lineLoop) {
                    patient.lineLoop = new THREE.LineLoop(patient.geo, patient.material);
                    patient.lineLoop.userData = { patientId, patient };
                    patient.lineLoop.layers.enable(1);
                    this.scene.add(patient.lineLoop);
                } else {
                    patient.lineLoop.geometry = patient.geo;
                    patient.lineLoop.layers.enable(1);
                }
            }

            if (this.grid) {
                if (renderGrid) {
                    this.grid.layers.enable(2)
                } else {
                    this.grid.layers.disable(2);
                }
            }
            if (this.legendMesh) {
                this.legendMesh.visible = renderLegend;
                for (let i = 0; i < this.legendPoints.length; ++i) {
                    const label = this.legendPoints[i].label;
                    if (label) {
                        label.visible = renderLegend;
                    }
                }
            }
            if (this.baselineMesh) {
                this.baselineMesh.visible = renderLegend;
            }

            this.renderer.render(this.scene, this.camera);
            this.labelRenderer.render(this.scene, this.camera);
        }
    }

    checkForIntersect() {
        if (this.mount) {
            const r = this.mount.getBoundingClientRect();
            const { sceneData, day, selectedPatients } = this.state;

            function sqr(v) {
                return v * v;
            }

            let intersects = null;
            let intersectDistance = 0;
            for (let j in sceneData.patients) {
                if (selectedPatients.length > 0 && selectedPatients.indexOf(j) < 0) continue;     // patient not selected

                const patient = sceneData.patients[j];
                const event = patient.events.findLast((e) => e.day <= day);
                const projected = event.points.map((v3) => this.toScreenPosition(v3));

                for (let i = 0; i < projected.length; ++i) {
                    let distance = Math.sqrt(sqr(this.mousePosition.x - projected[i].x) + sqr(this.mousePosition.y - projected[i].y));
                    if (distance < MIN_INTERSECT_DISTANCE && (!intersects || distance < intersectDistance)) {
                        intersects = { patientId: j, patient, event, axis: sceneData.axes[i], distance, x: projected[i].x + r.left, y: projected[i].y + r.top };
                        intersectDistance = distance;
                    }
                }

            }

            //console.log("checkForIntersect:", intersects);
            this.setState({ intersects });
        }
    }

    onPointerMove(e) {
        if (this.pointer && this.mount) {
            const r = this.mount.getBoundingClientRect();
            this.mousePosition = { x: e.clientX - r.left, y: e.clientY - r.top, r };
            this.pointer.x = (this.mousePosition.x / r.width) * 2 - 1;
            this.pointer.y = -((this.mousePosition.y / r.height) * 2 - 1);

            if (this.intersectTimer) {
                clearTimeout(this.intersectTimer);
            }
            this.intersectTimer = setTimeout(this.checkForIntersect.bind(this), 250);
        }
    }

    render() {
        const { appContext } = this.props;
        const { currentAxis, sceneData, progress, error, darkMode, twoD, orthographic, animate, animateSpeed,
            selectedJobId, selectedPatients, jobs, loading, intersects, day, dialog, displayLeftPanel,
            renderGrid, renderLegend } = this.state;
        const { innerHeight } = window;

        let tooltip = '';
        if (intersects) {
            //console.log("intersects:", intersects);
            const { patientId, event, axis, x, y } = intersects;

            this.tooltipPos = { clientX: x, clientY: y };
            tooltip = <React.Fragment>
                Axis: {axis.name}: {roundTo(event.data[axis.name], 3)}<br />
                Event Time: {event.eventTime.toISOString()}<br />
                Patient: <Patient appContext={appContext} patientId={patientId} />
            </React.Fragment>;
        }
        return <Tooltip title={tooltip}
            arrow={true}
            disableFocusListener={true}
            PopperProps={{
                modifiers: {
                    computeStyle: {
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    left: `${this.tooltipPos.clientX}px`,
                                    top: `${this.tooltipPos.clientY}px`
                                }
                            }
                        }
                    }
                }
            }}
        >
            <table style={{ width: '100%' }}><tbody>
                <tr>
                    <td>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FormControl style={{ margin: 5, width: 350 }}>
                                <InputLabel>Select PCA Job Result</InputLabel>
                                <Select value={selectedJobId} onChange={(e) => {
                                    this.setState({ selectedJobId: e.target.value }, this.loadJob.bind(this))
                                }}>
                                    {jobs.map((j) => {
                                        return <MenuItem key={j.jobId} value={j.jobId}>{`${j.createdAt}: ${j.name}`}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl style={{ margin: 5, width: 300 }}>
                                <InputLabel>Patient Filter</InputLabel>
                                <Select multiple={true}
                                    value={selectedPatients}
                                    renderValue={(data) => data.map((e, i) => <span key={i}>{i > 0 ? ', ' : ''}<Patient appContext={appContext} patientId={e} /></span>)}
                                    onChange={(e) => {
                                        let selectedPatients = e.target.value;
                                        if (selectedPatients.indexOf('all') >= 0) {
                                            selectedPatients = [];
                                        }
                                        this.setState({ selectedPatients });
                                    }}>
                                    <MenuItem value='all'>All</MenuItem>
                                    {Object.keys(sceneData.patients).map((patientId) => {
                                        return <MenuItem key={patientId} value={patientId}><Patient appContext={appContext} patientId={patientId} /></MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </td>
                    <td align='right'>
                        <span style={{ color: 'red' }}>{error}</span>
                        <FormControlLabel style={{ margin: 5 }} label='Dark' control={<Checkbox checked={darkMode} onChange={(e) => {
                            if (this.renderer) {
                                this.renderer.setClearColor(e.target.checked ? 0 : 0xffffff);
                            }
                            this.setState({ darkMode: e.target.checked }, this.initializeScene.bind(this, false));
                        }} />} />
                        <FormControlLabel style={{ margin: 5 }} label='Legend' control={<Checkbox checked={renderLegend} onChange={(e) => this.setState({ renderLegend: e.target.checked })} />} />
                        <FormControlLabel style={{ margin: 5 }} label='Grid' control={<Checkbox checked={renderGrid} onChange={(e) => this.setState({ renderGrid: e.target.checked })} />} />
                        <FormControlLabel style={{ margin: 5 }} label='2D' control={<Checkbox checked={twoD} onChange={(e) => this.setState({ twoD: e.target.checked }, this.initializeScene.bind(this, true))} />} />
                        <FormControlLabel style={{ margin: 5 }} label='Orthographic' control={<Checkbox checked={orthographic} onChange={(e) => this.setState({ orthographic: e.target.checked }, this.initializeScene.bind(this, true))} />} />
                        <TextField style={{ width: 150, margin: 5 }} label='Speed' value={animateSpeed} type='number' onChange={(e) => this.setState({ animateSpeed: e.target.value })} />
                        <IconButton onClick={this.loadContent.bind(this)} disabled={progress !== null}>{progress || <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} align='left'>
                        <table style={{ width: '100%' }}><tbody>
                            <tr><td valign='top'>
                                <Paper style={{ height: displayLeftPanel ? (innerHeight * 0.85) : 50, width: displayLeftPanel ? 500 : 50, overflowY: 'hidden' }}>
                                    <Tooltip title='Toggle Panel'><IconButton onClick={() => {
                                        this.setState({ displayLeftPanel: !displayLeftPanel }, () => {
                                            // this changes the renderer size, so we need to completely destroy everything, then re-create just like we reloaded..
                                            this.shutdownScene();
                                            this.initializeScene(true);
                                        });
                                    }}><MenuIcon /></IconButton></Tooltip>
                                    {displayLeftPanel && <Tabs variant='scrollable' value={currentAxis} onChange={(e, t) => this.setState({ currentAxis: t })}>{sceneData.axes.map((axis, i) => {
                                        return <Tab label={axis.name} value={i} />
                                    })}</Tabs>}
                                    {displayLeftPanel && <table width='100%'><tbody>
                                        {(sceneData.axes[currentAxis] || { features: [] }).features.sort((a, b) => b.weight - a.weight).map((f) => {
                                            const value = f.abs_weight * 200;
                                            const color = f.weight < 0 ? 'red' : 'green';
                                            //console.log(`${f.name}: ${value}`);
                                            return <tr><td>{f.name}</td><td style={{ color, align: 'center' }}>{roundTo(f.weight, 3)}
                                                <Divider light={false} variant='middle' style={{ background: color, width: `${value}px` }} /> </td></tr>
                                        })}
                                    </tbody></table>}
                                </Paper>
                            </td>
                                <td align='center'>
                                    <div style={{ margin: 5, width: '100%', height: innerHeight * 0.75 }} ref={(mount) => this.mount = mount}
                                        onClick={(e) => {
                                            if (intersects) {
                                                this.setState({ selectedPatients: [ intersects.patientId ],currentAxis: intersects.axis.component - 1 });
                                            }
                                        }}>
                                        {loading}
                                        {dialog}
                                    </div>
                                    <IconButton style={{ top: -15 }} onClick={() => this.setState({ animate: !animate })}>{animate ? <PauseIcon /> : <PlayIcon />}</IconButton>
                                    <Slider style={{ margin: 5, width: 800 }}
                                        valueLabelDisplay='on'
                                        size='small'
                                        value={Math.floor(day)}
                                        min={0}
                                        step={0.01}
                                        max={sceneData.sceneDays}
                                        onChange={(e, v) => {
                                            let newDay = v;
                                            if (newDay < 0) {
                                                newDay = 0;
                                            } else if (newDay > sceneData.sceneDays) {
                                                newDay = sceneData.sceneDays;
                                            }
                                            this.setState({ day: newDay })
                                        }}
                                    /><br />
                                    <span><i>Hold left mouse button to rotate, Hold right mouse button to move, Use your mouse wheel to zoom.</i></span>
                                </td>
                            </tr>
                        </tbody></table>
                    </td>
                </tr>
            </tbody></table>
        </Tooltip>
    }
}

