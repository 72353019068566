import { AxiosRequest } from '@apricityhealth/web-common-lib/utils/Axios';
import Config from '@apricityhealth/web-common-lib/Config';

export function loadJobResultS3(appContext, job) {
    if (job.result.startsWith('s3://')) {
        const { state: { idToken } } = appContext;
        // request an HTTPS link to the result in the S3 bucket..
        const getJobResult = {
            url: Config.baseUrl + `${Config.pathPrefix}apricity-forecast/${job.planId}/ml/job/${job.jobId}/result`,
            method: 'get',
            headers: { "Authorization": idToken }
        };
        console.log("loadJobResultS3 requst:", getJobResult );
        return AxiosRequest(getJobResult).then((result) => {
            console.log("loadJobResultS3 result:", result.data );
            job.result = result.data;
            return job;
        }).catch((err) => {    
            console.error("loadJobResultS3 error:", err );
            return err;
        })
    } 
    return Promise.resolve(job);
}

export function loadJobResultHTTPS(job) {
    if (job.result.startsWith('https://')) {
        console.log("loadJobResultHTTPS request:", job.result );
        return AxiosRequest({
            url: job.result,
            method: 'get',
            transformResponse: (res) => {
                return res;
            }
        }).then((response) => {
            console.log("loadJobResultHTTPS done:", response.data.length );
            job.result = response.data;
            return job;
        }).catch((err) => {
            console.error("loadJobResultHTTPS error:", err );
        })
    } 
    return Promise.resolve(job);
}

// check if the result is in S3 first, if so, request a secure HTTPS link so we can download the result directly from the S3 bucket..
export function loadJobResult(appContext, job) {
    return loadJobResultS3(appContext, job).then(() => {
        return loadJobResultHTTPS(job);
    })
}

